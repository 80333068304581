import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actFetchBankPromotionRequest } from '../../../actions/index';

import { Link } from 'react-router-dom';
import './styles.sass';

class LoanOfferBankTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loanPeriodId: '',
            rateOfferId: '',
            rateAfterIncentivesId: '',
            appraisalTime: '',
        };
    }
    componentDidMount() {
        this.props.fetchBankPromotions(
            this.props.cateId,
            this.state.loanPeriodId,
            this.state.rateOfferId,
            this.state.rateAfterIncentivesId,
            this.state.appraisalTime
        );
    }
    render() {
        var { bankPromotions } = this.props;
        return (
            <div className="bank-table table">
                <table>
                    <thead>
                        <tr>
                            <th>Ngân hàng</th>
                            <th>
                                Thời gian
                                <br />
                                vay tối đa
                            </th>
                            <th>Ưu đãi lãi suất</th>
                            <th>Lãi suất sau ưu đãi </th>
                            <th>Thời gian thẩm định</th>
                            <th>Website</th>
                            <th>Tổng đài</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bankPromotions.map((bankItem) => (
                            <tr key={bankItem.Id}>
                                <td>
                                    <div className="bank-info flex align-center">
                                        <div className="logo">
                                            <img src={bankItem.BankLogo} alt={bankItem.BankName} />
                                        </div>
                                        <div className="title">
                                            <Link to={`${this.props.zoneUrl}/${bankItem.Id}`} title={bankItem.BankName}>
                                                <strong>{bankItem.BankName}</strong>
                                            </Link>
                                        </div>
                                    </div>
                                </td>
                                <td dangerouslySetInnerHTML={{ __html: bankItem.MaximumLoanPeriod }}></td>
                                <td dangerouslySetInnerHTML={{ __html: bankItem.InterestRateOffers }}></td>
                                <td dangerouslySetInnerHTML={{ __html: bankItem.InterestRateAfterIncentives }}></td>
                                <td dangerouslySetInnerHTML={{ __html: bankItem.AppraisalTime }}></td>
                                <td>
                                    <a
                                        href={bankItem.BankWebsite}
                                        title={bankItem.BankName}
                                        className="view-website"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Xem website
                                    </a>
                                </td>
                                <td>
                                    <a href={`tel: ${bankItem.BankHotLine}`}>
                                        <strong>{bankItem.BankHotLine}</strong>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        bankPromotions: state.bankPromotions,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchBankPromotions: (cateId, loanPeriodId, rateOfferId, rateAfterIncentivesId, appraisalTime) => {
            dispatch(
                actFetchBankPromotionRequest(cateId, loanPeriodId, rateOfferId, rateAfterIncentivesId, appraisalTime)
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfferBankTableComponent);
