import React from 'react';
import { useParams } from 'react-router';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import LoanOfferNavComponent from '../../components/loan-offer/loan-offer-nav';
import LoanOfferBankDetailComponent from '../../components/loan-offer/loan-offer-bank-detail';
import LoanOfferFormBankDetailComponent from '../../components/loan-offer/loan-offer-form-bank-detail';

function NormalLoanDetailPage(props) {
    const { zoneId } = props;
    const bankId = useParams().bankId;

    const normalLoanNavList = [
        {
            zoneId: 1,
            zoneTitle: 'Chương trình Ưu đãi thông thường',
            zoneDescription: 'Khách hàng vay với lãi suất ưu đãi chỉ từ 6%/năm và tự thanh toán gốc lãi cho ngân hàng',
            zoneUrl: '/loan-offer/chuong-trinh-uu-dai-thong-thuong',
            zoneImageUrl: '',
            isActive: zoneId === '1',
        },
        {
            zoneId: 2,
            zoneTitle: 'Chương trình hỗ trợ lãi suất 0% ',
            zoneDescription: 'Khách hàng được hỗ trợ tiền lãi vay lên tới 12 tháng tại các ngân hàng liên kết với HTV',
            zoneUrl: '/loan-offer/chuong-trinh-ho-tro-lai-suat',
            zoneImageUrl: '',
            isActive: zoneId === '2',
        },
        {
            zoneId: 3,
            zoneTitle: 'Ưu đãi đặc biệt',
            zoneDescription:
                'Hyundai Thành Công triển khai chương trình ưu đãi tháng 12 trên toàn quốc “Hyundai đồng hành - Giáng sinh an lành”',
            zoneUrl: '/loan-offer/uu-dai-dac-biet',
            zoneImageUrl: '',
            isActive: zoneId === '3',
        },
    ];
    // form
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    return (
        <section className="normal-loan-section section bg-login bdbt-gray">
            <div className="container">
                <LoanOfferNavComponent navList={normalLoanNavList} />
                <div className="box-normal-loan-wrap">
                    <LoanOfferBankDetailComponent bankId={bankId} />

                    <div className="row center">
                        <div className="col-lg-11">
                            <LoanOfferFormBankDetailComponent form={form} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NormalLoanDetailPage;
