import React from 'react';
import PropTypes from 'prop-types';

import './styles.sass';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Select from '../../form-control/SelectField';

FormFilter.propTypes = {
    onSubmit: PropTypes.func,
};

function FormFilter(props) {
    const { onSubmit, provinces, districts, onProvinceChange } = props;

    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    return (
        <>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="wrap-form wrap-filter">
                    <div className="form-group filter-field select-group province">
                        <Select
                            name="Province"
                            label=""
                            options={provinces}
                            handleChange={onProvinceChange}
                            form={form}
                        />
                    </div>
                    <div className="form-group filter-field select-group district">
                        <Select name="District" label="" options={districts} form={form} />
                    </div>
                    <div className="form-group frm-btnwrap hidden">
                        <div className="frm-btn">
                            <input className="btn btn-searchmap" type="submit" value="Tìm kiếm" />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default FormFilter;
