import React, { useState } from 'react';
import { encode as base64_encode } from 'base-64';

import Get from '../../../../utils/APIsHelper';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import InputField from '../../../form-control/InputField';
import AlertMessage from '../../../alert/index';

function Form() {
    const [oldPassword, setOldPassword] = useState();
    const [password, setPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('alert alert-success');

    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        let body = {
            oldPassword,
            password,
            confirmNewPassword,
        };
        Get('Api/Customer/ChangePassword', 'post', body).then((res) => {
            if (res.data.success) {
                setAlertType('alert alert-success');
                setAlertMessage('Thay đổi mật khẩu thành công');
            } else {
                setAlertType('alert alert-error');
                if (res.data.message) setAlertMessage(res.data.message[0]);
            }
        });
    };

    return (
        <div className="box-admin">
            <h1 className="heading-title fz-24 text-white fw-700">Đổi mật khẩu</h1>
            <AlertMessage message={alertMessage} type={alertType} />
            <form onSubmit={handleSubmit} method="post">
                <div className="wrap-form">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="OldPassword"
                                    type="password"
                                    label="Mật khẩu cũ"
                                    onChange={(e) => setOldPassword(base64_encode(e.target.value))}
                                    form={form}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="NewPassword"
                                    type="password"
                                    label="Mật khẩu mới"
                                    onChange={(e) => setPassword(base64_encode(e.target.value))}
                                    form={form}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="ConfirmNewPassword"
                                    type="password"
                                    label="Xác nhận mật khẩu mới"
                                    onChange={(e) => setConfirmNewPassword(base64_encode(e.target.value))}
                                    form={form}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group frm-btnwrap">
                                <label for=""> </label>
                                <div className="frm-btn">
                                    <input
                                        className="btn btn-default frm-btn-submit btn-change-password"
                                        type="submit"
                                        value="Đổi mật khẩu"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Form;
