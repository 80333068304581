import React from 'react';
import PropTypes from 'prop-types';
import './styles.sass';

StepNav.propTypes = {
	stepList: PropTypes.array.isRequired,
};

function StepNav({stepList}) {
	
	return (
		<div className="step-by-step"> 
			<ul className="type-none flex text-center">
				{stepList.map(step => (
					<li 
						className={step.isActive ? 'active' : ''}
						key={step.id}
					>
						Bước {step.id}.  
						<span>{step.title}</span>
					</li>
				))}
			</ul>
		</div>
	);
}

export default StepNav;