import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.sass';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// social login
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
// import loginFBImages from '../../../../assets/images/login-fb.jpg';
// import loginGGImages from '../../../../assets/images/login-gg.jpg';

import InputField from '../../../form-control/InputField';
import VerificationCodeFormComponent from '../../../popup/FormPopup/VerificationCode';
import Get from '../../../../utils/APIsHelper';

export default function LoginForm({ setToken }) {
	const [openDialog, setOpenDialog] = React.useState(false);
	const [phoneNumber, setPhoneNumber] = React.useState('');
	const [OTPMessage, setOTPMessage] = React.useState('');
	const [error, setError] = useState();
	// redirect fb and gg
	// const [shouldRedirect, setShouldRedirect] = useState(false);

	const schema = yup.object().shape({
		content: yup.string().required('Please enter title'),
	});
	const form = useForm({
		defaultValues: {
			content: '',
		},
		resolver: yupResolver(schema),
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		Get('api/TokenWeb/LoginByPhone', 'post', { phoneNumber }).then((res) => {
			if (res.data.success) {
				setOpenDialog(true);
				setOTPMessage('');
			} else setError('Đăng nhập thất bại. Vui lòng thử lại.');
		});
	};

	const onVerificationOTP = (e) => {
		e.preventDefault();
		let OTPCode = e.target.OTPCode.value;
		if (OTPCode !== '') {
			let body = {
				phoneNumber,
				otp: OTPCode,
			};

			Get('api/TokenWeb/Verification', 'post', body).then((res) => {
				if (res.data.success) {
					setOTPMessage('Xác thực thành công!');
					setOpenDialog(false);
					setToken({
						token: res.data.accessToken,
					});
				} else if (res.data.status === 1) {
					setOTPMessage('Mã xác thực hết hạn!');
				} else if (res.data.status === 5) {
					setOTPMessage('Mã xác thực không đúng!');
				} else if (res.data.status === 10) {
					setOTPMessage('Xác thực OTP thất bại!. Vui lòng bấm gửi lại OTP để nhận OTP mới');
				}
			});
		}
	};
	const onClickReSend = (e) => {
		e.preventDefault();
		let body = { phoneNumber };
		Get('api/TokenWeb/ReSendOTP', 'post', body).then((res) => {
			if (res.data.success) setOTPMessage('');
			setOpenDialog(false);
			setOpenDialog(true);
		});
	};
	const handleClose = () => {
		setOpenDialog(false);
	};

	// const loginOauth = (body) => {
        // Get('api/TokenWeb/LoginOauth', 'post', body).then((res) => {
        //     console.log(body);
        //     console.log(res);
        // });
    // };

	// const getOauthUrl = (code, type) => {
    //     Get(`api/TokenWeb/GetOauthUrl?type=${type}`, 'get').then((res) => {
    //         console.log(res);
	// 		setToken({
    //             token: res.accessToken,
    //         });
    //         // let body = {
    //         //     type: type,
    //         //     code: code,
    //         // };
	// 		// if(code){
	// 		// 	loginOauth(body);
	// 		// }
    //     });
    // };

	// const responseGoogle = (response) => {
    //     console.log(response);
	// 	getOauthUrl(response.code, 'google');
    // };

	// const responseFacebook = (response) => {
    //     console.log(response);
	// 	getOauthUrl(response.code, 'facebook');
    // };

	

	return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="wrap-form">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputField
                                    name="PhoneNumber"
                                    type="text"
                                    label="Số điện thoại"
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group frm-btnwrap">
                                <div className="frm-btn">
                                    <input className="btn btn-default frm-btn-submit" type="submit" value="Đăng nhập" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group sign-in-facebook">
                                {/* <a href="/#" title="Đăng nhập bằng Facebook">
                                    <img src={loginFBImages} alt="facebook" />
                                </a> */}
                                {/* <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                    autoLoad={false}
                                    fields="name,email"
                                    icon="fa-brands fa-facebook"
                                    textButton="Sign in with Facebook"
                                    callback={responseFacebook}
                                /> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group sign-in-google">
                                {/* <a href="/#" title="Đăng nhập bằng Google">
									<img src={loginGGImages} alt="google" />
								</a> */}
                                {/* <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    theme={'dark'}
                                /> */}
                            </div>
                        </div>
                        <div className="col-md-7">
                            {/* <div className="form-group frm-captcha">
								<img src="http://html.canhcam.asia/tcmotor/img/capcha.png" alt=""/>
							</div> */}
                        </div>

                        <div className="col-md-12">
                            <div className="error">{error}</div>
                        </div>
                    </div>
                </div>
            </form>

            <VerificationCodeFormComponent
                openDialog={openDialog}
                handleClose={handleClose}
                phoneNumber={phoneNumber}
                message={OTPMessage}
                onSubmit={onVerificationOTP}
                onClickReSend={onClickReSend}
            />
        </>
    );
}

LoginForm.propTypes = {
	setToken: PropTypes.func.isRequired,
};
