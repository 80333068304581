import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { actFetchCategoriesRequest } from '../../../actions/index'; 
import './styles.sass';


class NavBarLeft extends Component {
    componentDidMount() {
        this.props.fetchCategories();
    }
    render() {
        var {categories} = this.props; 
        return ( 
            <div className="box-nav nav-sticky hidden-mobile">
                <h2 className="main-title fz-20 fw-500 text-up text-white">Hỗ trợ khách hàng</h2>
                <ul className="list-items type-none">
                    {categories.map(nav => (
                        <li 
                            key={nav.id}
                            className={nav.isActive ? 'active' : ''}
                        > 
                            <Link  to={nav.url} >  
                                <em className="ri-arrow-right-s-line"></em>
                                <span>{nav.name}</span> 
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        categories: state.categories,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchCategories: () => {
            dispatch(actFetchCategoriesRequest('News-Catalog',''));  
        },
    };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(NavBarLeft);