import React from 'react';

import CustomerNavLink from '../Menu/index';
import LoanForm from './Form';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

function LoanComponent(props) {
    // form
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    return (
        <section className="admin-section customer-section section bg-login bdbt-gray">
            <div className="container">
                <div className="row row-space">
                    <div className="col-lg-4 col-xl-3">
                        <CustomerNavLink />
                    </div>
                    <div className="col-lg-8 col-xl-9">
                        <LoanForm form={form} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LoanComponent;
