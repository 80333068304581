import React, { Component } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

// import dotyWhite from '../../../assets/images/doty-white.png';

class StoreSelect extends Component {
	render() {  
		var {  showroomList, handleChange  } = this.props; 
		
		return (
            <>
                <div className="form-group">
                    <label htmlFor="true">
                        Showroom/ Đại lý
                        {/* <em className="ri-information-fill"></em> */}
                    </label>
                    <div className="showroom-list">
                        <RadioGroup aria-label="Store" name="Store" onChange={handleChange}>
                            {showroomList.map((showroom) => (
                                <Accordion key={showroom.id}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-label="Expand"
                                        aria-controls={`additional-actions${showroom.id}-content`}
                                        id={`additional-actions${showroom.id}-header`}
                                    >
                                        <FormControlLabel
                                            value={`${showroom.id}`}
                                            aria-label={showroom.title}
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Radio />}
                                            label={showroom.title}
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            color="textSecondary"
                                            className={
                                                showroom.id === '6124b2295a0396332dd1dd9a' ||
                                                showroom.id === '6124ae7c5a0396332dd1ce89'
                                                    ? 'description is-doty'
                                                    : 'description'
                                            }
                                            dangerouslySetInnerHTML={{ __html: showroom.description }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </RadioGroup>
                    </div>
                </div>
            </>
        );
	}
}

export default StoreSelect;
