import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './styles.sass';

FaqsList.propTypes = {
    faqsList: PropTypes.array.isRequired,
};

function FaqsList({faqsList}) {
    return (
        <div className="box-faqs"> 
            <h1 className="heading-title fz-30 text-white fw-700">FAQs</h1>
            <div className="accordion">
                {faqsList.map((faqs,index) => (
                    <> 
                        <Accordion className="accordion-item" >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${faqs.id}a-content`} 
                                id={`panel${faqs.id}a-header`}
                            > 
                                <h2 className="title">{(index + 1) < 10 ? '0' : ''}{(index + 1)}. {faqs.title}</h2>
                            </AccordionSummary>
                            <AccordionDetails 
                                className="content" 
                                dangerouslySetInnerHTML={{__html: faqs.briefContent}}
                            >
                            </AccordionDetails>
                        </Accordion>
                    </>
                ))}
            </div>
        </div>
    );
}

export default FaqsList;