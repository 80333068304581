import React, { Component } from 'react';
import Get from '../../../../utils/APIsHelper';
import { NavLink } from 'react-router-dom';

import './styles.sass';

class NavCustomer extends Component {
    handleLogout = (event) => {
        event.preventDefault();
        Get('api/Customer/LogOut', 'post', null).then((res) => {
            sessionStorage.removeItem('token-customer');
            window.location.reload();
            event.preventDefault();
        });
    };
    render() {
        var { navbarList, fullName } = this.props;
        return (
            <div className="box-nav nav-sticky hidden-mobile">
                <h2 className="main-title fw-300 text-white">
                    Tài khoản của
                    <span>{fullName}</span>
                </h2>
                <ul className="list-items type-none">
                    {navbarList.map((nav) => (
                        <li key={nav.id}>
                            <NavLink to={`${nav.zonePath}${nav.url}`}>
                                <em className="ri-arrow-right-s-line"></em>
                                <span>{nav.title}</span>
                            </NavLink>
                        </li>
                    ))}
                    {/* <li> 
						<NavLink  to='/admin-change-password' >  
							<em className="ri-arrow-right-s-line"></em>
							<span>Đổi mật khẩu</span> 
						</NavLink>
					</li> */}
                    <li>
                        <a href="!#" onClick={this.handleLogout}>
                            <em className="ri-arrow-right-s-line"></em>
                            <span>Đăng xuất</span>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default NavCustomer;
