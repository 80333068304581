
const API_URL = process.env.REACT_APP_API_ENDPOINT;
export default async function loginUser(credentials) {
	return fetch(API_URL + '/Api/TokenWeb/Login', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	}).then(data => data.json())
} 