var initialState = [];

const banners = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_BANNERS":
            return action.banners;  
        default: return state;
    }  
}; 

export default banners;