import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchRegistrationListManageRequest } from '../../../actions/index';
import { DownloadTableExcel } from 'react-export-table-to-excel';

// form
import InputField from '../../form-control/InputField';
import Select from '../../form-control/SelectField';

import LoanListPopup from '../../popup/ContentPopup/LoanList';

function formatDate($date) {
    var date = new Date($date);
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return day + '/' + month + '/' + year;
}

class LoanRegistrationListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableRef: React.createRef(),
            pageIndex: 0,
            keyword: '',
            statusId: '1',
        };
        this.tableRef = React.createRef(null);
    }

    componentDidMount() {
        var json = {
            pageIndex: this.state.pageIndex,
            keyword: this.state.keyword,
            statusId: this.state.statusId,
        };
        this.props.fetchLoanRegisManageList(json);
    }

    handleSearch = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        var search = {};
        const pageIndex = this.state.pageIndex;
        formData.append('PageIndex', pageIndex);
        formData.forEach(function (value, key) {
            search[key] = value;
        });
        this.props.fetchLoanRegisManageList(search);
    };

    render() {
        var { form, orders } = this.props;
        var loanTableList = [];
        var i = 0;
        orders?.forEach((element, index) => {
            i++;
            const item = {
                number: i,
                ApplicantId: element.ApplicantId,
                Code: element.Code,
                BankName: element.BankName,
                Status: element.Status,
                FileName: element.FileName,
                FileUrl: element.FileUrl,
                Date: formatDate(element.CreateDateOn),
                FullName: element.FullName,
                Gender: element.Gender,
                Email: element.Email,
                PhoneNumber: element.Phone,
                Address: element.Address,
                AddressWard: element.AddressWard,
                AddressDistrict: element.AddressDistrict,
                AddressProvince: element.AddressProvince,
                IdentityCardNumber: element.IdentityCardNumber,
                IdentityCardAddress: element.IdentityCardAddress,
                IdentityCardDate: element.IdentityCardDate,
                ProductName: element.ProductName,
                ProductModelName: element.ProductModelName,
                LoanAmount: element.LoanAmount,
                LoanTerm: element.LoadPeriod,
            };
            loanTableList.push(item);
        });

        const tableHeader = (
            <thead>
                <tr>
                    <th>Ngân hàng</th>
                    <th>Số đăng ký</th>
                    <th>Ngày đăng ký</th>
                    <th>Họ và tên</th>
                    {/* <th>Số điện thoại</th>
                    <th>Email</th> */}
                    <th>Loại xe</th>
                    <th>Số tiền vay</th>
                    <th>Thời gian vay</th>
                    <th>File đề nghị</th>
                    <th>Trạng thái</th>
                </tr>
            </thead>
        );

        return (
            <div className="box-admin box-loan box-loan-registration-list">
                <h1 className="heading-title fz-24 text-white fw-700">Danh sách Đăng ký vay </h1>
                <div className="admin-loan-registration-form">
                    <form method="post" onSubmit={this.handleSearch}>
                        <div className="wrap-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="Keyword"
                                            type="text"
                                            label="Tìm kiếm"
                                            placeholder="Nhập từ khóa..."
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="StatusId"
                                            label="Trạng thái"
                                            required
                                            options={[
                                                {
                                                    id: 1,
                                                    value: '0',
                                                    label: 'Mới',
                                                },
                                                {
                                                    id: 2,
                                                    value: '10',
                                                    label: 'Đã liên hệ - khách đã phản hồi',
                                                },
                                                {
                                                    id: 3,
                                                    value: '20',
                                                    label: 'Đã liên hệ - khách không phản hồi',
                                                },
                                            ]}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group frm-btnwrap">
                                        <div className="frm-btn">
                                            <input
                                                className="btn btn-default frm-btn-submit"
                                                type="submit"
                                                value="Tìm kiếm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="table-loan-registration">
                    <div className="table">
                        <table ref={this.tableRef}>
                            {tableHeader}
                            <tbody>
                                {loanTableList?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.BankName}</td>
                                        <td>
                                            <LoanListPopup item={item} />
                                        </td>
                                        <td>{item.Date}</td>
                                        <td>{item.FullName}</td>
                                        {/* <td>{item.PhoneNumber}</td>
                                        <td>{item.Email}</td> */}
                                        <td>{item.ProductName}</td>
                                        <td>{item.LoanAmount}</td>
                                        <td>{item.LoanTerm} Tháng</td>
                                        <td>
                                            <a href={item.FileUrl} title={item.FileName} download="">
                                                {item.FileName}
                                            </a>
                                        </td>
                                        <td>{item.Status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="form-group frm-btnwrap">
                        <div className="frm-btn">
                            <DownloadTableExcel
                                filename="danh-sach-dang-ky-vay"
                                sheet="Danh sách đăng ký vay"
                                currentTableRef={this.tableRef.current}
                            >
                                <button className="btn btn-default frm-btn-submit btn-download-excel" type="button">
                                    Export excel
                                </button>
                            </DownloadTableExcel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orders: state.orders,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchLoanRegisManageList: (body) => {
            dispatch(actFetchRegistrationListManageRequest(body));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanRegistrationListComponent);
