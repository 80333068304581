import React from 'react';
import Login from '../../../components/customer/Login/index';

import LoanComponent from '../../../components/customer/Loan'; 
import useToken from '../../../actions/useToken-customer';

function CustomerLoanPage(props) {
    const { token, setToken } = useToken();
    if(!token) { 
      return <Login setToken={setToken} />
    } 
	return (
		<>
			<LoanComponent></LoanComponent>
		</>
	);
}

export default CustomerLoanPage;

