import React from 'react';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import InputField from '../../form-control/InputField';
import Select from '../../form-control/SelectField';

import './styles.sass';

function LoanRegistrationListComponent(props) {
    const loanList = [
        {
            id: 1,
            title: 'VP-0092',
            bankName: 'VPBank',
            time: '20/12/2021',
            fullName: 'Nguyễn Văn A',
            phoneNumber: '0908407123',
            email: 'abc@gmail.com',
            productType: 'SantaFe Dầu Cao Cấp 2021',
            endow: 'Ưu đãi 1 - 5.8% trong 6 tháng',
            loanPrice: '1.100.000.000 ',
            borrowedTime: '4',
            fileName: 'VP-0092',
            fileUrl: '',
            status: 'Từ chối',
            dateUpdate: '2/1/2021',
            notificationFileName: 'test',
            notificationFileUrl: '',
            note: 'KH khai báo sai',
        },
        {
            id: 2,
            title: 'VP-0093',
            bankName: 'VPBank',
            time: '20/12/2021',
            fullName: 'Nguyễn Văn A',
            phoneNumber: '0908407123',
            email: 'abc@gmail.com',
            productType: 'SantaFe Dầu Cao Cấp 2021',
            endow: 'Ưu đãi 1 - 5.8% trong 6 tháng',
            loanPrice: '1.100.000.000 ',
            borrowedTime: '4',
            fileName: 'VP-0093',
            fileUrl: '',
            status: 'Từ chối',
            dateUpdate: '2/1/2021',
            notificationFileName: 'test',
            notificationFileUrl: '',
            note: 'KH khai báo sai',
        },
        {
            id: 3,
            title: 'VP-0094',
            bankName: 'VPBank',
            time: '20/12/2021',
            fullName: 'Nguyễn Văn A',
            phoneNumber: '0908407123',
            email: 'abc@gmail.com',
            productType: 'SantaFe Dầu Cao Cấp 2021',
            endow: 'Ưu đãi 1 - 5.8% trong 6 tháng',
            loanPrice: '1.100.000.000 ',
            borrowedTime: '4',
            fileName: 'VP-0094',
            fileUrl: '',
            status: 'Từ chối',
            dateUpdate: '2/1/2021',
            notificationFileName: 'test',
            notificationFileUrl: '',
            note: 'KH khai báo sai',
        },
        {
            id: 4,
            title: 'VP-0095',
            bankName: 'VPBank',
            time: '20/12/2021',
            fullName: 'Nguyễn Văn A',
            phoneNumber: '0908407123',
            email: 'abc@gmail.com',
            productType: 'SantaFe Dầu Cao Cấp 2021',
            endow: 'Ưu đãi 1 - 5.8% trong 6 tháng',
            loanPrice: '1.100.000.000 ',
            borrowedTime: '4',
            fileName: 'VP-0095',
            fileUrl: '',
            status: 'Từ chối',
            dateUpdate: '2/1/2021',
            notificationFileName: 'test',
            notificationFileUrl: '',
            note: 'KH khai báo sai',
        },
    ];

    // form
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });
    return (
        <div className="box-admin box-loan box-loan-registration-list">
            <h1 className="heading-title fz-24 text-white fw-700">Danh sách Đăng ký vay</h1>
            <div className="admin-loan-registration-form">
                <form>
                    <div className="wrap-form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="Search"
                                        type="text"
                                        label="Tìm kiếm"
                                        defaultValue="Nhập từ khóa..."
                                        form={form}
                                        // onChange={})}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="Status"
                                        label="Trạng thái"
                                        required
                                        options={[
                                            {
                                                id: 1,
                                                value: '-1',
                                                label: 'Chưa tiếp nhận',
                                            },
                                            {
                                                id: 2,
                                                value: '10',
                                                label: 'Đang xử lý',
                                            },
                                            {
                                                id: 3,
                                                value: '30',
                                                label: 'Hoàn tất',
                                            },
                                            {
                                                id: 4,
                                                value: '40',
                                                label: 'Hủy',
                                            },
                                        ]}
                                        // handleChange={this.handleStatusChange}
                                        form={form}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group frm-btnwrap">
                                    <div className="frm-btn">
                                        <input
                                            className="btn btn-default frm-btn-submit"
                                            type="submit"
                                            value="Tìm kiếm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="table-loan-registration">
                <form action="">
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Ngân hàng</th>
                                    <th>Số Đăng ký</th>
                                    <th>Ngày đăng ký</th>
                                    <th>Họ và tên</th>
                                    <th>Số điện thoại</th>
                                    <th>Email</th>
                                    <th>Loại xe</th>
                                    <th>Ưu đãi</th>
                                    <th>Số tiền vay</th>
                                    <th>Thời gian vay</th>
                                    <th>File đề nghị</th>
                                    <th>Trạng thái</th>
                                    <th>Ngày cập nhật</th>
                                    <th>File thông báo</th>
                                    <th>Ghi chú</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loanList.map((loanItem) => (
                                    <tr key={loanItem.id}>
                                        <td class="text-main">{loanItem.bankName}</td>
                                        <td>{loanItem.title}</td>
                                        <td>{loanItem.time}</td>
                                        <td>{loanItem.fullName}</td>
                                        <td>
                                            <a href={`tel: ${loanItem.phoneNumber}`} title={loanItem.phoneNumber}>
                                                {loanItem.phoneNumber}
                                            </a>
                                        </td>
                                        <td>
                                            <a href={`mailto: ${loanItem.email}`} title={loanItem.email}>
                                                {loanItem.email}
                                            </a>
                                        </td>
                                        <td>{loanItem.productType}</td>
                                        <td>{loanItem.endow}</td>
                                        <td>{loanItem.loanPrice}</td>
                                        <td>{loanItem.borrowedTime}</td>
                                        <td>
                                            <a href={loanItem.fileUrl} title={loanItem.fileName}>
                                                {loanItem.fileName}
                                            </a>
                                        </td>
                                        <td>{loanItem.status}</td>
                                        <td>{loanItem.dateUpdate}</td>
                                        <td>
                                            <a
                                                href={loanItem.notificationFileUrl}
                                                title={loanItem.notificationFileName}
                                            >
                                                {loanItem.notificationFileName}
                                            </a>
                                        </td>
                                        <td>{loanItem.note}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="button-group flex align-center wrap">
                        <div className="button">
                            <a href="/#" className="btn btn-blue">
                                Xuất Excel
                            </a>
                        </div>
                        <div className="button">
                            <a href="/#" className="btn btn-light-blue">
                                Import thông báo
                            </a>
                        </div>
                        <div className="button">
                            <a href="/#" className="btn btn-67">
                                Lưu
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoanRegistrationListComponent;
