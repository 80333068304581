import React from 'react';

import Step2Component from "../../components/step/Step2";

function Step2Pages(props) {
	return (
		<section className="step-section section bg-login bdbt-gray">
			<div className="container">
				<Step2Component />
			</div>
		</section>
	);
}

export default Step2Pages;