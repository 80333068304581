import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

InputField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    isCurrency: PropTypes.bool,
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={0}
            valueIsNumericString
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function InputField(props) {
    const {
        form,
        name,
        label,
        type,
        placeholder,
        disabled,
        defaultValue,
        value,
        title,
        required,
        onChange,
        isCurrency,
    } = props;
    const { errors, touchedFields } = form.formState;
    const hasError = touchedFields[name] && errors[name];
    return (
        <Controller
            control={form.control}
            name={name}
            render={({ field }, formState) =>
                isCurrency ? (
                    <TextField
                        fullWidth
                        label={label}
                        {...field}
                        id={name}
                        type={type}
                        placeholder={placeholder}
                        error={!!hasError}
                        helperText={errors[name]?.message}
                        disabled={disabled}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={defaultValue}
                        value={value}
                        onChange={onChange}
                        required={required}
                        title={title}
                        InputProps={{ inputComponent: NumberFormatCustom }}
                    />
                ) : (
                    <TextField
                        fullWidth
                        label={label}
                        {...field}
                        id={name}
                        type={type}
                        placeholder={placeholder}
                        error={!!hasError}
                        helperText={errors[name]?.message}
                        disabled={disabled}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={defaultValue}
                        value={value}
                        onChange={onChange}
                        required={required}
                        title={title}
                    />
                )
            }
        />
    );
}

export default InputField;
