import React from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import InputField from '../../../form-control/InputField';

function Form(props) {
    const { onSubmit } = props;

    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });
    return (
        <>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="wrap-form">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputField name="email" type="email" label="Email" form={form} required />
                            </div>
                        </div>
                        <div className="col-md-7">
                            {/* <div className="form-group frm-captcha">
                                <img src="http://html.canhcam.asia/tcmotor/img/capcha.png" alt=""/>
                            </div> */}
                        </div>
                        <div className="col-md-5">
                            <div className="form-group frm-btnwrap">
                                <div className="frm-btn">
                                    <input className="btn btn-default frm-btn-submit" type="submit" value="Gửi" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Form;
