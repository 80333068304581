import React from 'react';

import './styles.sass';

// popup
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import TableLoanOfferDetail from './Table';

// icon
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = (theme) => ({
	root: {
		margin: 0,
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});
function replaceName($name)
{
	if($name ==="Ngày")
		$name = "Ngay";

	if($name ==="Kỳ hạn")
		$name = "Ky han";

	if($name ==="Gốc còn lại")
		$name = "Goc con lai";

	if($name ==="Gốc")
		$name = "Goc";

	if($name ==="Lãi")
		$name = "Lai";

	if($name ==="Tổng G+L")
		$name = "Tong G+L";

	if($name ==="Tổng")
		$name = "Tong";
	return $name;
}
function exportData(){
    /* Get the HTML data using Element by Id */
    var table = document.getElementById("tblStocks");
 
    /* Declaring array variable */
    var rows =[];
 
	//iterate through rows of table
    //eslint-disable-next-line
    for(var i = 0, row; row = table.rows[i];i++){
		//rows would be accessed using the "row" variable assigned in the for loop
		//Get each cell value/column from the row
		let column1 = replaceName(row.cells[0].innerText);
		let column2 = replaceName(row.cells[1].innerText);
		let column3 = replaceName(row.cells[2].innerText);
		let column4 = replaceName(row.cells[3].innerText);
		let column5 = replaceName(row.cells[4].innerText);
		let column6 = replaceName(row.cells[5].innerText);


    	/* add a new records in the array */
        rows.push(
            [
                column1,
                column2,
                column3,
                column4,
                column5,
				column6
            ]
        );
 
        }
       	let  csvContent = "data:text/csv;charset=utf-8,";
         /* add the column delimiter as comma(,) and each row splitted by new line character (\n) */
        rows.forEach(function(rowArray){
            row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
 
        /* create a hidden <a> DOM node and set its download attribute */
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Calculate_Loan_Report.csv");
        document.body.appendChild(link);
        link.click();
}
const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{children}
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(0),
	},
}))(MuiDialogContent);

function LoanOfferRepaymentSchedulePopup(props) {
	const {calculateLoan} = props;
	// popup
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const ExportLoan = (e) => {
		e.preventDefault();
		exportData();
	};

	return (
		<>
			<Button 
				className="btn btn-blue"
				variant="outlined" 
				color="primary" 
				onClick={handleClickOpen}
			>
				<span>Lịch trả chi tiết</span>
			</Button>
			<Dialog maxWidth="xl" onClose={handleClose} aria-labelledby="customized-dialog-title-3" open={open}>
				<div className="content-popup" id="loan-info">
					<section className="loan-info-section loan-offer-section"> 
						<div className="box-form-popup bg-white">
							<DialogTitle id="customized-dialog-title-3" onClose={handleClose}>
								<h3 className="main-title text-center">Lịch trả nợ chi tiết</h3>
							</DialogTitle>
							<DialogContent>
								
								<TableLoanOfferDetail  calculateLoan={calculateLoan}/>

								<div className="button-wrap">
									<div className="button">
										<a href="!#" className='btn btn-blue' onClick={ExportLoan}>
											<GetAppIcon />
											<span>Tải lịch trả nợ chi tiết</span>
										</a>
									</div>
								</div>
							</DialogContent>
						</div>
					</section>
				</div>
			</Dialog>
		</>
	)
}

export default LoanOfferRepaymentSchedulePopup;
