var initialState = [];

const orders = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_ORDER_LIST":
			return action.orders;
		default: return state;
	}
};

export default orders;