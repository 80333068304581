import React, { Component } from 'react';

import ProductSelect from "../../../../form-control/product-select";
import SelectProvince from "../../../../form-control/select-provinces";
import StoreSelect from "../../../../form-control/store-select";

class FormStep1Left extends Component {
    render() {
        var {
            form,
            colorList, 
            models,
            productSelectItems,
            storeList,
            handleProductChange,
            handleProvinceChange,
            onChangeColor,
            onChangeModelType,
            colorTitle,
            onChangeStore,
        } = this.props;

        return (
            <div className="row">
                <ProductSelect form={form}
                    colorList={colorList}
                    models={models}
                    productSelectItems={productSelectItems}
                    onProductChange={handleProductChange}
                    onChangeModelType={onChangeModelType}
                    onChangeColor={onChangeColor}
                    colorTitle={colorTitle}
                />
                <div className="col-12"> 
                    <div className="form-group"> 
                        <SelectProvince form={form}  handleChange={handleProvinceChange} />
                    </div>
                </div>
                <div className="col-12">
                    <StoreSelect form={form} showroomList={storeList} handleChange={onChangeStore}/>
                </div>
            </div> 
        );
    }
}

export default FormStep1Left;
