import React from 'react';

import Get from '../../../../utils/APIsHelper';

import '../styles.sass';
import successIcon from '../../../../assets/images/success-icon.png';

// popup
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import RequestCounsellingForm from './Form/index';

const styles = (theme) => ({
	root: {
		margin: 0,
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});


const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{children}
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(0),
	},
}))(MuiDialogContent);

function RequestCounsellingFormComponent({ buttonText }) {
	// popup
	const [openDialog, setOpenDialog] = React.useState(false);
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	let save = (e) => {
		e.preventDefault();
		let storeId = '';
		if (e.target.Store !== undefined)
			storeId = e.target.Store.value;
		let productColorId = e.target.ProductColor.value;
		let productId = e.target.Product.value;
		let productModelId = e.target.ModelType.value;

		let fullName = e.target.FullName.value;
		let phoneNumber = e.target.PhoneNumber.value;
		let email = e.target.Email.value;
		let body = {
			storeId,
			productId,
			productModelId,
			productColorId,
			fullName,
			phoneNumber,
			email
		};
		Get('api/requestCounselling/Create', 'post', body).then((res) => {
			if (res.data.success) {
				setOpen(false);
				setOpenDialog(true);
			}
		});
	};

	const schema = yup.object().shape({
		content: yup.string().required("Please enter title"),
	});

	const form = useForm({
		defaultValues: {
			content: "",
		},
		resolver: yupResolver(schema),
	});

	const showButton = (buttonText) => {
		if (buttonText !== undefined) {
			return <>
				<Button
					className="btn btn-light-blue"
					variant="outlined"
					color="primary"
					onClick={handleClickOpen}
				>
					<span>{buttonText}</span>
				</Button>
			</>;
		} else {
			return <>
				<Button
					className="btn-request-counselling"
					variant="outlined"
					color="primary"
					onClick={handleClickOpen}
				>
					<em className="ri-edit-2-line"> </em>
				</Button>
			</>;
		}
	};
	return (
		<>
			{showButton(buttonText)}
			<Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title-3" open={open}>
				<div className="form-popup" id="request-counselling">
					<section className="request-counselling-section">
						<div className="box-form-popup bg-white">
							<DialogTitle id="customized-dialog-title-3" onClose={handleClose}>
								<h3 className="main-title text-center">Yêu cầu tư vấn</h3>
							</DialogTitle>
							<DialogContent>
								<form method="post" onSubmit={save}>
									<RequestCounsellingForm form={form} />
								</form>
							</DialogContent>
						</div>
					</section>
				</div>
			</Dialog>

			<Dialog
				maxWidth="lg"
				onClose={handleDialogClose}
				aria-labelledby="customized-dialog-title"
				open={openDialog}
			>
				<div className="form-popup" id="check-order">
					<section className="success-section">
						<div className="box-form-popup bg-white">
							<DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
								<h3 className="main-title text-center">Thành công</h3>
							</DialogTitle>
							<DialogContent>
								<div className="success">
									<div className="image">
										<img src={successIcon} alt="success"></img>
									</div>
									<div className="description text-center">
										<p>Đã đăng ký thành công</p>
									</div>
								</div>
							</DialogContent>
						</div>
					</section>
				</div>
			</Dialog>
		</>
	);
}

export default RequestCounsellingFormComponent;