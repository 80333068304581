
const API_URL = process.env.REACT_APP_API_ENDPOINT;
export default async function userInfoVendors() {
	let tokenString = sessionStorage.getItem('token-vendors');
	let userToken = JSON.parse(tokenString);
	return fetch(API_URL + '/api/Customer/UserInfo', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Token': userToken?.token
		},
	}).then(data => data.json())
} 