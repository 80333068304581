import React, { Component } from 'react';

class FormStep2Left extends Component {
    render() { 
        const {orderDetail} = this.props;
        return ( 
            <div className="box-products">
                <div className="wrap-form"> 
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group form-title">
                                <h2 className="title fz-14 fw-600 text-main text-up">
                                    Thông tin khách hàng
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-6">
							<div className="form-group">
								<div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
									<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true" htmlFor="FullName" id="FullName-label">
										Họ tên
									</label>
									<div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl">
										<input aria-invalid="false" id="FullName" name="FullName" 
										type="text" className="MuiInputBase-input MuiInput-input" defaultValue={orderDetail.FullName} readOnly/>
									</div>
								</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
								<div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
									<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true" htmlFor="CCCD" id="CCCD-label">
										CCCD/CMTND
									</label>
									<div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl">
										<input aria-invalid="false" id="CCCD" name="CCCD" type="text" 
										className="MuiInputBase-input MuiInput-input" defaultValue={orderDetail.CCCD} readOnly/>
									</div>
								</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
								<div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
									<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true" htmlFor="PhoneNumber" id="PhoneNumber-label">
										Số điện thoại
									</label>
									<div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl">
										<input aria-invalid="false" id="PhoneNumber" name="PhoneNumber" type="text"
										 className="MuiInputBase-input MuiInput-input" defaultValue={orderDetail.PhoneNumber} readOnly/>
									</div>
								</div>
                            </div>
                        </div>
                        <div className="col-md-6"> 
                            <div className="form-group"> 
								<div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
									<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true" htmlFor="Email" id="Email-label">
										Email
									</label>
									<div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl">
										<input aria-invalid="false" id="Email" name="Email" type="text"
										 className="MuiInputBase-input MuiInput-input" defaultValue={orderDetail.Email} readOnly/>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormStep2Left; 