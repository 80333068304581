import React from 'react';

import imagesBanner from '../../assets/images/child.png';

import BannerChildComponent from "../../components/banner/BannerChild";
import BreadcrumbComponent from "../../components/breadcrumb";
import ContactComponent from "../../components/contact";

function ContactPages(props) {
    return (
        <>
            <BannerChildComponent imageBannerUrl={imagesBanner}/>
            <BreadcrumbComponent />
            <ContactComponent />
        </>
    );
}

export default ContactPages;