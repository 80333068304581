import React from 'react';
import PropTypes from 'prop-types';

TablePayment.propTypes = {
    paymentTableList: PropTypes.array.isRequired,
};

function TablePayment({paymentTableList}) {
    return ( 
        <>
            <div className="table table-payments">
                <h3 className="title-table fw-700 text-main fz-14 text-up">Các đợt thanh toán</h3>
                <table> 
                    <thead> 
                        <tr> 
                            <th>Đợt</th>
                            <th>Ngày thành toán</th>
                            <th>Số tiền thanh toán</th>
                            <th>Các file ủy nhiệm chi</th>
                            <th>Phương thức thanh toán</th>
                            <th>Trạng thái</th>
                            <th>Thời gian nhận</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {paymentTableList.map(paymentTable => (
                            <tr key={paymentTable.Id}> 
                                <td>
									Lần {paymentTable.number}
                                </td>
                                <td>{paymentTable.CreatedOn}</td>
                                <td> 
									{paymentTable.PaidAmount} đ
                                </td>
                                <td> 
                                    {paymentTable.Files.map(({file,index}) => (
                                        <a className="btn btn-file" href={file} download="">
                                            File {index} 
                                        </a>
                                    ))}
                                </td>
                                <td>{paymentTable.PaymentMethodName}</td>
                                <td>{paymentTable.PaymentStatus}</td>
                                <td>{paymentTable.CreatedOn}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TablePayment;