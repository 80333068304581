import React from 'react';

import '../styles.sass'
import AdminNavLink from '../Menu/index';
import Form from './Form';

function ChangePasswordComponent(props) {
	return (
		<section className="admin-section section bg-login bdbt-gray">
			<div className="container"> 
				<div className="row row-space">
					<div className="col-lg-4 col-xl-3"> 
						<AdminNavLink/>
					</div>
					<div className="col-lg-8 col-xl-9"> 
						<Form />
					</div>
				</div>
			</div>
		</section>
	);
}

export default ChangePasswordComponent;