import React from 'react';

import './styles.sass';
import ContactForm from './ContactForm';
import HtmlContent from '../html-content/index';

function ContactComponent() {
    return (
        <section className="contact-section section"> 
            <div className="container"> 
                <HtmlContent system={'ContactInfo-Info'}/>  
                <div className="form-wrap"> 
                    <HtmlContent system={'ContactInfo-Form'}/>  
                    <ContactForm />
                </div>
            </div>  
        </section>
    );
}

export default ContactComponent;