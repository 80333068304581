import React, { Component } from 'react';

import { connect } from 'react-redux';
import { actFetchRegistrationListRequest } from '../../../actions/index';

import LoanListPopup from '../../popup/ContentPopup/LoanList';

// import { DownloadTableExcel } from 'react-export-table-to-excel';

// form
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";

import './styles.sass';

class LoanRegistrationListComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tableRef: React.createRef(),
			pageIndex: 0,
			pageSize: 20,
		};
	}

	componentDidMount() {
		var json = {
			pageIndex: 0,
			pageSize: 20,
		};
		this.props.fetchLoanRegisList(json);
	}

	render() {
		const tableHeader = (
			<thead>
				<tr>
					<th>STT</th>
					<th>Số đề nghị</th>
					<th>
						Đăng ký vay
						<br />
						(Ngân hàng/ Ngày)
					</th>
					<th>Ngày, giờ</th>
					<th>Tình trạng hồ sơ</th>
					<th>File đăng ký</th>
				</tr>
			</thead>
		);
		var { orders } = this.props;
		var loanTableList = [];
		let i = 0;
		orders?.forEach((element, index) => {
			i++;
			loanTableList.push({
				ID: element.Id,
				number: i,
				ProductName: element.ProductName,
				ProductModelName: element.ProductModelName,
				PriceUnit: element.PriceUnit,
				LoanAmount: element.LoanAmount,
				LoanTerm: element.LoadPeriod,
				FullName: element.FullName,
				DateOfBirth: element.DateOfBirth,
				Gender: element.Gender,
				IdentityCardNumber: element.IdentityCardNumber,
				IdentityCardDate: element.IdentityCardDate,
				IdentityCardAddress: element.IdentityCardAddress,
				AddressProvince: element.AddressProvince,
				AddressDistrict: element.AddressDistrict,
				PhoneNumber: element.Phone,
				Address: element.Address,
				ApplicantId: element.ApplicantId,
				Code: element.Code,
				BankName: element.BankName,
				Status: element.Status,
				FileName: element.FileName,
				FileUrl: element.FileUrl,
				CreateOn: element.CreateOn,
			});
		});

		return (
			<div className="box-admin box-loan box-loan-registration-list">
				<h1 className="heading-title fz-24 text-white fw-700">Danh sách Đăng ký vay</h1>
				<div className="table-loan-registration">
					<form action="">
						<div className="table">
							<table ref={this.tableRef}>
								{tableHeader}
								<tbody>
									{loanTableList.map((loanItem) => (
										<tr key={loanItem.number}>
											{/* <td>
												<div className="form-group form-checkbox">
													<input
														type="checkbox"
														id={`loan-${loanItem.id}`}
														name="LoanRegistration"
													/>
												</div>
											</td> */}
											<td className="id">
												<strong>
													{loanItem.number < 10 ? '0' : ''}
													{loanItem.number}
												</strong>
											</td>
											<td>
												<LoanListPopup item={loanItem} />
											</td>
											<td>{loanItem.BankName}</td>
											<td>{loanItem.CreateOn}</td>
											<td>{loanItem.Status}</td>
											<td>
												<a href={loanItem.FileUrl} title={loanItem.FileName}>
													{loanItem.FileName}
												</a>
											</td>
											{/* <td>
												<a
													href={loanItem.notificationFileUrl}
													title={loanItem.notificationFileName}
												>
													{loanItem.notificationFileName}
												</a>
											</td>
											<td>
												<a href={`tel: ${loanItem.chat}`} title={loanItem.chat}>
													{loanItem.chat}
												</a>
											</td>
											<td>
												<a href={`tel: ${loanItem.hotline}`} title={loanItem.hotline}>
													{loanItem.hotline}
												</a>
											</td> */}
										</tr>
									))}
								</tbody>
							</table>
						</div>
						{/* <div className="form-group frm-btnwrap">
							<div className="frm-btn">
								<DownloadTableExcel filename="users table" sheet="users" currentTableRef={tableRef.current}>
									<button className="btn btn-default frm-btn-submit btn-download-excel" type="button">
										Xuất excel
									</button>
								</DownloadTableExcel>
							</div>
						</div> */}
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		orders: state.orders,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchLoanRegisList: (body) => {
			dispatch(actFetchRegistrationListRequest(body));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanRegistrationListComponent);
