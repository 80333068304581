import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { actFetchOrderDetailRequest } from '../../../actions/index';


import InforTable from './InforTable';
import ProductTable from './ProductTable';
import TablePayment from './TablePayment';
import CustomerNavLink from '../Menu/index';


function getQueryVariable(variable)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable)
            return pair[1];
    }
    return null;
}
class PurchaseHistoryDetailComponent extends Component {
    componentDidMount() { 
        var id = getQueryVariable('id');
        var token = getQueryVariable('token');
        if(id !== null && token !== null)
		    this.props.fetchOrderDetail(id, token);  
    }
    render() {
        var { orderDetail } = this.props;
        var id = getQueryVariable('id');
        var token = getQueryVariable('token');
        if(id === null || token === null)
			return <Redirect to='/error' />;
            
        const paymentTableList = [];
        if(orderDetail.PaymentTransactions !=null && orderDetail.PaymentTransactions.length > 0 )
        {
            var number = 0;
            orderDetail.PaymentTransactions.forEach((element) => {
                number ++;
                element.number = number; 
                element.status = [];
                if(element.Files === null)
                    element.Files = [];
                paymentTableList.push(element);
            });
        }
        return (
            <section className="admin-section customer-section section bg-login bdbt-gray">
                <div className="container"> 
                    <div className="row row-space">
                        <div className="col-lg-4 col-xl-3"> 
                            <CustomerNavLink/> 
                        </div>
                        <div className="col-lg-8 col-xl-9"> 

                            <InforTable order={orderDetail}/>
                            
                            <ProductTable orderDetail={orderDetail}/>

                            <TablePayment paymentTableList={paymentTableList}/>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
const mapStateToProps = (state) => {
	return { 
		orderDetail: state.orderDetail,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchOrderDetail: (orderId, token) => {
            let body = {Key : orderId, token: token};
			dispatch(actFetchOrderDetailRequest(body));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseHistoryDetailComponent);