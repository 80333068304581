import React from 'react';

import CollapsedBreadcrumbs from './CollapsedBreadcrumbs'

function BreadcrumbComponent(props) {
    return (
        <section className="global-breakcrumb"> 
            <div className="container"> 
                <CollapsedBreadcrumbs />
            </div>
        </section>
    );
}

export default BreadcrumbComponent;