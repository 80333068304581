import React from 'react';
import Login from '../../../components/customer/Login/index';

import PurchaseHistoryDetailComponent from '../../../components/customer/PurchaseHistoryDetail'; 
import useToken from '../../../actions/useToken-customer';

function CustomerPointHistoryDetailPages(props) {
    const { token, setToken } = useToken();
    if(!token) { 
      return <Login setToken={setToken} />
    } 
    return ( 
        <> 
            <PurchaseHistoryDetailComponent />
        </>
    );
}

export default CustomerPointHistoryDetailPages;