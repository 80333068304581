import React from 'react';

function TableLoanOfferDetail(props) {
	const {calculateLoan} = props;
	return (
		<>
			<div className="table table-3">
				<table>
					<thead>
						<tr>
							<th>Gốc phải trả</th>
							<th>Lãi phải trả</th>
							<th>Tổng gốc và lãi</th>
							<th>Gốc và lãi bình quân hàng tháng</th>
							<th>Gốc và lãi tháng đầu tiên</th>
							<th>Gốc và lãi tháng cao nhất</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{calculateLoan.principalPayable}</td>
							<td>{calculateLoan.interestPayable}</td>
							<td>{calculateLoan.totalPrincipalAndInterest}</td>
							<td>{calculateLoan.principalAndAverageMonthlyInterest}</td>
							<td>{calculateLoan.principalAndFirstMonthlyInterest}</td>
							<td>{calculateLoan.principalAndHighestMonthlyInterest}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="table table-4">
				<table id="tblStocks">
					<thead>
						<tr>
							<th>Ngày</th>
							<th>Kỳ hạn</th>
							<th>Tiền còn phải trả</th>
							<th>Tiền gốc phải trả/ tháng</th>
							<th>Tiền lãi phải trả/ tháng	</th>
							<th>Tổng tiền trả hàng tháng</th>
						</tr>
					</thead>
					<tbody>
						{calculateLoan.data.map(repaymentItem =>(
							<tr key={repaymentItem.ID.toString()}>
								<td>{repaymentItem.Date}</td>
								<td>{repaymentItem.PaymentDebit}</td>
								<td>{repaymentItem.CurrencyOriginal}</td>
								<td>{repaymentItem.Original}</td>
								<td>{repaymentItem.Interest}</td>
								<td>{repaymentItem.TotalGL}</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td>Tổng</td>
							<td></td>
							<td></td>
							<td>{calculateLoan.principalPayable}</td>
							<td>{calculateLoan.interestPayable}</td>
							<td>{calculateLoan.totalPrincipalAndInterest}</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</>
	);
}

export default TableLoanOfferDetail;
