import React from 'react';
import PropTypes from 'prop-types';

import LoginForm from './Form';

export default function LoginComponent({ setToken }) {
    return (
        <section className="login-section section bg-login bdbt-gray">
            <div className="container">
                <div className="box-form-login bg-white">
                    <h1 className="main-title text-center">Đăng nhập</h1>
                    <LoginForm setToken={setToken} />
                </div>
            </div>
        </section>
    );
}

LoginComponent.propTypes = {
    setToken: PropTypes.func.isRequired,
};
