import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    actFetchProductsRequest,
    actFetchProductPriceRequest,
    actFetchCalculateLoanRequest,
} from '../../../actions/index';
// form
import InputField from '../../form-control/InputField';
import Select from '../../form-control/SelectField';

// import button popup
import LoanOfferRepaymentSchedulePopup from '../../popup/ContentPopup/LoanOfferRepaymentSchedule';
import LoanOfferConsultationFormPopup from '../../popup/FormPopup/LoanOfferConsultation';

import './styles.sass';

var promotionCodes = [
    {
        Id: 1,
        Name: 'Ưu đãi 1 - 8.6% trong 3 tháng',
        PreferentialRatePerYear: 8.6,
        PreferentialMonth: 3,
        RatePerYear: 11.7,
    },
    {
        Id: 2,
        Name: 'Ưu đãi 2 - 9.3% trong 6 tháng',
        PreferentialRatePerYear: 9.3,
        PreferentialMonth: 6,
        RatePerYear: 11.9,
    },
    {
        Id: 3,
        Name: 'Ưu đãi 3 - 9.7% trong 12 tháng',
        PreferentialRatePerYear: 9.7,
        PreferentialMonth: 12,
        RatePerYear: 12.1,
    },
];
class LoanOfferFormBankDetailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: '',
            oldPrice: '',
            productIdSelected: '',
            storeIdSelected: '6124a50e674bd75cfdf44c31',
            colorIdSelected: '',
            colorTitleSelected: '',
            modelTypeIdSelected: '',
            interestPayable: '',
            calculateLoan: {},
            calculateLoaning: false,
        };
    }
    componentDidMount() {
        this.props.fetchProducts();
        var productId = localStorage.getItem('productId');
        setTimeout(
            function () {
                var { products } = this.props;
                if (products.length > 0) {
                    if (productId === null || productId === '') productId = products[0].id;

                    this.setState({ productIdSelected: productId });
                    products.forEach((element) => {
                        if (element.id === productId) {
                            if (element.colors.length > 0) this.setState({ colorIdSelected: element.colors[0].id });
                            if (element.modelTypes.length > 0)
                                this.setState({ modelTypeIdSelected: element.modelTypes[0].id });
                        }
                    });

                    this.handleFetchPrice();
                }
            }.bind(this),
            500
        );
    }
    handleProductChange = (event) => {
        this.setState({ productIdSelected: event.target.value });
        var { products } = this.props;
        products.forEach((element) => {
            if (element.id === event.target.value) {
                if (element.colors.length > 0) this.setState({ colorIdSelected: element.colors[0].id });
                if (element.modelTypes.length > 0) this.setState({ modelTypeIdSelected: element.modelTypes[0].id });
            }
        });
        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleColorChange = (event) => {
        this.setState({
            colorIdSelected: event.target.value,
            colorTitleSelected: event.target.getAttribute('data-title'),
        });
        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleModelTypeChange = (event) => {
        this.setState({ modelTypeIdSelected: event.target.value });
        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleFetchPrice = () => {
        this.props.fetchProductPrice(
            this.state.productIdSelected,
            this.state.storeIdSelected,
            this.state.colorIdSelected,
            this.state.modelTypeIdSelected
        );
        setTimeout(
            function () {
                let { productPrice } = this.props;
                let priceValue = '';
                let oldPriceValue = '';
                let discountAmountValue = '';
                let discountGiftValue = '';
                if (productPrice !== null) {
                    priceValue = productPrice.price;
                    oldPriceValue = productPrice.oldPrice;
                    discountAmountValue = productPrice.discountAmount;
                    discountGiftValue = productPrice.discountGift;
                }
                this.setState({
                    price: priceValue,
                    oldPrice: oldPriceValue,
                    discountAmount: discountAmountValue,
                    discountGift: discountGiftValue,
                });
            }.bind(this),
            500
        );
    };
    calculateLoan = ($form) => {
        let promoCode = $form.target.PromoCode.value;
        let paymentMethod = $form.target.PaymentMethod.value;
        let loanAmount = $form.target.LoanAmount.value;
        let loanMonth = $form.target.LoanMonth.value;
        let loanDate = $form.target.LoanDate.value;
        let code = promotionCodes.find((element) => {
            return element.Id === parseInt(promoCode);
        });
        this.props.fetchCalculateLoan(
            loanAmount,
            code.RatePerYear / 100,
            loanMonth * 12,
            loanDate,
            paymentMethod,
            code.PreferentialRatePerYear / 100,
            code.PreferentialMonth
        );
        setTimeout(
            function () {
                const { calculateLoan } = this.props;
                this.setState({
                    calculateLoan,
                    calculateLoaning: true,
                });
            }.bind(this),
            500
        );
    };
    save = (e) => {
        e.preventDefault();
        this.calculateLoan(e);
    };
    render() {
        const { form, products } = this.props;
        var colorList = [];
        var models = [];
        var productSelectItems = [];
        var promotionCodeItems = [];
        var productId = this.state.productIdSelected;
        var colorId = this.state.colorIdSelected;
        var modelTypeId = this.state.modelTypeIdSelected;
        products.forEach((element) => {
            if (productId === '') productId = element.id;

            productSelectItems.push({
                id: element.id,
                value: element.id,
                label: element.title,
            });

            if (models.length === 0 || element.id === productId) {
                models = [];
                element.modelTypes.forEach((modelType) => {
                    if (modelTypeId === '') modelTypeId = modelType.id;
                    models.push({
                        id: modelType.id,
                        value: modelType.id,
                        label: modelType.name,
                    });
                });
            }

            if (colorList.length === 0 || element.id === productId) {
                colorList = [];

                element.colors.forEach((color) => {
                    if (colorId === '') colorId = color.id;
                    colorList.push({
                        id: color.id,
                        value: color.id,
                        label: color.name,
                    });
                });
            }
        });
        promotionCodes.forEach((element) => {
            promotionCodeItems.push({
                id: element.Id,
                value: element.Id,
                label: element.Name,
            });
        });
        return (
            <div className="form-calculate-wrap">
                <div className="main-form">
                    <form id="calculate-form" method="post" onSubmit={this.save}>
                        <div className="wrap-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="PromoCode"
                                            label="Mã ưu đãi"
                                            options={promotionCodeItems}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="PaymentMethod"
                                            label="Phương thức trả lãi"
                                            options={[
                                                {
                                                    id: 1,
                                                    value: '1',
                                                    label: 'Hàng tháng',
                                                },
                                                {
                                                    id: 2,
                                                    value: '2',
                                                    label: 'Hàng quý',
                                                },
                                            ]}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="GracePeriod"
                                            label="Ân hạn gốc (tháng)"
                                            options={[
                                                {
                                                    id: 1,
                                                    value: '0',
                                                    label: '0',
                                                },
                                                {
                                                    id: 2,
                                                    value: '3',
                                                    label: '3',
                                                },
                                                {
                                                    id: 3,
                                                    value: '6',
                                                    label: '6',
                                                },
                                                {
                                                    id: 4,
                                                    value: '9',
                                                    label: '9',
                                                },
                                                {
                                                    id: 5,
                                                    value: '12',
                                                    label: '12',
                                                },
                                            ]}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="TypeOfRepayment"
                                            label="Loại trả nợ"
                                            options={[
                                                {
                                                    id: 1,
                                                    value: '1',
                                                    label: 'Dư nợ giảm dần',
                                                },
                                                {
                                                    id: 2,
                                                    value: '2',
                                                    label: 'Đều hàng tháng',
                                                },
                                            ]}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="ProductId"
                                            label="Loại xe"
                                            options={productSelectItems}
                                            form={form}
                                            handleChange={this.handleProductChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="ProductModelId"
                                            label="Phiên bản"
                                            options={models}
                                            form={form}
                                            handleChange={this.handleAttributeChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="ProductColorId"
                                            label="Màu sắc"
                                            options={colorList}
                                            form={form}
                                            handleChange={this.handleAttributeChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="TemporaryCarPrice"
                                            type="text"
                                            label="Giá xe tạm tính (VNĐ)"
                                            value={this.state.oldPrice}
                                            form={form}
                                            disabled
                                            // onChange={e => this.setState({ startDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="LoanAmount"
                                            type="text"
                                            label="Số tiền cần vay (VNĐ)"
                                            defaultValue="0"
                                            form={form}
                                            // onChange={e => this.setState({ startDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="LoanMonth"
                                            label="Thời hạn vay (năm)"
                                            options={[
                                                {
                                                    id: 1,
                                                    value: '1',
                                                    label: '1',
                                                },
                                                {
                                                    id: 2,
                                                    value: '2',
                                                    label: '2',
                                                },
                                                {
                                                    id: 3,
                                                    value: '3',
                                                    label: '3',
                                                },
                                                {
                                                    id: 4,
                                                    value: '4',
                                                    label: '4',
                                                },
                                                {
                                                    id: 5,
                                                    value: '5',
                                                    label: '5',
                                                },
                                                {
                                                    id: 6,
                                                    value: '6',
                                                    label: '6',
                                                },
                                                {
                                                    id: 7,
                                                    value: '7',
                                                    label: '7',
                                                },
                                                {
                                                    id: 8,
                                                    value: '8',
                                                    label: '8',
                                                },
                                                {
                                                    id: 9,
                                                    value: '9',
                                                    label: '9',
                                                },
                                                {
                                                    id: 10,
                                                    value: '10',
                                                    label: '10',
                                                },
                                            ]}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="LoanDate"
                                            type="date"
                                            label="Ngày giải ngân"
                                            defaultValue="11/05/2022"
                                            form={form}
                                            // onChange={e => this.setState({ startDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group frm-btnwrap">
                                        <div className="frm-btn">
                                            <input
                                                className="btn btn-default frm-btn-submit"
                                                type="submit"
                                                value="Tính toán"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={this.state.calculateLoaning ? 'main-summary ' : 'hidden'}>
                    <h2 className="summary-title fz-30 fw-700 text-main text-center">Tổng hợp lịch trả nợ</h2>
                    <div className="box-summary mx-auto">
                        <div className="box-top">
                            <div className="item fz-14 text-main">
                                <p className="left">
                                    <strong>Gốc phải trả</strong>
                                </p>
                                <p className="right text-black">
                                    <strong>{this.state.calculateLoan.principalPayable}</strong>
                                </p>
                            </div>
                            <div className="item fz-14 text-main">
                                <p className="left">
                                    <strong>Lãi phải trả</strong>
                                </p>
                                <p className="right text-black">
                                    <strong>{this.state.calculateLoan.interestPayable}</strong>
                                </p>
                            </div>
                            <div className="item fz-14 text-main">
                                <p className="left">
                                    <strong>Tổng gốc và lãi</strong>
                                </p>
                                <p className="right text-black">
                                    <strong>{this.state.calculateLoan.totalPrincipalAndInterest}</strong>
                                </p>
                            </div>
                        </div>
                        <div className="box-bot">
                            <div className="item fz-14 text-main">
                                <p className="left">
                                    <strong>Gốc và lãi bình quân hàng tháng</strong>
                                </p>
                                <p className="right text-black">
                                    <strong>{this.state.calculateLoan.principalAndAverageMonthlyInterest}</strong>
                                </p>
                            </div>
                            <div className="item fz-14 text-main">
                                <p className="left">
                                    <strong>Gốc và lãi tháng đầu tiên</strong>
                                </p>
                                <p className="right text-black">
                                    <strong>{this.state.calculateLoan.principalAndFirstMonthlyInterest}</strong>
                                </p>
                            </div>
                            <div className="item fz-14 text-main">
                                <p className="left">
                                    <strong>Gốc và lãi tháng cao nhất</strong>
                                </p>
                                <p className="right text-black">
                                    <strong>{this.state.calculateLoan.principalAndHighestMonthlyInterest}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.calculateLoaning ? 'button-wrap flex align-center center' : 'hidden'}>
                    <div className="button">
                        <LoanOfferConsultationFormPopup />
                    </div>
                    <div className="button form-download">
                        <LoanOfferRepaymentSchedulePopup calculateLoan={this.state.calculateLoan} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products,
        productPrice: state.productPrice,
        calculateLoan: state.calculateLoan,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchProducts: () => {
            dispatch(actFetchProductsRequest('Home', ''));
        },
        fetchProductPrice: (productId, storeId, colorId, modelTypeId) => {
            dispatch(actFetchProductPriceRequest(productId, storeId, colorId, modelTypeId));
        },
        fetchCalculateLoan: (amount, rate, month, dateTime, method, preferentialRatePerYear, preferentialMonth) => {
            dispatch(
                actFetchCalculateLoanRequest(
                    amount,
                    rate,
                    month,
                    dateTime,
                    method,
                    preferentialRatePerYear,
                    preferentialMonth
                )
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfferFormBankDetailComponent);
