import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { actFetchOrderDetailRequest } from '../../../actions/index';

import '../styles.sass';
import Form from './Form';

function getQueryVariable(variable)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable)
            return pair[1];
    }
    return null;
}

class OrderDetail extends Component {
    componentDidMount() { 
        var id = getQueryVariable('id');
        var token = getQueryVariable('token');
        if(id !== null && token !== null)
		    this.props.fetchOrderDetail(id, token);  
    }
    render() {
        var { orderDetail } = this.props;
        var id = getQueryVariable('id');
        var token = getQueryVariable('token');
        if(id === null || token === null)
			return <Redirect to='/error' />;

        return (
            <Form orderDetail={orderDetail} onSubmit={this.onSubmit}/>  
        );
    }
}
const mapStateToProps = (state) => {
	return { 
		orderDetail: state.orderDetail,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchOrderDetail: (orderId, token) => {
            let body = {Key : orderId, token: token};
			dispatch(actFetchOrderDetailRequest(body));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);