import { useState } from 'react';

export default function useToken() {
	const getToken = () => {
		const tokenString = sessionStorage.getItem('token-vendors');
		const userToken = JSON.parse(tokenString);
		return userToken?.token
	};

	const [token, setToken] = useState(getToken());

	const saveToken = userToken => {
		sessionStorage.setItem('token-vendors', JSON.stringify(userToken));
		setToken(userToken.token);
	};

	return {
		setToken: saveToken,
		token,
	}
}