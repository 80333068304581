var initialState = null; 

const productPrice = (state = initialState, action) => {
    switch (action.type) { 
        case "FETCH_PRODUCTPRICE":
            return action.productPrice;   
        default: return state;
    }  
};  

export default productPrice;