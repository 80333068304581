import React from 'react';

function ProductTable({orderDetail}) {
    return (
        <div className="table"> 
            <table> 
                <thead> 
                    <tr> 
                        <th>#</th>
                        <th>Sản phẩm</th>
                        <th>Số lượng</th>
                        <th>Giá</th>
                        <th>Tổng</th>
                    </tr>
                </thead>
                <tbody> 
                    <tr> 
                        <td className="id">01</td>
                        <td>{orderDetail.ProductName}/{orderDetail.ProductModelName}/{orderDetail.ProductColorName}</td>
                        <td>{orderDetail.Quantity}</td>
                        <td>{orderDetail.PriceUnit}</td>
                        <td>{orderDetail.TotalAmount}</td> 
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ProductTable;