import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actFetchBankPromotionDetailRequest } from '../../../actions/index';


import './styles.sass';

class LoanOfferBankDetailComponent extends Component {
	componentDidMount() {
		const bankId = this.props.bankId;
		this.props.fetchBankPromotionDetail(bankId);
    }
	render() {
		const {bankDetail} = this.props;
		return (
			<div className="bank-detail-wrap">
				<div className="bank-info">
					<div className="image">
						<img src={bankDetail.BankLogo} alt={bankDetail.BankName} />
					</div>
					<div className="caption">
						<h1 className="title fz-20 fw-700 text-main">
							{bankDetail.BankName}
						</h1>
						<div className="desc fz-16 text-black">
							<p>Website:  
								<a href={bankDetail.BankWebsite}>
									{bankDetail.BankWebsite}
								</a> 
							</p>
							<p>
								Tổng đài: 
								<a href={`tel: ${bankDetail.BankHotLine}`}>
									<strong>
										{bankDetail.BankHotLine}
									</strong>
								</a>
							</p>
							<p>
								Facebook: 
								<a href="{bankDetail.BankSocial}">
								{bankDetail.BankSocial}
								</a>
							</p>
						</div>
					</div>
				</div>
				<div className="full-content" dangerouslySetInnerHTML={{ __html: bankDetail.FullContent }}>
					
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
return {
	bankDetail: state.promotionDetail
}
}

const mapDispatchToProps = (dispatch, props) => {
return {
	fetchBankPromotionDetail: (bankId) => { 
		dispatch(actFetchBankPromotionDetailRequest(bankId));
	}
}
} 

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfferBankDetailComponent);