var initialState = {};

const orderDetail = (state = initialState, action) => {
    switch (action.type) { 
        case "FETCH_ORDER_DETAIL": 
            return action.orderDetail;  
        default: return state;
    }  
}; 
  
export default orderDetail;