import React, { Component } from 'react';
import Step1Component from "../../components/step/Step1";

class Step1Pages extends Component {
	render() {
		return (
			<section className="step-section section bg-login bdbt-gray">
				<div className="container">
					<Step1Component />
				</div>
			</section>
		);
	}
}

export default Step1Pages;
