import React, { Component } from 'react';
import './styles.sass';

class Car extends Component {
    render() {
        var { orderDetail } = this.props;
        let imageUrl = '';
        if (orderDetail.ProductImages != null) {
            imageUrl = orderDetail.ProductImages[0];
            if (orderDetail.ProductImages.length > 1) imageUrl = orderDetail.ProductImages[1];
        }
        return (
            <>
                <div className="image text-center">
                    <img src={imageUrl} alt={orderDetail.ProductName} />
                    <h1 className="car-name fz-24 text-up fw-400 text-center">{orderDetail.ProductName}</h1>
                </div>
                <ul className="car-info type-none text-center flex">
                    <li>
                        <p className="text-main">
                            <strong>Màu sắc</strong>
                        </p>
                        <p>
                            <label
                                dangerouslySetInnerHTML={{
                                    __html: (orderDetail.ProductColorCode = ''
                                        ? orderDetail.ProductColorImageUrl
                                        : `<div style="background-color: ${orderDetail.ProductColorCode}"></div>`),
                                }}
                            ></label>
                            <span>{orderDetail.ProductColorName}</span>
                        </p>
                    </li>
                    <li>
                        <p className="text-main">
                            <strong>Dòng xe</strong>
                        </p>
                        <p>{orderDetail.ProductName}</p>
                    </li>
                    <li>
                        <p className="text-main">
                            <strong>Phiên bản</strong>
                        </p>
                        <p>{orderDetail.ProductModelName}</p>
                    </li>
                </ul>
                <div className="price-wrap">
                    <div className="price-item flex between">
                        <div className="left">
                            <p title="Giá khuyến nghị mang tính chất tham khảo. Vui lòng liên hệ Đại lý uỷ quyền để biết thông tin cụ thể về giá, chương trình khuyến mại và các thông tin khác">
                                Giá khuyến nghị <span>*</span>
                            </p>
                        </div>
                        <div className="right">
                            <p>
                                <span>{orderDetail.TotalOldAmount}</span>
                            </p>
                        </div>
                    </div>
                    <div className="price-item flex between">
                        <div className="left">
                            <p title="Vui lòng liên hệ Đại lý ủy quyền để biết thêm về chương trình ưu đâĩ">
                                Ưu đãi <span>*</span>
                            </p>
                        </div>
                        <div className="right">
                            <p className="text-main fw-700">
                                <span>{orderDetail.DiscountAmountShow}</span>
                            </p>
                            <div className="note">
                                <div dangerouslySetInnerHTML={{ __html: orderDetail.DiscountGift }} />
                            </div>
                        </div>
                    </div>
                    <div className="price-item flex between">
                        <div className="left">
                            <p>Số tiền cọc</p>
                        </div>
                        <div className="right">
                            <p className="text-main fw-700">
                                <span>{orderDetail.TotalAmount}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="price-wrap">
                    <div className="price-item flex between">
                        <div className="left">
                            <p title="Giá chưa bao gồm các ưu đãi, vui lòng liên hệ với Đại lý Ủy quyền để biết thêm chi tiết">
                                Giá trị đơn hàng <span>*</span>
                            </p>
                        </div>
                        <div className="right">
                            <p className="text-main fw-700 total fz-18">
                                <span>{orderDetail.TotalOldAmount}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="button">
                    <button className="btn btn-blue" type="submit">
                        Đặt hàng
                    </button>
                </div>
            </>
        );
    }
}

export default Car;
