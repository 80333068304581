import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export default function GetVendors(endpoint, method = 'GET', body) {
	let tokenString = sessionStorage.getItem('token');
	let userToken = JSON.parse(tokenString);
	if (userToken === null) {
		tokenString = sessionStorage.getItem('token-vendors');
		userToken = JSON.parse(tokenString);
	}
	return axios({
		method,
		url: `${API_URL}/${endpoint}`,
		headers: {
			'Content-Type': 'application/json',
			'Token': userToken?.token
		},
		data: body
	}).catch(err => {
		console.log(err);
	});
}

