import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchLoanContractListRequest } from '../../../actions/index';

import LoanListPopup from '../../popup/ContentPopup/LoanList';

class LoanListComponent extends Component {
    componentDidMount() {
        this.props.fetchLoanContracts();
    }
    render() {
        var { contracts } = this.props;
        var items = [];
        let i = 0;
        contracts.forEach((element) => {
            i++;
            let item = element;
            item.number = i;
            items.push(item);
        });
        return (
            <div className="box-admin box-loan box-load-list">
                <h1 className="heading-title fz-24 text-white fw-700">Danh sách thẩm định vay</h1>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Số đề nghị</th>
                                <th>Đăng ký vay (Ngân hàng/ Ngày)</th>
                                <th>Ngày, giờ</th>
                                <th>Tình trạng hồ sơ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((loanItem) => (
                                <tr key={loanItem.number}>
                                    <td className="id">
                                        <strong>
                                            {loanItem.number < 10 ? '0' : ''}
                                            {loanItem.number}
                                        </strong>
                                    </td>
                                    <td>
                                        <LoanListPopup item={loanItem} />
                                    </td>
                                    <td>VP Bank</td>
                                    <td>{loanItem.CreateOn}</td>
                                    <td className={loanItem.Status ? 'Approved' : ''}>{loanItem.Status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contracts: state.contracts,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchLoanContracts: () => {
            dispatch(actFetchLoanContractListRequest());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanListComponent);
