import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchStoresRequest, actFetchOrderListRequest } from '../../../../actions/index';

import InputField from '../../../form-control/InputField';
import Select from '../../../form-control/SelectField';
import TableOrder from '../TableOrder';

class OrderListSearch extends Component {
    constructor(props) {
        super(props);
        var today = new Date(),
            date =
                today.getFullYear() +
                '-' +
                (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
                '-' +
                (today.getDate() < 10 ? '0' + today.getDate() : today.getDate());
        this.state = {
            storeId: '',
            status: -1,
            startDate: Date.now(),
            endDate: Date.now(),
            keyword: '',
            pageIndex: 0,
            pageSize: 20,
            date: date,
        };
    }

    componentDidMount() {
        this.props.fetchStores('');
        setTimeout(
            function () {
                var { storeId, status, startDate, endDate, keyword, pageIndex, pageSize } = this.props;
                var json = {
                    storeId,
                    status,
                    startDate,
                    endDate,
                    keyword,
                    pageIndex,
                    pageSize,
                };
                this.props.fetchOrders(json);
            }.bind(this),
            1000
        );
    }

    handleStoreChange = (event) => {
        this.setState({ storeId: event.target.value });
    };
    handleStatusChange = (event) => {
        this.setState({ status: event.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        var storeId = this.state.storeId;
        var status = this.state.status;
        var startDate = null;
        var endDate = null;
        var keyword = this.state.keyword;
        var pageIndex = this.state.pageIndex;
        var pageSize = this.state.pageSize;

        var json = {
            storeId,
            status,
            startDate,
            endDate,
            keyword,
            pageIndex,
            pageSize,
        };
        this.props.fetchOrders(json);
    };

    render() {
        var { form, stores, orders } = this.props;
        var storeList = [];
        stores.forEach((element) => {
            storeList.push({
                id: element.id,
                label: element.name,
                value: element.id,
            });
        });

        var orderTableList = [];
        let i = 0;
        orders.forEach((element) => {
            i++;
            orderTableList.push({
                id: element.Id,
                number: i,
                title: element.ProductName,
                verison: element.ProductModelName,
                color: element.ProductColorName,
                code: element.Code,
                user: element.FullName,
                total: element.TotalAmount,
                store: element.StoreName,
                paymentMethods: element.PaymentMethodName,
                ProcessStatusId: element.ProcessStatusId,
                url: '/admin-order-detail?id=' + element.Id + '&token=' + element.Token,
            });
        });
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <div className="box-admin">
                        <h1 className="heading-title fz-24 text-white fw-700">Danh sách đơn hàng </h1>
                        <div className="wrap-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="Store"
                                            label="Showroom/ Đại lý"
                                            required
                                            options={storeList}
                                            handleChange={this.handleStoreChange}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="Status"
                                            label="Trạng thái đơn hàng"
                                            required
                                            options={[
                                                {
                                                    id: 1,
                                                    value: '-1',
                                                    label: 'Tất cả',
                                                },
                                                {
                                                    id: 2,
                                                    value: '10',
                                                    label: 'Đang xử lý',
                                                },
                                                {
                                                    id: 3,
                                                    value: '30',
                                                    label: 'Hoàn tất',
                                                },
                                                {
                                                    id: 4,
                                                    value: '40',
                                                    label: 'Hủy',
                                                },
                                            ]}
                                            handleChange={this.handleStatusChange}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="StartDate"
                                            type="date"
                                            label="Thời gian đặt hàng"
                                            form={form}
                                            onChange={(e) => this.setState({ startDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="EndDate"
                                            type="date"
                                            label="Đến ngày"
                                            defaultValue={this.state.date}
                                            form={form}
                                            onChange={(e) => this.setState({ endDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <InputField
                                            name="Keywork"
                                            type="text"
                                            label="Tìm kiếm"
                                            defaultValue=""
                                            placeholder="Tên khách hàng, mã đơn hàng, số điện thoại"
                                            onChange={(e) => this.setState({ keyword: e.target.value })}
                                            form={form}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group frm-btnwrap">
                                        <div className="frm-btn">
                                            <input
                                                className="btn btn-default frm-btn-submit"
                                                type="submit"
                                                value="Tìm kiếm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TableOrder orderTableList={orderTableList} />
                </form>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        stores: state.stores,
        orders: state.orders,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchStores: (province) => {
            dispatch(actFetchStoresRequest(province));
        },
        fetchOrders: (body) => {
            dispatch(actFetchOrderListRequest(body));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderListSearch);
