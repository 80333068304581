import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { actFetchCategoriesRequest } from '../../actions/index';
// import css
import '../../components/loan-offer/styles.sass';

LoanOfferInterestRatePage.prototype = {
	zoneId: PropTypes.number,
	cateId: PropTypes.string,
	zoneUrl: PropTypes.string,
};

function LoanOfferInterestRatePage(props) {
	React.useEffect(() => {
		props.fetchCategories();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const cateId = props.cateId;

	const { categories } = props;

	const category = categories.find((category) => {
		return category.id === cateId
	})
	
	return (
		<section className="loanoffer-section section setBackground no-pt">
			<div className="interest-rate-banner img-scale">
				<img src={category?.secondImage} alt={category?.name} />
			</div>
			<div className="container">
				<div className="step-by-step interest-rate-step"> 
					<ul className="type-none flex text-center">
						<li className="active">
							<span>Chi tiết ưu đãi</span>
						</li>
						<li className="">
							<NavLink className="flex align-center" to="/customer/tham-dinh-vay-nhanh">
								<span>Đăng ký ngay</span>
							</NavLink>
						</li>
					</ul>
				</div>
				<div className="interest-rate-content" dangerouslySetInnerHTML={{ __html: category?.bottomDescription }}>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => {
	return {
		categories: state.categories
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchCategories: () => {
			dispatch(actFetchCategoriesRequest('LoanOffer-Catalog', '', true));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfferInterestRatePage);
