import React from 'react';
import useToken from '../../actions/useToken-vendors';

import Login from '../../components/banker/Login';
import BankerNavLink from '../../components/banker/Menu';
import BankerLoanListComponent from '../../components/banker/LoanList';

function BankerPages() {
    const { token, setToken } = useToken();
    if (!token) {
        return <Login setToken={setToken} />;
    }
    return (
        <section className="admin-section customer-section section bg-login bdbt-gray">
            <div className="container">
                <div className="row row-space">
                    <div className="col-lg-4 col-xl-3">
                        <BankerNavLink />
                    </div>
                    <div className="col-lg-8 col-xl-9">
                        <BankerLoanListComponent token={token} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BankerPages;
