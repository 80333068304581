import React, { Component } from 'react';
import { connect } from 'react-redux';
import HtmlContent from '../../components/html-content';

// import image
import bgSection from '../../assets/images/bg-loan-offer.jpg';
// import ImgLoanOffer4 from '../../assets/images/loanoffer-4.jpg';

// import component
import LoanOfferZoneItemComponent from '../../components/loan-offer/loan-offer-item';

// import css
import '../../components/loan-offer/styles.sass';
import { actFetchCategoriesRequest } from '../../actions/index';
import RequestCounsellingFormComponent from '../../components/popup/FormPopup/RequestCounselling';

class LoanOfferPage extends Component {
	// constructor(props) {
	// 	super(props);
	// }
	componentDidMount = () => {
		this.props.fetchCategories();
	}
	render() {
		var { categories } = this.props;
		categories.forEach((element) => {
			element.url = '/loan-offer/' + element.url;
		});
		return (
			<section className="loanoffer-section section setBackground">
				<div className="background-section">
					<div className="simpleParallax simple-parallax-initialized" style={{ overflow: 'hidden' }}>
						<img className="parallax-0" src={bgSection} alt="background" />
					</div>
				</div>
				<div className="container">
					<div className="zone-top">
						<div className="row no-gutters center">
							{categories.map((loanOffer, index) => (
								<div
									className="col-md-4 col-xl-4"
									key={loanOffer.id}
								>
									<LoanOfferZoneItemComponent loanOfferItem={loanOffer} loanNotLink={index} />
								</div>
							))}
						</div>
					</div>
					<div className="zone-bot">
						<div className="loan-offer-html">
							<div className="row no-gutters">
								{/* <div className="col-md-4">
									<div className="image img-scale">
										<img src={ImgLoanOffer4} alt="loan-offer-4" />
									</div>
								</div> */}
								<div className="col-md-12">
									<div className="caption fz-16 fw-400 test-dark" id="content-loan-offer">
										{/* <nav>
											<ul>
												<li>
													<a href="#loan-offer-html-1">
														Lợi ích
													</a>
												</li>
												<li>
													<a href="#loan-offer-html-2">
														Các ngân hàng đối tác
													</a>
												</li>
												<li>
													<a href="#loan-offer-html-3">
														Điều kiện vay mua xe
													</a>
												</li>
												<li>
													<a href="#loan-offer-html-4">
														Hồ sơ cần cung cấp
													</a>
												</li>
												<li>
													<a href="#loan-offer-html-5">
														Quy trình chung
													</a>
												</li>
												<li>
													<a href="#loan-offer-html-6">
														Hỏi đáp và hướng dẫn
													</a>
												</li>
											</ul>
										</nav> */}
										<HtmlContent system="LoanOfferDescription" />
										<RequestCounsellingFormComponent buttonText={'Tư vấn ngay'} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		categories: state.categories
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchCategories: () => {
			dispatch(actFetchCategoriesRequest('LoanOffer-Catalog', '', true));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfferPage);
