import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Get from '../../../../utils/APIsHelper';
import '../../Step3-LoanProductInfo/styles.sass';

// form
import InputField from '../../../form-control/InputField';
import Select from '../../../form-control/SelectField';

// import WorkIncomePopup from '../../../popup/ContentPopup/WorkIncome';

//eslint-disable-next-line
String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};
var fulltimePositions = [
    {
        id: 'POSH4',
        value: 'POSH4',
        label: 'Quản lý cấp cao',
    },
    {
        id: 'POSH3',
        value: 'POSH3',
        label: 'Quản lý cấp trung',
    },
    {
        id: 'POSH29',
        value: 'POSH29',
        label: 'Nhân viên / Chuyên viên',
    },
    {
        id: 'POSH14',
        value: 'POSH14',
        label: 'Công nhân',
    },
    {
        id: 'POSH33',
        value: 'POSH33',
        label: 'Bảo vệ',
    },
    {
        id: 'POSH37',
        value: 'POSH37',
        label: 'Khác',
    },
];

var partTimePositions = [
    {
        id: 'POSH1',
        value: 'POSH1',
        label: 'Chuyên gia',
    },
    {
        id: 'POSH28',
        value: 'POSH28',
        label: 'Nhân viên kinh doan (xe ô tô, bảo hiểm, bất động sản, chứng khoán)',
    },
    {
        id: 'POSH21',
        value: 'POSH21',
        label: 'Tài xế taxi',
    },
    {
        id: 'POSH37',
        value: 'POSH37',
        label: 'Khác',
    },
];

var noJobPositions = [
    {
        id: 'POSH36',
        value: 'POSH36',
        label: 'Đầu tư',
    },
    {
        id: 'POSH12',
        value: 'POSH12',
        label: 'Thực tập / sinh viên',
    },
    {
        id: 'POSH42',
        value: 'POSH42',
        label: 'Nội trợ',
    },
    {
        id: 'POSH9',
        value: 'POSH9',
        label: 'Nghỉ hưu',
    },
    {
        id: 'POSH10',
        value: 'POSH10',
        label: 'Thất nghiệp',
    },
];

function FormStep3FormWorkIncome(props) {
    const { form, employmentStatus, occupationCode } = props;
    const [showOrganizationName, setShowOrganizationName] = useState(true);
    const [showBusiness, setShowBusiness] = useState(false);
    const [showWorkingTime, setShowWorkingTime] = useState(true);
    const [showOtherIncome, setShowOtherIncome] = useState(false);
    const [jobPositions, setJobPositions] = useState(fulltimePositions);

    const history = useHistory();
    let save = (e) => {
        e.preventDefault();
        let contract = JSON.parse(localStorage.getItem('LoanContract'));
        const employmentType = e.target.EmploymentType.value;
        const organizationName = e.target.OrganizationName.value;
        const taxCode = e.target.TaxCode.value;
        const business = e.target.Business.value;
        const businessOther = e.target.BusinessOther.value;
        const otherJob = e.target.OtherJob.value;
        const otherIncome = e.target.OtherIncome.value;
        const workingTime = e.target.WorkingTime.value;
        const jobPosition = e.target.JobPosition.value;
        const typeIncome = e.target.TypeIncome.value;
        const monthlyIncome = parseFloat(e.target.MonthlyIncome.value.replaceAll('.', ''));
        if (monthlyIncome < 4500000) {
            alert('Thu nhập tối thiểu là 4.500.000 VNĐ');
            return;
        }
        if (monthlyIncome > 99999999999999999) {
            alert('Thu nhập tối đa là 99.999.999.999.999.999 VNĐ');
            return;
        }
        contract.employmentType = employmentType;
        contract.companyName = organizationName;
        contract.taxCode = taxCode;
        contract.business = business;
        contract.businessOther = businessOther;
        contract.otherJob = otherJob;
        contract.otherIncome = otherIncome;
        contract.workingTime = workingTime;
        contract.position = jobPosition;
        contract.typeIncome = typeIncome;
        contract.incomeOtherAmount = monthlyIncome;

        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        contract.orderId = orderId;
        contract.token = orderToken;
        Get('api/order/SaveLoan', 'post', contract).then((res) => {
            history.push('step-3');
        });
    };
    let changeEmploymentStatus = (e) => {
        let val = e.target.value;
        setShowOrganizationName(val === 'FT');
        setShowBusiness(val === 'BO');
        setShowWorkingTime(val !== 'UE');
        if (val === 'FT') setJobPositions(fulltimePositions);
        else if (val === 'PT') setJobPositions(partTimePositions);
        else if (val === 'UE') setJobPositions(noJobPositions);
        else setJobPositions([]);
    };
    let changeTypeIncome = (e) => {
        let employmentType = document.getElementById('EmploymentType').value;
        let val = e.target.value;
        setShowOtherIncome(val === 'INC15' && employmentType === 'UE');
    };
    return (
        <>
            <form method="post" onSubmit={save}>
                <div className="wrap-form box-loan-info box-work-income">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="EmploymentType"
                                    label="Hình thức công việc"
                                    options={employmentStatus}
                                    form={form}
                                    handleChange={changeEmploymentStatus}
                                />
                            </div>
                        </div>
                        <div className={showOrganizationName ? 'col-md-6 ' : 'hidden'}>
                            <div className="form-group">
                                <InputField
                                    name="OrganizationName"
                                    type="text"
                                    label="Nhập tên cơ quan"
                                    defaultValue=""
                                    form={form}
                                    // onChange={e => this.setState({ startDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className={showBusiness ? 'col-md-6 ' : 'hidden'}>
                            <div className="form-group">
                                <Select
                                    name="Business"
                                    label="Ngành nghề kinh doanh"
                                    options={occupationCode}
                                    form={form}
                                    // handleChange={onChangeModelType}
                                />
                            </div>
                        </div>
                        <div className={showWorkingTime ? 'col-md-6 ' : 'hidden'}>
                            <div className="form-group">
                                <Select
                                    name="WorkingTime"
                                    label="Thời gian làm việc"
                                    options={[
                                        {
                                            id: 1,
                                            value: 'LTEYEAR',
                                            label: '< 1 Năm',
                                        },
                                        {
                                            id: 2,
                                            value: 'MIDDLE13',
                                            label: '1- 3 Năm',
                                        },
                                        {
                                            id: 3,
                                            value: 'GTEHIRTYEAR',
                                            label: '> 3 Năm',
                                        },
                                    ]}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className={showBusiness ? 'col-md-6 ' : 'hidden'}>
                            <div className="form-group">
                                <Select
                                    name="TaxCode"
                                    label="Tôi có giấy phép kinh doanh đứng tên tôi?"
                                    options={[
                                        {
                                            id: 1,
                                            value: 'true',
                                            label: 'Có',
                                        },
                                        {
                                            id: 2,
                                            value: 'false',
                                            label: 'Không',
                                        },
                                    ]}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="BusinessOther"
                                    label="Ngành nghề kinh doanh "
                                    options={occupationCode}
                                    form={form}
                                    // handleChange={onChangeModelType}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="OtherJob"
                                    type="text"
                                    label="Vị trí công việc "
                                    defaultValue=""
                                    form={form}
                                    // onChange={e => this.setState({ startDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className={!showWorkingTime ? 'col-md-6 ' : 'hidden'}>
                            <div className="form-group">
                                <Select
                                    name="TypeIncome"
                                    label="Loại thu nhập"
                                    options={[
                                        {
                                            id: 1,
                                            value: 'INC6',
                                            label: 'Nguồn thu từ cho thuê/cổ tức/các hình thức nhận lãi',
                                        },
                                        {
                                            id: 2,
                                            value: '2INC8',
                                            label: 'Nguồn thu từ hưu trí/trợ cấp chính phủ',
                                        },
                                        {
                                            id: 3,
                                            value: 'INC10',
                                            label: 'Trợ cấp từ gia đình',
                                        },
                                        {
                                            id: 4,
                                            value: 'INC15',
                                            label: 'Nguồn thu khác',
                                        },
                                    ]}
                                    form={form}
                                    handleChange={changeTypeIncome}
                                />
                            </div>
                        </div>
                        <div className={showOtherIncome ? 'col-md-6 ' : 'hidden'}>
                            <div className="form-group">
                                <InputField
                                    name="OtherIncome"
                                    type="text"
                                    label="Nguồn thu nhập khác"
                                    defaultValue=""
                                    form={form}
                                    // onChange={e => this.setState({ startDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className={jobPositions.length > 0 ? 'col-md-6 ' : 'hidden'}>
                            <div className="form-group">
                                <Select name="JobPosition" label="Chức vụ" options={jobPositions} form={form} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="MonthlyIncome"
                                    type="text"
                                    label="Thu nhập hàng tháng (VNĐ)"
                                    defaultValue="50000000"
                                    form={form}
                                    isCurrency
                                    // onChange={e => this.setState({ endDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-checkbox">
                                <input type="checkbox" id="accept" name="AcceptPolicy" />
                                <label htmlFor="accept">Tôi cam kết các thông tin trên là đúng sự thật</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group frm-btnwrap">
                                <div className="button">
                                    <Link
                                        className="flex align-center btn btn-back btn-67 text-white"
                                        to="/step-3-thong-tin-ca-nhan"
                                    >
                                        Quay lại
                                    </Link>
                                </div>
                                <div className="frm-btn">
                                    {/* <div className="button btn-preview">
										<WorkIncomePopup></WorkIncomePopup>
									</div> */}
                                    <input
                                        className="btn btn-default frm-btn-submit"
                                        type="submit"
                                        value="Gửi đề nghị"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default FormStep3FormWorkIncome;
