import React from 'react';
import PropTypes from 'prop-types';

import './styles.sass';
import Form from '../Form';

ContactForm.propTypes = {
    contactFormInfo: PropTypes.object.isRequired,
};

function ContactForm() {
    return (
        <Form />
    );
} 

export default ContactForm;