import React from 'react';

import './styles.sass';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function handleClick(event) {
  event.preventDefault();
}

function CollapsedBreadcrumbs() {
    return (
        <Breadcrumbs maxItems={10} aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={handleClick}>
                Trang chủ
            </Link>
            <Link color="inherit" href="#" onClick={handleClick}>
                Danh mục
            </Link>
            <Typography color="textPrimary">Faqs</Typography>
        </Breadcrumbs>
    );
}

export default CollapsedBreadcrumbs;