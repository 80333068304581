import React from 'react';
import HomePage from './pages/Home';

import Step1Page from './pages/Step1';
import Step2Page from './pages/Step2';
import Step3Page from './pages/Step3';
import Step3LoanProductInfoPages from './pages/Step3-LoanProductInfo';
import Step3PersonalInfoPages from './pages/Step3-PersonalInfo';
import Step3WorkIncomePages from './pages/Step3-WorkIncome';

import FaqsPage from './pages/Faqs';
import ContactPage from './pages/Contact';
import DealersPage from './pages/Dealers';
import LoanOfferPage from './pages/LoanOffer';
import LoanOfferInterestRatePage from './pages/LoanOfferInterestRate';
import NormalLoanPage from './pages/NormalLoan';
import NormalLoanDetailPage from './pages/NormalLoanDetail';

import CustomerPage from './pages/Customer/index';
// import CustomerPointHistoryPage from './pages/Customer/PointHistory';
import CustomerPurchaseHistoryPage from './pages/Customer/PurchaseHistory';
import CustomerPurchaseHistoryDetailPage from './pages/Customer/PurchaseHistoryDetail';
import CustomerLoanPage from './pages/Customer/Loan';
import CustomerLoanListPage from './pages/Customer/LoanList';
import CustomerLoanRegistrationListPage from './pages/Customer/LoanRegistrationList';

import AdminPage from './pages/Admin/index';
import AdminOrderDetailPage from './pages/Admin/OrderDetail/index';
import AdminRequestCounsellingPage from './pages/Admin/RequestCounselling/index';
import AdminChangePasswordPage from './pages/Admin/ChangePassword/index';
import AdminLoanListPage from './pages/Admin/LoanList';
import AdminLoanRegistrationListPage from './pages/Admin/LoanRegistrationList';

import BankerPages from './pages/Banker';
import BankerLoanRegistrationListPage from './pages/Banker/LoanRegistrationList';

import NewsDetailPage from './pages/News-Detail';
import RecoverPasswordPage from './pages/RecoverPassword';
import Error from './pages/Error';

const routes = [
	{
		path: '/',
		exact: true,
		main: () => <HomePage />
	},
	{
		path: '/step-1',
		exact: true,
		main: () => <Step1Page />
	},
	{
		path: '/step-2',
		exact: true,
		main: () => <Step2Page />
	},
	{
		path: '/step-3',
		exact: true,
		main: () => <Step3Page />
	},
	{
		path: '/step-3-thong-tin-vay',
		exact: true,
		main: () => <Step3LoanProductInfoPages />
	},
	{
		path: '/step-3-thong-tin-ca-nhan',
		exact: true,
		main: () => <Step3PersonalInfoPages />
	},
	{
		path: '/step-3-cong-viec-thu-nhap',
		exact: true,
		main: () => <Step3WorkIncomePages />
	},
	{
		path: '/loan-offer',
		exact: true,
		main: () => <LoanOfferPage />
	},
	{
		path: '/loan-offer/chuong-trinh-uu-dai-thong-thuong',
		exact: true,
		main: () => <NormalLoanPage zoneId="1" cateId="62fef3187b36d8c3c5a4637f" zoneUrl='/loan-offer/chuong-trinh-uu-dai-thong-thuong' />
	},
	{
		path: '/loan-offer/chuong-trinh-uu-dai-thong-thuong/:bankId',
		exact: true,
		main: () => <NormalLoanDetailPage zoneId="1" cateId="62fef3187b36d8c3c5a4637f" />
	},
	{
		path: '/loan-offer/chuong-trinh-ho-tro-lai-suat',
		exact: true,
		main: () => <NormalLoanPage zoneId="2" cateId="6209fb24277d6b3d83335531" zoneUrl='/loan-offer/chuong-trinh-ho-tro-lai-suat' />
	},
	{
		path: '/loan-offer/chuong-trinh-ho-tro-lai-suat/:bankId',
		exact: true,
		main: () => <NormalLoanDetailPage zoneId="2" cateId="6209fb24277d6b3d83335531" />
	},
	{
		path: '/loan-offer/uu-dai-dac-biet',
		exact: true,
		main: () => <NormalLoanPage zoneId="3" cateId="6209fb30277d6b3d8333556a" zoneUrl='/loan-offer/uu-dai-dac-biet' />
	},
	{
		path: '/loan-offer/uu-dai-dac-biet/:bankId',
		exact: true,
		main: () => <NormalLoanDetailPage zoneId="3" cateId="6209fb30277d6b3d8333556a" />
	},
	{
		path: '/loan-offer/chuong-trinh-uu-dai-lai-suat',
		exact: true,
		main: () => <LoanOfferInterestRatePage zoneId="4" cateId="63a517206f7d4e1a6286e3f2" zoneUrl='/loan-offer/chuong-trinh-uu-dai-lai-suat' />
	},
	{
		path: '/contact',
		exact: true,
		main: () => <ContactPage />
	},
	{
		path: '/dealers',
		exact: true,
		main: () => <DealersPage />
	},
	{
		path: '/faqs',
		exact: true,
		main: () => <FaqsPage cateId='6132ece0d174eb0f71259d40' />
	},
	{
		path: '/quy-che-hoat-dong',
		exact: true,
		main: () => <NewsDetailPage cateId='6132ec74d174eb0f712597e4' />
	},
	{
		path: '/dieu-khoan-va-dieu-kien',
		exact: true,
		main: () => <NewsDetailPage cateId='6132ecb0d174eb0f71259a27' />
	},
	{
		path: '/mien-tru-trach-nhiem',
		exact: true,
		main: () => <NewsDetailPage cateId='6132ecb9d174eb0f71259aa6' />
	},
	{
		path: '/chinh-sach-bao-mat-thanh-toan',
		exact: true,
		main: () => <NewsDetailPage cateId='6132ecc2d174eb0f71259b24' />
	},
	{
		path: '/chinh-sach-doi-tra',
		exact: true,
		main: () => <NewsDetailPage cateId='611c7cc973044590227eebbc' />
	},
	{
		path: '/chinh-sach-van-chuyen',
		exact: true,
		main: () => <NewsDetailPage cateId='6132ecd1d174eb0f71259c2a' />
	},
	{
		path: '/dieu-khoan-dat-coc',
		exact: true,
		main: () => <NewsDetailPage cateId='611c7cc973044590227eebbc' />
	},
	{
		path: '/customer/danh-sach-don-hang',
		exact: true,
		main: () => <CustomerPage />
	},
	{
		path: '/customer/tham-dinh-vay-nhanh',
		exact: true,
		main: () => <CustomerLoanPage />
	},
	{
		path: '/customer/danh-sach-tham-dinh-vay',
		exact: true,
		main: () => <CustomerLoanListPage />
	},
	{
		path: '/customer/danh-sach-dang-ky-vay',
		exact: true,
		main: () => <CustomerLoanRegistrationListPage />
	},
	{
		path: '/banker/danh-sach-tham-dinh-vay-nhanh',
		exact: true,
		main: () => <BankerPages />
	},
	{
		path: '/banker/danh-sach-dang-ky-vay',
		exact: true,
		main: () => <BankerLoanRegistrationListPage />
	},
	{
		path: '/purchase-history',
		exact: true,
		main: () => <CustomerPurchaseHistoryPage />
	},
	{
		path: '/purchase-history-detail',
		exact: true,
		main: () => <CustomerPurchaseHistoryDetailPage />
	},
	{
		path: '/recover-password',
		exact: true,
		main: () => <RecoverPasswordPage />
	},
	{
		path: '/admin/danh-sach-don-hang',
		exact: true,
		main: () => <AdminPage />
	},
	{
		path: '/admin-order-detail',
		exact: true,
		main: () => <AdminOrderDetailPage />
	},
	{
		path: '/admin/yeu-cau-tu-van',
		exact: true,
		main: () => <AdminRequestCounsellingPage />
	},
	{
		path: '/admin/danh-sach-tham-dinh-vay-nhanh',
		exact: true,
		main: () => <AdminLoanListPage />
	},
	{
		path: '/admin/danh-sach-dang-ky-vay',
		exact: true,
		main: () => <AdminLoanRegistrationListPage />
	},
	{
		path: '/admin-change-password',
		exact: true,
		main: () => <AdminChangePasswordPage />
	},
	{
		path: '/error',
		exact: true,
		main: () => <Error />
	},
	{
		path: '',
		exact: true,
		main: () => <Error />
	}
];

export default routes; 