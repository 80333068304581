import React from 'react';
import '../styles.sass';
import RequestCounsellingList from './Form';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

function OrderListComponent() {
    // form
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    return <RequestCounsellingList form={form} />;
}

export default OrderListComponent;
