import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchProvincesRequest } from '../../../actions/index';
import Select from "../../form-control/SelectField";


class SelectProvince extends Component {
    
    componentDidMount() {
        this.props.fetchProvinces();
    }

    render() { 
        var { provinces, form, handleChange  } = this.props; 
        var options = [];  
        options.push({
            id : '',
            value : '',
            label : 'Chọn tỉnh thành' 
        });
        provinces.forEach(element => {
            options.push({
                id : element.id,
                value : element.id,
                label : element.name
            });
        });
        return (
            <Select 
                name="Province" 
                label="Tỉnh/ Thành phố"  
                required
                options={options} 
                form={form}   
                handleChange={handleChange} 
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        provinces: state.provinces
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchProvinces: () => {
            dispatch(actFetchProvincesRequest());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProvince);