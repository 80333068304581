import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchNewsItemsRequest } from '../../actions/index';

import NavBarLeft from '../navbar/NavBarLeft';
import FaqsList from './FaqsList';


class FaqsComponent extends Component {
    componentDidMount() {
        var {cateId} = this.props;
        this.props.fetchNewItems(cateId);
    } 
    render() {
        var {newsItems} = this.props;
    return (
        <section className="faqs-section section">
            <div className="container">
                <div className="row"> 
                    <div className="col-lg-4 col-xl-3"> 
                        <NavBarLeft  />
                        
                    </div>
                    <div className="col-lg-8 col-xl-9"> 
                        <FaqsList faqsList={newsItems}/>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        newsItems: state.newsItems,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchNewItems: (cateId) => {
            dispatch(actFetchNewsItemsRequest('',cateId));  
        },
    };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(FaqsComponent);