import React from 'react';
import { useHistory } from 'react-router-dom';

// form
import InputField from '../../../form-control/InputField';
import Select from '../../../form-control/SelectField';

//eslint-disable-next-line
String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

function FormStep3LoanProductInfo(props) {
    const { form, order, purposes, promotion } = props;
    const history = useHistory();

    let save = (e) => {
        e.preventDefault();

        const bank = e.target.Bank.value;
        const loanOffer = e.target.LoanOffer.value;
        const maxAmount = parseFloat(order.TotalOldAmount.replaceAll('đ', '').replaceAll('.', ''));
        const loanAmount = parseFloat(e.target.LoanAmount.value.replaceAll('đ', '').replaceAll('.', ''));
        const loanPeriod = e.target.LoanPeriod.value;
        const loanPurpose = e.target.LoanPurpose.value;

        const min = 50000000;
        const max = 2000000000;

        if (loanAmount < min) {
            alert(
                'Số tiền vay tối thiểu là ' +
                    new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(min)
            );
            return;
        }
        if (loanAmount > maxAmount) {
            alert(
                'Số tiền vay tối đa là ' +
                    new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(maxAmount)
            );
            return;
        }
        if (loanAmount > max) {
            alert(
                'Số tiền vay tối đa là ' +
                    new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(max)
            );
            return;
        }

        let contract = {
            bank: bank,
            promotionCode: loanOffer,
            loanAmount: loanAmount,
            loanTerm: loanPeriod,
            loanPurposeDetailCode: loanPurpose,
        };
        localStorage.setItem('LoanContract', JSON.stringify(contract));
        history.push('step-3-thong-tin-ca-nhan');
    };

    return (
        <>
            <form method="post" onSubmit={save}>
                <div className="wrap-form box-loan-info">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="Product"
                                    type="text"
                                    label="Loại xe"
                                    defaultValue={order.ProductName}
                                    value={order.ProductName || ''}
                                    form={form}
                                    disabled
                                    // onChange={e => this.setState({ startDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="ModelType"
                                    type="text"
                                    label="Phiên bản"
                                    defaultValue={order.ProductModelName}
                                    value={order.ProductModelName || ''}
                                    form={form}
                                    disabled
                                    // onChange={e => this.setState({ startDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="ProductColor"
                                    type="text"
                                    label="Màu sắc"
                                    defaultValue={order.ProductColorName}
                                    value={order.ProductColorName || ''}
                                    form={form}
                                    disabled
                                    // onChange={e => this.setState({ startDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="Bank"
                                    label="Ngân hàng"
                                    options={[
                                        {
                                            id: 1,
                                            value: '1',
                                            label: 'VP Bank',
                                        },
                                    ]}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="LoanOffer"
                                    label="Mã ưu đãi vay"
                                    required
                                    options={promotion}
                                    form={form}
                                    // handleChange={onChangeModelType}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="RecommendedPrice"
                                    type="text"
                                    label="Giá khuyến nghị * (VNĐ)  "
                                    defaultValue={order.SubTotalOldAmount}
                                    value={order.SubTotalOldAmount || ''}
                                    form={form}
                                    disabled
                                    // onChange={e => this.setState({ startDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="DepositAmount "
                                    type="text"
                                    label="Số tiền cọc (VNĐ)"
                                    defaultValue={order.TotalAmount}
                                    value={order.TotalAmount || ''}
                                    form={form}
                                    disabled
                                    // onChange={e => this.setState({ endDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="RealPrice"
                                    type="text"
                                    label="Giá thực tế (VNĐ)"
                                    defaultValue={order.TotalOldAmount}
                                    value={order.TotalOldAmount || ''}
                                    form={form}
                                    disabled
                                    // onChange={e => this.setState({ endDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="LoanAmount"
                                    type="text"
                                    label="Số tiền vay (VNĐ)"
                                    // defaultValue={order.LoanAmount}
                                    value={String(order.LoanAmount) || ''}
                                    form={form}
                                    isCurrency
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="LoanPeriod"
                                    label="Thời gian vay (năm)"
                                    options={[
                                        {
                                            id: 1,
                                            value: '48',
                                            label: '4',
                                        },
                                        {
                                            id: 3,
                                            value: '60',
                                            label: '5',
                                        },
                                        {
                                            id: 4,
                                            value: '72',
                                            label: '6',
                                        },
                                        {
                                            id: 5,
                                            value: '84',
                                            label: '7',
                                        },
                                        {
                                            id: 6,
                                            value: '96',
                                            label: '8',
                                        },
                                    ]}
                                    value="96"
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select name="LoanPurpose" label="Mục đích vay" options={purposes} form={form} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group frm-btnwrap">
                                <div className="frm-btn">
                                    <input className="btn btn-default frm-btn-submit" type="submit" value="Tiếp theo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default FormStep3LoanProductInfo;
