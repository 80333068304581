import React from 'react';

import LoanListPopup from '../../popup/ContentPopup/LoanList';

function LoanListComponent(props) {
    const loanList = [
        {
            id: 1,
            title: 'VP-DN-1253',
            bankName: 'VPBank',
            time: '20/12/2021',
            isAccept: true,
        },
        {
            id: 2,
            title: 'VP-DN-4567',
            bankName: 'VPBank',
            time: '20/12/2021',
            isAccept: false,
        },
        {
            id: 3,
            title: 'VP-DN-0256',
            bankName: 'VPBank',
            time: '20/12/2021',
            isAccept: false,
        },
        {
            id: 4,
            title: 'VP-DN-3245',
            bankName: 'VPBank',
            time: '20/12/2021',
            isAccept: false,
        },
    ];
    return (
        <div className="box-admin box-loan box-load-list">
            <h1 className="heading-title fz-24 text-white fw-700">Danh sách thẩm định vay</h1>
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Số đề nghị</th>
                            <th>Đăng ký vay (Ngân hàng/ Ngày)</th>
                            <th>Ngày, giờ</th>
                            <th>Tình trạng hồ sơ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loanList.map((loanItem) => (
                            <tr key={loanItem.id}>
                                <td className="id">
                                    <strong>
                                        {loanItem.id < 10 ? '0' : ''}
                                        {loanItem.id}
                                    </strong>
                                </td>
                                <td>
                                    <LoanListPopup titlePopup={loanItem.title} />
                                </td>
                                <td>{loanItem.bankName}</td>
                                <td>{loanItem.time}</td>
                                <td className={loanItem.isAccept ? 'accept' : ''}>
                                    {loanItem.isAccept ? 'Đồng ý' : 'Từ chối'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default LoanListComponent;
