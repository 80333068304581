import React from 'react';
import PropTypes from 'prop-types';

// import { Link } from 'react-router-dom';

import './styles.sass';

LoanOfferNavComponent.propTypes = {
    navList: PropTypes.array.isRequired,
};

function LoanOfferNavComponent({ navList }) {
    return (
        <div className="step-by-step">
            {/* <ul className="type-none flex text-center">
                {navList.map((navItem) => (
                    <li className={navItem.isActive ? 'active' : ''} key={navItem.zoneId}>
                        <Link to={navItem.zoneUrl} title={navItem.zoneTitle}>
                            <span>{navItem.zoneTitle}</span>
                        </Link>
                    </li>
                ))}
            </ul> */}
        </div>
    );
}

export default LoanOfferNavComponent;
