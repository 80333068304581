import React from 'react';

import Step3Component from '../../components/step/Step3';

function Step3Pages(props) {
    return (
        <section className="step-section section bg-login bdbt-gray">
            <div className="container">
                <Step3Component />
            </div>
        </section>
    );
}

export default Step3Pages;
