import React, { Component } from 'react';
import userInfoVendors from '../../../actions/userInfoVendors';

import NavBanker from './NavBanker';

class BankerNavLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
        };
    }
    async componentDidMount() {
        const result = await userInfoVendors();
        if (result.success) {
            let name = result.data.fullName;
            if (name === '') name = result.data.email;
            this.setState({ fullName: name });
        } else {
            sessionStorage.removeItem('token-vendors');
            window.location.reload();
        }
    }

    render() {
        const navbarList = [
            {
                id: 1,
                title: 'Danh sách Thẩm định vay nhanh',
                zonePath: '/banker',
                url: '/danh-sach-tham-dinh-vay-nhanh',
                isActive: false,
                isTarget: false,
            },
            {
                id: 2,
                title: 'Danh sách Đăng ký vay',
                zonePath: '/banker',
                url: '/danh-sach-dang-ky-vay',
                isActive: false,
                isTarget: false,
            },
        ];
        return <NavBanker navbarList={navbarList} fullName={this.state.fullName} />;
    }
}

export default BankerNavLink;
