import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actFetchOrderDetailRequest } from '../../../actions/index';
import axios from 'axios';

import FormStep3FormWorkIncome from './Form';
  

async function GetOptions(id)
{
    var options = [];  
    const link = process.env.REACT_APP_API_ENDPOINT + '/api/CustomFiled/Get?id=' + id;
    var result = await axios.get(link);
    if (result.data.success) {
        result.data.data.forEach(element => {
            options.push({
                id : element.Id,
                value : element.Value,
                label : element.Name
            });
        });
    } 
    return options;
}


class Step3FormWorkIncome extends Component {
    state = {
        employmentStatus : [],
        occupationCode : [],
        startDateOfWork : [],
        positions : [],
        incomeType : [],
        relationshipCode : []
    }
    async componentDidMount(): Promise<void> {
        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        this.props.fetchOrderDetail(orderId,orderToken);

        const employmentStatus = await GetOptions('61ee19f94d62f211f0ceb78e');
        const occupationCode = await GetOptions('61ee1a504d62f211f0ceb904'); 
        const startDateOfWork = await GetOptions('61ee1adf4d62f211f0cebadf'); 
        const positions = await GetOptions('61ee1ce64d62f211f0cec733'); 
        const incomeType = await GetOptions('61ee1da04d62f211f0ced1db'); 
        const relationshipCode = await GetOptions('61ee16dc4d62f211f0ceaaf5'); 
        // Sleep for 10 seconds
        await new Promise(resolve => { setTimeout(resolve, 500); });
        // This line of code will be executed after 0.5 seconds
        this.setState({employmentStatus, occupationCode, startDateOfWork, positions, incomeType, relationshipCode});
        return Promise.resolve();
    }
    render() {
        const { form , orderDetail} = this.props;
        const { employmentStatus, occupationCode, startDateOfWork, positions, incomeType, relationshipCode} = this.state;
        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        if (orderId === null || orderToken === null)  
            return <Redirect path="/" />;
		return <FormStep3FormWorkIncome   form={form} order={orderDetail}
											orderDetail={orderDetail}
											employmentStatus={employmentStatus}
											occupationCode={occupationCode}
											startDateOfWork={startDateOfWork}
											positions={positions}
											incomeType={incomeType} 
											relationshipCode={relationshipCode}  />
    }
}

const mapStateToProps = (state) => {
    return {
        orderDetail: state.orderDetail,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
		fetchOrderDetail: (orderId, orderToken) => {
            let body = {Key : orderId, Token: orderToken};
			dispatch(actFetchOrderDetailRequest(body));
		},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3FormWorkIncome);
