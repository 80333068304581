import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actFetchStoresRequest, actFetchProductsRequest, actFetchProductPriceRequest } from '../../../../actions/index';

import ProductSlide from '../../ProductSlide';
import FormStep1Left from './FormLeft';
import FormStep1Right from './FormRight';
import InputField from '../../../form-control/InputField';

class Step1Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province: '',
            price: '',
            oldPrice: '',
            discountAmount: '',
            discountGift: '',
            productIdSelected: '',
            storeIdSelected: '',
            colorIdSelected: '',
            colorTitleSelected: '',
            modelTypeIdSelected: '',
        };
    }
    componentDidMount() {
        //this.props.fetchStores(this.state.province);
        this.props.fetchProducts();
        var productId = localStorage.getItem('productId');
        setTimeout(
            function () {
                var { stores, products } = this.props;
                if (stores.length > 0) this.setState({ storeIdSelected: stores[0].id });
                if (products.length > 0) {
                    if (productId === null || productId === '') productId = products[0].id;

                    this.setState({ productIdSelected: productId });
                    products.forEach((element) => {
                        if (element.id === productId) {
                            if (element.colors.length > 0) this.setState({ colorIdSelected: element.colors[0].id });
                            if (element.modelTypes.length > 0)
                                this.setState({ modelTypeIdSelected: element.modelTypes[0].id });
                        }
                    });

                    this.handleFetchPrice();
                }
            }.bind(this),
            500
        );

        setTimeout(
            function () {
                var element = document.getElementById('Product');
                element.value = productId;
                this.setState({ productIdSelected: productId });
            }.bind(this),
            1000
        );
    }

    handleProvinceChange = (event) => {
        this.setState({ province: event.target.value });
        this.props.fetchStores(event.target.value);
    };

    handleProductChange = (event) => {
        this.setState({ productIdSelected: event.target.value });
        var { products } = this.props;
        products.forEach((element) => {
            if (element.id === event.target.value) {
                if (element.colors.length > 0) this.setState({ colorIdSelected: element.colors[0].id });
                if (element.modelTypes.length > 0) this.setState({ modelTypeIdSelected: element.modelTypes[0].id });
            }
        });
        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleStoreChange = (event) => {
        this.setState({ storeIdSelected: event.target.value });
        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleColorChange = (event) => {
        this.setState({
            colorIdSelected: event.target.value,
            colorTitleSelected: event.target.getAttribute('data-title'),
        });
        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleModelTypeChange = (event) => {
        this.setState({ modelTypeIdSelected: event.target.value });
        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleFetchPrice = () => {
        this.props.fetchProductPrice(
            this.state.productIdSelected,
            this.state.storeIdSelected,
            this.state.colorIdSelected,
            this.state.modelTypeIdSelected
        );
        setTimeout(
            function () {
                let { productPrice } = this.props;
                let priceValue = '';
                let oldPriceValue = '';
                let discountAmountValue = '';
                let discountGiftValue = '';
                if (productPrice !== null) {
                    priceValue = productPrice.price;
                    oldPriceValue = productPrice.oldPrice;
                    discountAmountValue = productPrice.discountAmount;
                    discountGiftValue = productPrice.discountGift;
                }
                this.setState({
                    price: priceValue,
                    oldPrice: oldPriceValue,
                    discountAmount: discountAmountValue,
                    discountGift: discountGiftValue,
                });
            }.bind(this),
            500
        );
    };

    render() {
        var { form, stores, products } = this.props;
        var colorList = [];
        var models = [];
        var productSelectItems = [];
        var images = [];
        var productId = this.state.productIdSelected;
        var storeId = this.state.storeIdSelected;
        var colorId = this.state.colorIdSelected;
        var colorTitle = this.state.colorTitleSelected;
        var modelTypeId = this.state.modelTypeIdSelected;
        var storeList = [];
        if (this.state.province !== '') {
            stores.forEach((element) => {
                if (storeId === '') storeId = element.id;

                storeList.push({
                    id: element.id,
                    title: element.name,
                    description: element.description,
                });
            });
        }

        products.forEach((element) => {
            if (productId === '') productId = element.id;

            productSelectItems.push({
                id: element.id,
                value: element.id,
                label: element.title,
            });

            if (models.length === 0 || element.id === productId) {
                models = [];
                element.modelTypes.forEach((modelType) => {
                    if (modelTypeId === '') modelTypeId = modelType.id;
                    models.push({
                        id: modelType.id,
                        value: modelType.id,
                        label: modelType.name,
                    });
                });
            }

            if (colorList.length === 0 || element.id === productId) {
                colorList = [];

                element.colors.forEach((color) => {
                    if (colorId === '') colorId = color.id;
                    colorList.push({
                        id: color.id,
                        color: color.colorCode,
                        imageUrl: color.imageUrl,
                        title: color.name,
                    });
                });
            }

            if (element.id === productId) {
                images = [];
                element.images.forEach((image) => {
                    if (image.colorId === colorId && image.displayOrder < 98) {
                        images.push({
                            id: image.id,
                            title: image.title,
                            imageUrl: image.imageUrl,
                        });
                    }
                });
            }
        });

        return (
            <div className="row custom-row">
                <div className="col-lg-6 col-xl-5-cus">
                    <div className="box-products">
                        <div className="wrap-form">
                            <FormStep1Left
                                form={form}
                                colorList={colorList}
                                models={models}
                                productSelectItems={productSelectItems}
                                storeList={storeList}
                                handleProductChange={this.handleProductChange}
                                handleProvinceChange={this.handleProvinceChange}
                                onChangeColor={this.handleColorChange}
                                colorTitle={colorTitle}
                                onChangeModelType={this.handleModelTypeChange}
                                onChangeStore={this.handleStoreChange}
                            />
                            <div className="main-price row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <InputField
                                            name="recommendedPrice"
                                            label="Giá khuyến nghị (Đã bao gồm phí VAT) (*)"
                                            value={this.state.oldPrice}
                                            form={form}
                                            disabled
                                            title="Giá khuyến nghị mang tính chất tham khảo. Vui lòng liên hệ Đại lý uỷ quyền để biết thông tin cụ thể về giá, chương trình khuyến mại và các thông tin khác"
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <InputField
                                            name="specialOffer"
                                            label="Ưu đãi (*)"
                                            value={this.state.discountAmount}
                                            form={form}
                                            disabled
                                            title="Vui lòng liên hệ Đại lý ủy quyền để biết thêm về chương trình ưu đâĩ"
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <InputField
                                            name="depositAmount"
                                            label="Số tiền đặt cọc"
                                            value={this.state.price}
                                            form={form}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="note">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.discountGift }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-7-cus">
                    <ProductSlide images={images} />
                    <FormStep1Right form={form} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        stores: state.stores,
        products: state.products,
        productPrice: state.productPrice,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchStores: (province) => {
            dispatch(actFetchStoresRequest(province));
        },
        fetchProducts: () => {
            dispatch(actFetchProductsRequest('Home', ''));
        },
        fetchProductPrice: (productId, storeId, colorId, modelTypeId) => {
            dispatch(actFetchProductPriceRequest(productId, storeId, colorId, modelTypeId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step1Form);
