import React from 'react';

import './styles.sass';

function Coupon(props) {
    return (
        <div className="widget widget-coupon">
            <h2 className="widget-title fz-14 fw-700 text-main text-up">Nhập mã giảm giá</h2>
            <div className="form-group">
                <label htmlFor="coupon">Nhập mã E - voucher</label>
                <div className="group">
                    <input type="text" placeholder="Nhập mã E - voucher" id="coupon" />
                    <button className="btn btn-submit" type="submit">
                        Áp dụng
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Coupon;
