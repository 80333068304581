import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchStoresRequest, actFetchRequestCounsellingRequest } from '../../../../actions/index';

// import InputField from "../../../form-control/InputField";
// import Select from "../../../form-control/SelectField";
import TableOrder from '../TableOrder';

class OrderListSearch extends Component {
    constructor(props) {
        super(props);
		var today = new Date(),
            date = today.getFullYear() + '-' + ((today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + (today.getDate() < 10 ? '0' + (today.getDate()) : today.getDate());
        this.state = {
            storeId: '',
            status: -1,
            startDate: Date.now(),
            endDate: Date.now(),
            keyword: '', 
            pageIndex: 0,
            pageSize: 20,
			date: date
        };
    }

    componentDidMount() {
        this.props.fetchStores('');
        setTimeout(
            function () {
                var { storeId, status, startDate, endDate, keyword, pageIndex, pageSize} = this.props;
                var json = {
                    storeId,
                    status,
                    startDate,
                    endDate,
                    keyword,
                    pageIndex,
                    pageSize,
                }
                this.props.fetchOrders(json); 
            }.bind(this),
            1000 
        );
    };

    handleStoreChange = (event) => {
        this.setState({ storeId: event.target.value });
    }; 
    handleStatusChange = (event) => {
        this.setState({ status: event.target.value });
    };
    
    handleSubmit = (e) => {
        e.preventDefault();
        var storeId =  this.state.storeId;
        var status =  this.state.status;
        var startDate =  null; 
        var endDate = null;
        var keyword =  this.state.keyword;
        var pageIndex =  this.state.pageIndex;
        var pageSize =  this.state.pageSize;

        var json = {
            storeId,
            status,
            startDate,
            endDate,
            keyword,
            pageIndex,
            pageSize,
        }
        this.props.fetchOrders(json); 
    };
    
    render() {
        var { stores, orders } = this.props;
        var storeList = [];
        stores.forEach((element) => {
            storeList.push({
                id: element.id,
                label: element.name,
                value: element.id,
            });
        });
        
        var orderTableList = [];
        const statusList =[
            {
                id: 1,
                value: "0",
                label: "Chưa liên hệ",
            },
            {
                id: 2,
                value: "10",
                label: "Đã liên hệ - đã trả lời",
            },
            {
                id: 3, 
                value: "20",
                label: "Đã liên hệ - không trả lời",
            }
        ]
        let i = 0;
        orders.forEach((element) => {
            i++;
            let el={
                id: element.Id,
                number : i,  
                title: element.ProductName,
                verison: element.ProductModelName,
                color: element.ProductColorName,
                code: element.Code,
                user: element.FullName,
                total: element.TotalAmount,
                store: element.StoreName,
                phone: element.PhoneNumber,
                email: element.Email,
                province: element.StoreProvince,
                statusId: element.ProcessStatusId,
                paymentMethods: element.PaymentMethodName, 
                CreateOn: element.CreateOn,
               
            };
            el.status = [];
            statusList.forEach((item) => {
                if(el.PaymentStatusId === item.id)
                    item.isActive = true; 
                el.status.push(item);
            }); 
            orderTableList.push(el); 
        });
        return (
            <>
                <form onSubmit={this.handleSubmit} > 
                    <div className="box-admin box-request-counselling">
                        <div className="heading-button flex align-center between">
                            <h1 className="heading-title fz-24 text-white fw-700">Yêu cầu tư vấn</h1>
                        </div>
                    </div> 
                    <TableOrder orderTableList={orderTableList}/>
                </form>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        stores: state.stores,
        orders : state.orders
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchStores: (province) => {
            dispatch(actFetchStoresRequest(province,true));
        },
        fetchOrders: (body) => {
            dispatch(actFetchRequestCounsellingRequest(body));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderListSearch);
