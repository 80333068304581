import React from 'react';

import '../styles.sass';

import HtmlContent from '../../../html-content/index';

// popup
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiDialogContent);

function TermsAndConditionComponent(props) {
	// popup
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

	const htmlKey = 'TermsAndCondition-Pupup';
	return (
		<>
			<Button
                className="btn-popup"
                variant="outlined" 
                color="primary" 
                onClick={handleClickOpen}
                >
                Điều khoản và điều kiện của website 
            </Button>
            <Dialog 
                maxWidth="lg" 
                onClose={handleClose} 
                aria-labelledby="customized-dialog-title" 
                open={open}
            >
                <div className="form-popup" id="check-order">
                    <section className="success-section">
                        <div className="box-form-popup bg-white">
                            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                <h3 className="main-title text-center">Điều khoản và điều kiện của website </h3>
                            </DialogTitle>
                            <DialogContent>
                                <div className="content text-left">
					                <HtmlContent system={htmlKey}/>  
                                </div>
                            </DialogContent>
                        </div>
                    </section>
                </div>
            </Dialog>
		</>
	);
}

export default TermsAndConditionComponent;