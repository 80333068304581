import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchPurchaseListRequest } from '../../../actions/index';

import CustomerNavLink from '../Menu/index';

class PurchaseHistoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageIndex: 0,
            pageSize: 20,
        };
    }
    componentDidMount() {
        var json = {
            pageIndex: 0,
            pageSize: 20,
        };
        this.props.fetchOrders(json);
    }

    render() {
        var { orders } = this.props;
        var orderTableList = [];
        let i = 0;
        orders.forEach((element) => {
            i++;
            orderTableList.push({
                id: element.Id,
                number: i,
                title: element.ProductName,
                verison: element.ProductModelName,
                color: element.ProductColorName,
                code: element.Code,
                user: element.FullName,
                total: element.TotalAmount,
                store: element.StoreName,
                paymentMethods: element.PaymentMethodName,
                url: '/purchase-history-detail?id=' + element.Id + '&token=' + element.Token,
            });
        });
        return (
            <section className="admin-section customer-section section bg-login bdbt-gray">
                <div className="container">
                    <div className="row row-space">
                        <div className="col-lg-4 col-xl-3">
                            <CustomerNavLink />
                        </div>
                        <div className="col-lg-8 col-xl-9">
                            <div className="box-admin">
                                <h1 className="heading-title fz-24 text-white fw-700">Danh sách đơn hàng</h1>
                                <div className="table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Mã đơn hàng</th>
                                                <th>Người đặt</th>
                                                <th>Model</th>
                                                <th>Tổng tiền</th>
                                                <th>Đại lý</th>
                                                <th>Tình trạng thành toán</th>
                                                <th> </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderTableList.map((purchase) => (
                                                <tr key={purchase.id}>
                                                    <td className="id">
                                                        {purchase.number < 10 ? '0' : ''}
                                                        {purchase.number}
                                                    </td>
                                                    <td>{purchase.code}</td>
                                                    <td>{purchase.user}</td>
                                                    <td>
                                                        <p>{purchase.title}</p>
                                                        <p>{purchase.verison}</p>
                                                        <p>{purchase.color}</p>
                                                    </td>
                                                    <td>{purchase.total}</td>
                                                    <td>{purchase.store}</td>
                                                    <td>{purchase.paymentMethods}</td>
                                                    <td>
                                                        <a className="btn btn-detail" href={purchase.url}>
                                                            Chi tiết
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        orders: state.orders,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchOrders: (body) => {
            dispatch(actFetchPurchaseListRequest(body));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseHistoryComponent);
