import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './styles.sass';

LoanOfferZoneItemComponent.propTypes = {
    loanOfferItem: PropTypes.object.isRequired,
    loanNotLink: PropTypes.number,
};

function LoanOfferZoneItemComponent({ loanOfferItem, loanNotLink }) {
    return (
        <div
            className="loan-offer-item"
            // key={loanOfferItem.zoneId}
        >
            <div className="image">
                {loanNotLink !== 0 ? (
                    <Link className="img-scale" to={loanOfferItem.url} title={loanOfferItem.name}>
                        <img src={loanOfferItem.imageUrl} alt={loanOfferItem.name} />
                    </Link>
                ) : (
                    <a className="img-scale" href="#content-loan-offer" title={loanOfferItem.name}>
                        <img src={loanOfferItem.imageUrl} alt={loanOfferItem.name} />
                    </a>
                )}
                {/* <Link
					className="img-scale"
					to={loanNotLink !== 0 ? loanOfferItem.url : "#content-loan-offer"}
					title={loanOfferItem.name}
				>
					<img src={loanOfferItem.imageUrl} alt={loanOfferItem.name} />
				</Link> */}
            </div>
            <div className="caption text-center">
                <h2 className="news-title fz-22 fw-500 text-dark">
                    {loanNotLink !== 0 ? (
                        <Link to={loanOfferItem.url} title={loanOfferItem.name}>
                            {loanOfferItem.name}
                        </Link>
                    ) : (
                        <a href="#content-loan-offer" title={loanOfferItem.name}>
                            {loanOfferItem.name}
                        </a>
                    )}
                    {/* <Link
						to={loanNotLink !== 0 ? loanOfferItem.url : "#content-loan-offer"}
						title={loanOfferItem.name}
					>
						{loanOfferItem.name}
					</Link> */}
                </h2>
                <div className="desc fz-16 fw-400">{loanOfferItem.description}</div>
            </div>
        </div>
    );
}

export default LoanOfferZoneItemComponent;
