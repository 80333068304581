import React from 'react';

import Form from './Form';

function RecoverPasswordComponent(props) {
    return (
        <section className="recover-password-section section bg-login bdbt-gray">
            <div className="container"> 
                <div className="box-form-login bg-white">
                    <h1 className="main-title text-center">Quên mật khẩu</h1>
                    <Form />
                    
                </div>
            </div>
        </section>
    );
}

export default RecoverPasswordComponent;