import React from 'react';

import imagesBanner from '../../assets/images/child.png';

import BannerChildComponent from "../../components/banner/BannerChild";
import BreadcrumbComponent from "../../components/breadcrumb";
import DealersComponent from "../../components/dealers";

function DealersPages(props) {
    return (
        <>
            <BannerChildComponent imageBannerUrl={imagesBanner}/>
            <BreadcrumbComponent />
            <DealersComponent />
        </>
    );
}

export default DealersPages;