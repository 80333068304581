import React from 'react';
import PropTypes from 'prop-types';

InforTable.propTypes = {
    order: PropTypes.object.isRequired,
};

function InforTable({order}) {
    return (
        <>
            <div className="box-admin" key={order.id}>
                <h4 className="heading-title fz-24 text-white fw-700">Đơn hàng <span>#{order.Code}</span></h4>
                <div className="table table-info">
                    <table>
                        <tbody>
                            <tr> 
                                <td> 
                                    <p className="title">Đại lý</p>
                                    <p>{order.StoreName}</p>
                                </td>
                                <td> 
                                    <p className="title">Họ và tên</p>
                                    <p>{order.FullName}</p>
                                </td>
                                <td> 
                                    <p className="title">Email</p>
                                    <p>{order.Email}</p>
                                </td>
                                <td> 
                                    <p className="title">Số điện thoại</p>
                                    <p>{order.PhoneNumber}</p>
                                </td>
                            </tr>
                            <tr> 
                                <td> 
                                    <p className="title">Trạng thái đơn hàng</p>
                                    <p>{order.StatusName}</p>
                                </td>
                                <td> 
                                    <p className="title">Xác thực</p>
                                    <p>{order.VerificationStatus}</p>
                                </td>
                                <td> 
                                    <p className="title">Phương thức thanh toán</p>
                                    <p>{order.PaymentMethodName}</p>
                                </td>
                                <td> 
                                    <p className="title">Số tiền đã thanh toán</p>
                                    <p>{order.TotalAmountPaid}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default InforTable;