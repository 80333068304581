var initialState = [];

const stores = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_STORES": 
            return action.stores;  
        default: return state;
    }  
}; 
 
export default stores;