import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actFetchOrderDetailRequest } from '../../../actions/index';
import axios from 'axios';
import Get from '../../../utils/APIsHelper';

import FormStep3FormPersonalInfo from './Form';
  
import '../Step3-LoanProductInfo/styles.sass';

async function GetOptions(id, parentId)
{
    let options = [];  
    let link = process.env.REACT_APP_API_ENDPOINT + '/api/CustomFiled/Get?id=' + id;
    if(parentId !== undefined)
        link = process.env.REACT_APP_API_ENDPOINT + '/api/CustomFiled/Get?id=' + id + "&parentId=" + parentId;
    let result = await axios.get(link);
    if (result.data.success) {
        result.data.data.forEach(element => {
            options.push({
                id : element.Id,
                value : element.Value,
                label : element.Name
            });
        });
    } 
    return options;
}

function GetOptions2(id, parentId)
{
    let options = [];  
    let link = 'api/CustomFiled/Get?id=' + id;
    if(parentId !== undefined)
        link ='api/CustomFiled/Get?id=' + id + "&parentId=" + parentId;

    Get(link, 'get', null).then((res) => {
        if (res.data.success) {
            res.data.data.forEach(element => {
                options.push({
                    id : element.Id,
                    value : element.Value,
                    label : element.Name
                });
            });
        }
    });  
    return options;
}

class Step3FormPersonalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maritalStatus : [],
            placeIssued : [],
            provinces : [],
            districts : [],
            wards : [],
            currentProvinces : [],
            currentDistricts : [],
            currentWards : []
        };
    }
    async componentDidMount(): Promise<void> {
        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        this.props.fetchOrderDetail(orderId,orderToken);
        let maritalStatus = await GetOptions('61ee0e02e0480b6de259383a');
        let placeIssued = await GetOptions('61ee101be0480b6de259addd'); 
        let provinces = await GetOptions(process.env.REACT_APP_GEOZONE_KEY,"-1"); 
        let currentProvinces = provinces;
        let districts = [];
        let wards = [];
        let currentDistricts = [];
        let currentWards = [];
        if(provinces.length > 0)
        {
            districts = await GetOptions(process.env.REACT_APP_GEOZONE_KEY,provinces[0].value);
            currentDistricts = districts;
            if(districts.length > 0){

                wards = await GetOptions(process.env.REACT_APP_GEOZONE_KEY, districts[0].value);
                currentWards = wards;
            }
        }

        // Sleep for 10 seconds
        await new Promise(resolve => { setTimeout(resolve, 500); });
        // This line of code will be executed after 0.5 seconds
        this.setState({maritalStatus, placeIssued, provinces, districts, wards, currentProvinces, currentDistricts, currentWards});
        return Promise.resolve();
    }
    
    onChangeProvince = (e) =>{
        let province= e.target.value;
        let districts = GetOptions2(process.env.REACT_APP_GEOZONE_KEY, province); 
        setTimeout(function() {
            this.setState({districts});
            let wards = GetOptions2(process.env.REACT_APP_GEOZONE_KEY, districts[0].value); 
            setTimeout(function() {
                this.setState({wards});
            }
            .bind(this), 500);
        }
        .bind(this), 500);
    }
 
    onChangeDictrist = (e) =>{
        let district= e.target.value;
        let wards = GetOptions2(process.env.REACT_APP_GEOZONE_KEY, district); 
        setTimeout(function() {
            this.setState({wards});
        }
        .bind(this), 500);
    }
    onChangeCurrentProvince = (e) =>{
        let province= e.target.value;
        let currentDistricts = GetOptions2(process.env.REACT_APP_GEOZONE_KEY, province); 
        setTimeout(function() {
            this.setState({currentDistricts});
            let currentWards = GetOptions2(process.env.REACT_APP_GEOZONE_KEY, currentDistricts[0].value); 
            setTimeout(function() {
                this.setState({currentWards});
            }
            .bind(this), 500);
        }
        .bind(this), 500);
    }
 
    onChangeCurrentDictrist = (e) =>{
        let district= e.target.value;
        let currentWards = GetOptions2(process.env.REACT_APP_GEOZONE_KEY, district); 
        setTimeout(function() {
            this.setState({currentWards});
        }
        .bind(this), 500);
    }
    render() {
        const { form , orderDetail} = this.props;
        const { maritalStatus, placeIssued, 
            provinces, districts, wards, 
            currentProvinces, currentDistricts, currentWards} = this.state;
        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        if (orderId === null || orderToken === null)  
            return <Redirect path="/" />;
		return <FormStep3FormPersonalInfo   form={form} order={orderDetail}
											orderDetail={orderDetail}
											maritalStatus={maritalStatus}
											placeIssued={placeIssued}
											provinces={provinces}
											districts={districts} 
                                            wards={wards}
											currentProvinces={currentProvinces}
											currentDistricts={currentDistricts} 
                                            currentWards={currentWards}
                                            onChangeProvince={this.onChangeProvince}
                                            onChangeDictrist={this.onChangeDictrist}
                                            onChangeCurrentProvince={this.onChangeCurrentProvince}
                                            onChangeCurrentDictrist={this.onChangeCurrentDictrist}  />
    }
}

const mapStateToProps = (state) => {
    return {
        orderDetail: state.orderDetail,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
		fetchOrderDetail: (orderId, orderToken) => {
            let body = {Key : orderId, Token: orderToken};
			dispatch(actFetchOrderDetailRequest(body));
		},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3FormPersonalInfo);
