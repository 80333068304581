import React from 'react';
import PropTypes from 'prop-types';

TableProduct.propTypes = {
    orderDetail: PropTypes.object.isRequired,
};
 
function TableProduct({orderDetail}) {
    return (
        <div className="table table-product">
            <table> 
                <thead> 
                    <tr> 
                        <th>#</th>
                        <th>Sản phẩm</th>
                        <th>Số lượng</th>
                        <th>Giá</th>
                        <th>Tổng</th>
                    </tr>
                </thead>
                <tbody> 
                     <tr key={orderDetail.id}> 
                        <td className="id">
                           1
                        </td>
                        <td>{orderDetail.ProductName} {orderDetail.ProductModelName} {orderDetail.ProductColorName}</td>
                        <td>{orderDetail.Quantity}</td>
                        <td>{orderDetail.PriceUnit}</td>
                        <td>{orderDetail.TotalAmount}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TableProduct;