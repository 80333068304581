import React, { Component } from 'react';
import Get from '../../utils/APIsHelper';
class HtmlContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			item: [],
			isLoaded: false
		}
	}
	componentDidMount() {
		Get('api/HtmlContent/Get?key=' + this.props.system, 'Get', null).then((res) => {
			this.setState({
				isLoaded: true,
				item: res.data
			})
		});
	}
	render() {
		var { isLoaded, item } = this.state;
		if (!isLoaded) {
			return <div></div>
		}
		else {
			return (
				<div dangerouslySetInnerHTML={{ __html: item.content }}></div>
			);
		}
	}

}
export default HtmlContent;
