import React from 'react';

import './styles.sass';

import InfoPayment from '../InfoPayment';

import HtmlContent from '../../../../html-content/index';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Payment({orderDetail}) {
    return (
        <div className="widget widget-payment">
            <h2 className="widget-title fz-14 fw-700 text-main text-up">PHương thức thanh toán</h2>
            <div className="wrap-form">
                <Tabs>
                    <TabList className="payment-name type-none">
                        <Tab>
                            <div className="form-group form-radio">
                                <input type="radio" name="payment" defaultChecked="checked" id="payment-1"  value="0"/>
                                <label htmlFor="payment-1" data-payment={1}>
                                    Chuyển khoản ngân hàng
                                </label>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="form-group form-radio">
                                <input type="radio" name="payment" id="payment-2" value="10" />
                                <label htmlFor="payment-2" data-payment={2}>
                                    Thanh toán Online
                                </label>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="form-group form-radio">
                                <input type="radio" name="payment" id="payment-3" value="20" />
                                <label htmlFor="payment-3" data-payment={3}>
								Đăng ký vay mua xe
                                </label>
                            </div>
                        </Tab>
                    </TabList>
                    <div className="payment-content">
                        <TabPanel>
                            <div className="form-content">
                                <div className="content">
                                    <HtmlContent system={'Bank-Transfer-Description'} />
                                </div>
                                <InfoPayment orderDetail={orderDetail}/>
                            </div>
                        </TabPanel> 
                        <TabPanel>
                            <div className="form-content">
                                <div className="form-group form-radio form-payment-online">
                                    <input type="radio" name="payment-onl" id="payment-onl-1" value="10" /> 
                                    <label htmlFor="payment-onl-1">
                                        <HtmlContent system={'Online-Transfer-Description'} />
                                    </label>
                                    {/* <span>* Khách hàng không chịu phí.</span> */}
                                </div>
                            </div>
                        </TabPanel> 
						<TabPanel>
                            <div className="form-content">
                                <div className="content">
									<p>Quý KH vui lòng hoàn thành thông tin đăng ký vay mua xe ở bước tiếp theo để hoàn tất thông tin đặt hàng xe.</p>
                                    {/* <HtmlContent system={'Bank-Transfer-Description'} /> */}
                                </div>
                            </div>
                        </TabPanel> 
                    </div>
                </Tabs>
            </div>
        </div>
    );
}

export default Payment;
