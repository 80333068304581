import React from 'react';

import AdminNavLink from '../../../components/admin/Menu';
import LoanRegistrationListComponent from '../../../components/admin/LoanRegistrationList';

function AdminLoanRegistrationListPage(props) {
	return (
		<section className="admin-section customer-section section bg-login bdbt-gray">
			<div className="container"> 
				<div className="row row-space">
					<div className="col-lg-4 col-xl-3"> 
						<AdminNavLink /> 
					</div>
					<div className="col-lg-8 col-xl-9"> 
						<LoanRegistrationListComponent />
					</div>
				</div>
			</div>
		</section>
	);
}

export default AdminLoanRegistrationListPage;