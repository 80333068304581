import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actFetchOrderDetailRequest } from '../../../actions/index';
import axios from 'axios';

import FormStep3LoanProductInfo from './Form';

import './styles.sass';

async function GetOptions(id, parentId) {
    var options = [];
    let link = process.env.REACT_APP_API_ENDPOINT + '/api/CustomFiled/Get?id=' + id;
    if (parentId !== undefined)
        link = process.env.REACT_APP_API_ENDPOINT + '/api/CustomFiled/Get?id=' + id + '&parentId=' + parentId;
    var result = await axios.get(link);
    if (result.data.success) {
        result.data.data.forEach((element) => {
            options.push({
                id: element.Id,
                value: element.Value,
                label: element.Name,
            });
        });
    }
    return options;
}

class Step3FormLoanProductInfo extends Component {
    state = {
        purposes: [],
        promotion: [],
    };

    async componentDidMount(): Promise<void> {
        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        this.props.fetchOrderDetail(orderId, orderToken);

        const purposes = await GetOptions('61ee19034d62f211f0ceb434');
        const promotion = await GetOptions('61ee18cc4d62f211f0ceb2e0');
        // const productId = await GetOptions('61ee18cc4d62f211f0ceb2e0');
        // Sleep for 10 seconds
        await new Promise((resolve) => {
            setTimeout(resolve, 500);
        });
        // This line of code will be executed after 0.5 seconds
        this.setState({ purposes, promotion });
        return Promise.resolve();
    }

    render() {
        const { form, orderDetail } = this.props;
        const { purposes, promotion } = this.state;
        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        if (orderId === null || orderToken === null) return <Redirect path="/" />;
        return <FormStep3LoanProductInfo order={orderDetail} form={form} purposes={purposes} promotion={promotion} />;
    }
}

const mapStateToProps = (state) => {
    return {
        orderDetail: state.orderDetail,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchOrderDetail: (orderId, orderToken) => {
            let body = { Key: orderId, Token: orderToken };
            dispatch(actFetchOrderDetailRequest(body));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3FormLoanProductInfo);
