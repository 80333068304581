var initialState = [];

const contracts = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_LoanContract_LIST":
			return action.contracts;
		default: return state;
	}
};

export default contracts;