import React from 'react';

import './styles.sass';

function Error(props) {
    return (
        <>
            <section className="page-not-found section">
                <div className="container">
                    <h1 className="main-title text-center">404 Pages not found</h1>
                    <p className="text-center">Trang bạn đang tìm kiếm không tồn tại. Chúng tôi xin lỗi vì sự bất tiện này. </p>
                    <p className="text-center">Trở về <a href="/" title="Trang chủ">Trang chủ </a>.</p>
                </div>
            </section>
        </>
    );
}

export default Error;