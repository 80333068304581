import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchNewsItemsRequest } from '../../actions/index';

import imagesBanner from '../../assets/images/child.png';

import BannerChildComponent from '../../components/banner/BannerChild';
import BreadcrumbComponent from '../../components/breadcrumb';
import NavBarLeft from '../../components/navbar/NavBarLeft';

import './styles.sass';

class NewsDetailPage extends Component {
    componentDidMount() {
        var { cateId } = this.props;
        this.props.fetchNewItems(cateId);
    }
    render() {
        var { newsItems } = this.props;
        var newsItem;
        if (newsItems.length > 0) newsItem = newsItems[0];

        return (
            <>
                <BannerChildComponent imageBannerUrl={imagesBanner} />
                <BreadcrumbComponent />
                <section className="faqs-section section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-xl-3">
                                <NavBarLeft />
                            </div>
                            <div className="col-lg-8 col-xl-9">
                                <div className="box-faqs">{this.showNewDetail(newsItem)}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
    showNewDetail($newItem) {
        if ($newItem !== undefined) {
            return (
                <>
                    <h1 className="heading-title fz-30 text-white fw-700">{$newItem.title}</h1>
                    <div className="accordion">
                        <div
                            className="operating-regulations"
                            dangerouslySetInnerHTML={{ __html: $newItem.fullContent }}
                        ></div>
                    </div>
                </>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        newsItems: state.newsItems,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchNewItems: (cateId) => {
            dispatch(actFetchNewsItemsRequest('', cateId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetailPage);
