import React, { Component } from 'react';

import './styles.sass';


class Success extends Component {
    render() {
        const {orderDetail} = this.props;
        return ( 
            <div className="box-finish text-center">
                <h1 className="finish-title fz-40 fw-300 text-up text-main">
                    Cám ơn quý khách đã đặt mua <span>{orderDetail.ProductName}</span>
                </h1>
                <div className="desc fz-15 text-67"> 
                    <p>
                        Hệ thống đã ghi nhận đơn hàng thành công mã đơn hàng của bạn là <span>{orderDetail.Code}</span>
                    </p>
                    <p>Quý khách vui lòng kiểm tra thông tin chi tiết tại Email đã đăng ký</p>
                </div>
                <div className="image">
                    <img src={orderDetail.ImageUrl} alt={orderDetail.ProductName} />
                </div>
                {/* <div className="button flex center">
                    <a className="btn btn-light-blue" href={orderDetail.linkContinue}>
                        <span>Kiểm tra đơn hàng</span>
                        
                    </a>
                </div> */}
                <div className="info">
                    <h2 className="name">Thông tin liên hệ </h2>
                    <p className="hotline fz-18 fw-500 text-white">
                        Hotline: <a href="tel: 1900561212">1900561212</a>
                    </p>
                    <p className="mail fz-15 fw-400 text-white">
                        <a href="mailto: cs@hyundai.tcmotor.vn">cs@hyundai.tcmotor.vn</a>
                    </p>
                </div>
            </div>
        );
    }
}

export default Success;
