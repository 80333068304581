import React, { useState } from 'react';
import Get from '../../../../utils/APIsHelper';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AlertMessage from '../../../alert/index';
import InputField from '../../../form-control/InputField';
import Select from '../../../form-control/SelectField';
import TableProduct from '../TableProduct';
import TablePayment from '../TablePayment';

function Form({ orderDetail }) {
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('alert alert-success');

    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const handleSendLinkContinutePlace = (e) => {
        e.preventDefault();
        let body = {
            key: orderDetail.Id,
        };
        Get('api/order/SendLinkContinutePlace', 'post', body).then((res) => {
            if (res.data.success) {
                setAlertType('alert alert-success');
                setAlertMessage('Gửi "Link" tiếp tục đặt hàng thành công');
            }
        });
    };

    const handleSendLinkContinutePay = (e) => {
        e.preventDefault();
        let body = {
            key: orderDetail.Id,
        };
        Get('api/order/SendLinkContinutePay', 'post', body).then((res) => {
            if (res.data.success) {
                setAlertType('alert alert-success');
                setAlertMessage('Gửi "Link" tiếp tục thanh toán thành công');
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let body = {
            key: orderDetail.Id,
            fullName: e.target.FullName.value,
            email: e.target.Email.value,
            phoneNumber: e.target.PhoneNumber.value,
            orderStatus: e.target.OrderStatus.value,
            PaymentStatus: e.target.PaymentStatus.value,
            ProcessingStatus: e.target.ProcessingStatus.value,
        };
        Get('api/order/UpdateOrder', 'post', body).then((res) => {
            if (res.data.success) {
                setAlertType('alert alert-success');
                setAlertMessage('Cập nhật thành công');
            }
        });
    };

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    if (Object.keys(orderDetail).length === 0) return <>....</>;

    const showButton = () => {
        if (orderDetail.VerificationStatus === 'None') {
            return (
                <>
                    <div className="frm-btn">
                        <input
                            className="btn btn-default frm-btn-submit"
                            value="Gửi link tiếp tục đặt hàng"
                            type="button"
                            onClick={(e) => handleSendLinkContinutePlace(e)}
                        />
                    </div>
                </>
            );
        } else if (orderDetail.PaymentStatusId === 20) {
            return (
                <>
                    <div className="frm-btn">
                        <input
                            className="btn btn-default frm-btn-submit"
                            value="Gửi link tiếp tục thanh toán"
                            type="button"
                            onClick={(e) => handleSendLinkContinutePay(e)}
                        />
                    </div>
                </>
            );
        }
    };
    const paymentTableList = [];
    const statusList = [
        {
            id: 0,
            title: 'Chưa xác nhận',
            isActive: false,
        },
        {
            id: 30,
            title: 'Đã xác nhận',
            isActive: false,
        },
        {
            id: 40,
            title: 'Thanh toán thất bại',
            isActive: false,
        },
    ];
    if (orderDetail.PaymentTransactions != null && orderDetail.PaymentTransactions.length > 0) {
        var number = 0;
        orderDetail.PaymentTransactions.forEach((element) => {
            number++;
            element.number = number;
            element.status = [];
            if (element.Files === null) element.Files = [];
            statusList.forEach((item) => {
                if (element.PaymentStatusId === item.id) item.isActive = true;
                element.status.push(item);
            });
            paymentTableList.push(element);
        });
    }
    return (
        <>
            <div className="box-admin">
                <h1 className="heading-title fz-24 text-white fw-700">
                    Đơn hàng
                    <span>#{orderDetail.Code}</span>
                </h1>
                <AlertMessage message={alertMessage} type={alertType} />
                <form onSubmit={handleSubmit}>
                    <div className="wrap-form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="agency"
                                        type="text"
                                        label="Đại lý"
                                        value={orderDetail.StoreName}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <InputField
                                        name="FullName"
                                        type="text"
                                        label="Họ và tên"
                                        value={orderDetail.FullName}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <InputField
                                        name="Email"
                                        type="email"
                                        label="Email"
                                        value={orderDetail.Email}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <InputField
                                        name="PhoneNumber"
                                        type="text"
                                        label="Số điện thoại"
                                        value={orderDetail.PhoneNumber}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <InputField
                                        name="createdDate"
                                        type="date"
                                        label="Ngày khởi tạo"
                                        value={orderDetail.CreateDateOn}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <InputField
                                        name="amountPaid"
                                        type="text"
                                        label="Số tiền đã thanh toán"
                                        value={orderDetail.PhoneNumber}
                                        form={form}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="OrderStatus"
                                        label="Trạng thái đơn hàng"
                                        required
                                        defaultValue={orderDetail.StatusId}
                                        options={[
                                            {
                                                id: 1,
                                                value: '0',
                                                label: 'Mới',
                                            },
                                            {
                                                id: 2,
                                                value: '10',
                                                label: 'Đang xử lý',
                                            },
                                            {
                                                id: 3,
                                                value: '30',
                                                label: 'Hoàn tất',
                                            },
                                            {
                                                id: 4,
                                                value: '40',
                                                label: 'Hủy',
                                            },
                                        ]}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <InputField
                                        name="authenticationMethod"
                                        type="text"
                                        label="Phương thức xác thực"
                                        value={orderDetail.VerificationMethod}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group no-border">
                                    <InputField
                                        name="positively"
                                        type="text"
                                        label="Xác thực"
                                        value={orderDetail.VerificationStatus}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group no-border">
                                    <InputField
                                        name="paymentMethods"
                                        type="text"
                                        label="Phương thức thanh toán"
                                        value={orderDetail.PaymentMethodName}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        name="PaymentStatus"
                                        label="Trạng thái thanh toán"
                                        required
                                        defaultValue={orderDetail.PaymentStatusId}
                                        options={[
                                            {
                                                id: 1,
                                                value: '0',
                                                label: 'None',
                                            },
                                            {
                                                id: 2,
                                                value: '10',
                                                label: 'Pending',
                                            },
                                            {
                                                id: 3,
                                                value: '20',
                                                label: 'Thanh toán 1 phần',
                                            },
                                            {
                                                id: 4,
                                                value: '30',
                                                label: 'Thanh toán 100% tiền cọc',
                                            },
                                            {
                                                id: 5,
                                                value: '40',
                                                label: 'Thanh toán thất bại',
                                            },
                                        ]}
                                        form={form}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        name="ProcessingStatus"
                                        label="Trạng thái xử lý"
                                        required
                                        defaultValue={orderDetail.ProcessStatusId}
                                        options={[
                                            {
                                                id: 1,
                                                value: '0',
                                                label: 'Mới',
                                            },
                                            {
                                                id: 2,
                                                value: '10',
                                                label: 'Đã liên hệ - khách đã phản hồi',
                                            },
                                            {
                                                id: 3,
                                                value: '20',
                                                label: 'Đã liên hệ - khách không phản hồi',
                                            },
                                        ]}
                                        form={form}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group frm-btnwrap">
                                    {showButton()}
                                    <div className="frm-btn">
                                        <input
                                            className="btn btn-default frm-btn-submit"
                                            type="submit"
                                            value="Cập nhật"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <TableProduct orderDetail={orderDetail} />
            <TablePayment paymentTableList={paymentTableList} form={form} />
        </>
    );
}

export default Form;
