import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';

import './styles.sass';

class ColorList extends Component {
    render() { 
        var {colorList, onChangeColor, colorTitle} = this.props; 
        return (
            <>
                <FormControl component="fieldset" className="form-group form-color">
                    <FormLabel component="legend"><label htmlFor="true">Màu sắc <span>*</span></label> </FormLabel>
                    <RadioGroup 
                        aria-label="color" 
                        name="ProductColor" 
                        className="color-list type-none flex align-center wrap"
                    >
                        {colorList.map((color, index) => (
                            <FormControlLabel
                                key={color.id}
                                value={color.id} 
                                control={
                                    <> 
                                        <input 
                                            name="ProductColor"
                                            type="radio"   
                                            value={color.id}
                                            onChange={onChangeColor} 
                                            id={`color-id-${color.id}`}
                                            // defaultChecked={index === 0 ? true : false}
                                            data-title={color.title}
                                        >
                                        </input>
                                        <label 
                                            htmlFor={`color-id-${color.id}`} 
                                            dangerouslySetInnerHTML={{__html: color.color = '' ? color.imageUrl : `<div style="background-color: ${color.color}"></div>`}}
                                        >
                                        </label>
                                    </>
                                }
                            >
                            </FormControlLabel>
                        ))}
                    </RadioGroup>
                </FormControl>
                <p className="color-active">Màu sắc: <span>{colorTitle}</span></p>
            </>
        );
    }
}
export default ColorList;
