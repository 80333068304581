
const API_URL = process.env.REACT_APP_API_ENDPOINT;
export default async function userInfo() {
	let tokenString = sessionStorage.getItem('token');
	let userToken = JSON.parse(tokenString);
	if (userToken === null) {
		if (sessionStorage.getItem('token-vendors') !== null) {
			tokenString = sessionStorage.getItem('token-vendors');
		} else {
			tokenString = sessionStorage.getItem('token-customer');
		}
		userToken = JSON.parse(tokenString);
	}
	return fetch(API_URL + '/api/Customer/UserInfo', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Token': userToken?.token
		},
	}).then(data => data.json())
} 