import React from 'react';

import LoginForm from './Form';

function LoginUserComponent({ setToken }) {
    return (
        <section className="login-section section bg-login bdbt-gray">
            <div className="container">
                <div className="box-form-login bg-white">
                    <h1 className="main-title text-center">Đăng nhập</h1>
                    <LoginForm setToken={setToken} />
                </div>
            </div>
        </section>
    );
}

export default LoginUserComponent;
