import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

SelectField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};

function SelectField(props) {
    const { form, name, value, label, disabled, options, required, handleChange, onBlur, defaultValue } = props;
    const { errors, touchedFields } = form.formState;
    const hasError = touchedFields[name] && errors[name];
    return (
        <Controller
            control={form.control}
            name={name}
            render={({ field }, formState) => (
                <>
                    <InputLabel htmlFor={name}>
                        <div dangerouslySetInnerHTML={{ __html: required ? `${label} <span>*</span>` : label }}></div>
                    </InputLabel>
                    <NativeSelect
                        label={label}
                        {...field}
                        id={name}
                        fullWidth
                        error={!!hasError}
                        disabled={disabled}
                        onChange={handleChange}
                        onBlur={onBlur}
                        defaultValue={defaultValue}
                        value={value}
                    >
                        {options.map((option) => (
                            <option key={option.id} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </NativeSelect>
                </>
            )}
        />
    );
}

export default SelectField;
