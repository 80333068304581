import React, { Component } from 'react';

import { connect } from 'react-redux';
import { actFetchOrderDetailRequest } from '../../../actions/index';

import NavList from '../NavList';
import FormStep2 from './Form';
import { Redirect } from 'react-router-dom';


 
class Step2Component extends Component {
	componentDidMount() { 
        let orderId = localStorage.getItem('orderId');
        let orderToken = localStorage.getItem('orderToken');
		this.props.fetchOrderDetail(orderId, orderToken); 
	};
	render() {
        var { orderDetail} = this.props;
        const stepList = [
            {
                id: 1,
                title: 'Lựa chọn xe',
                subTitle: '',
                description: '',
                url: '',
                imageUrl: '',
                thumbnailUrl: '',
                isActive: false,
                isTarget: false,
            },
            {
                id: 2,
                title: 'Thanh toán & ưu đãi',
                subTitle: '',
                description: '',
                url: '',
                imageUrl: '',
                thumbnailUrl: '',
                isActive: true,
                isTarget: false,
            },
            {
                id: 3,
                title: 'Hoàn tất',
                subTitle: '',
                description: '',
                url: '',
                imageUrl: '',
                thumbnailUrl: '',
                isActive: false,
                isTarget: false,
            },
        ];
        var orderId = localStorage.getItem('orderId');

        if (orderId == null) 
            return <Redirect path="/" />;

        return (
            <>
                <NavList stepList={stepList} />

                <FormStep2  orderDetail={orderDetail} />
            </>
            );
        }
}

const mapStateToProps = (state) => {
	return { 
		orderDetail: state.orderDetail,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchOrderDetail: (orderId, orderToken) => {
            let body = {Key : orderId, Token: orderToken};
			dispatch(actFetchOrderDetailRequest(body));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2Component);

