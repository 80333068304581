

import React, { lazy, Suspense  } from 'react';

const HomeBanner = lazy(() => import('../../components/banner/HomeBanner'));
const HomeStepComponent = lazy(() => import('../../components/home/homeStep'));
const HomeProductComponent = lazy(() => import('../../components/home/homeProduct'));
const HomeSupportComponent = lazy(() => import('../../components/home/homeSupport'));

const renderLoader = () => <></>; 
const HomePages = () => (
    <Suspense fallback={renderLoader()}>
        <HomeBanner />
        <HomeStepComponent />
        <HomeProductComponent />
        <HomeSupportComponent />
    </Suspense> 
)

export default HomePages;