import React from 'react';
// import React, {useState} from 'react';

// form
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";

// import Select from "../form-control/SelectField";

import LoanOfferNavComponent from '../loan-offer/loan-offer-nav';
import LoanOfferBankTableComponent from '../loan-offer/loan-offer-bank';
import '../loan-offer/loan-offer-form/styles.sass';

function LoanDetailComponent(props) {
    const { zoneId, cateId, zoneUrl } = props;
    const loanPeriodId = '';
    const rateOfferId = '';
    const rateAfterIncentivesId = '';
    const appraisalTime = '';
    // const [loanPeriodId, setLoanPeriodId] = useState('');
    // const [rateOfferId, setRateOfferId] = useState('');
    // const [rateAfterIncentivesId, setRateAfterIncentivesId] = useState('');
    // const [appraisalTime, setAppraisalTime] = useState('');
    // let search = (e) => {
    //     e.preventDefault();
    //     setLoanPeriodId(e.target.LoanPeriod.value);
    //     setRateOfferId(e.target.InterestRate.value);
    //     setRateAfterIncentivesId(e.target.LoanInterestSupport.value);
    //     setAppraisalTime(e.target.AppraisalTime.value);
    // };
    const normalLoanNavList = [
        {
            zoneId: 1,
            zoneTitle: 'Chương trình Ưu đãi thông thường',
            zoneDescription: 'Khách hàng vay với lãi suất ưu đãi chỉ từ 6%/năm và tự thanh toán gốc lãi cho ngân hàng',
            zoneUrl: '/loan-offer/chuong-trinh-uu-dai-thong-thuong',
            zoneImageUrl: '',
            isActive: zoneId === '1',
        },
        // {
        // 	zoneId: 2,
        // 	zoneTitle: 'Chương trình hỗ trợ lãi suất 0% ',
        // 	zoneDescription: 'Khách hàng được hỗ trợ tiền lãi vay lên tới 12 tháng tại các ngân hàng liên kết với HTV',
        // 	zoneUrl: '/loan-offer/chuong-trinh-ho-tro-lai-suat',
        // 	zoneImageUrl: '',
        // 	isActive: zoneId === "2",
        // },
        // {
        // 	zoneId: 3,
        // 	zoneTitle: 'Ưu đãi đặc biệt',
        // 	zoneDescription: 'Hyundai Thành Công triển khai chương trình ưu đãi tháng 12 trên toàn quốc “Hyundai đồng hành - Giáng sinh an lành”',
        // 	zoneUrl: '/loan-offer/uu-dai-dac-biet',
        // 	zoneImageUrl: '',
        // 	isActive: zoneId === "3",
        // }
    ];
    // form
    // const schema = yup.object().shape({
    // 	content: yup.string().required("Please enter title"),
    // });

    // const form = useForm({
    // 	defaultValues: {
    // 		content: "",
    // 	},
    // 	resolver: yupResolver(schema),
    // });
    return (
        <>
            <LoanOfferNavComponent navList={normalLoanNavList} />
            <div className="box-normal-loan-wrap">
                {/* <form method="post" onSubmit={search}>
                    <div className="normal-loan-form wrap-form">
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-xl-1-cus">
                                <div className="form-group">
                                    <Select
                                        name="LoanPeriod"
                                        label="Thời gian cho vay"
                                        options={
                                            [
                                                {
                                                    id: 0,
                                                    value: "0",
                                                    label: "Chọn thời gian cho vay",
                                                },
                                                {
                                                    id: 1,
                                                    value: "1",
                                                    label: "10 năm",
                                                },
                                                {
                                                    id: 2,
                                                    value: "2",
                                                    label: "20 năm",
                                                },
                                            ]
                                        } 
                                        form={form}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-1-cus">
                                <div className="form-group">
                                    <Select
                                        name="InterestRate"
                                        label="Lãi suất ưu đãi"
                                        options={
                                            [
                                                {
                                                    id: 0,
                                                    value: "0",
                                                    label: "Chọn lãi suất ưu đãi",
                                                },
                                                {
                                                    id: 1,
                                                    value: "1",
                                                    label: "10%",
                                                },
                                                {
                                                    id: 2,
                                                    value: "2",
                                                    label: "20%",
                                                },
                                            ]
                                        } 
                                        form={form}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-1-cus">
                                <div className="form-group">
                                    <Select
                                        name="AppraisalTime"
                                        label="Thời gian thẩm định"
                                        options={
                                            [
                                                {
                                                    id: 0,
                                                    value: "0",
                                                    label: "Chọn thời gian thẩm định",
                                                },
                                                {
                                                    id: 1,
                                                    value: "1",
                                                    label: "10 năm",
                                                },
                                                {
                                                    id: 2,
                                                    value: "2",
                                                    label: "20 năm",
                                                },
                                            ]
                                        } 
                                        form={form}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-1-cus">
                                <div className="form-group">
                                    <Select
                                        name="LoanInterestSupport"
                                        label="Hỗ trợ lãi suất vay"
                                        options={
                                            [
                                                {
                                                    id: 0,
                                                    value: "0",
                                                    label: "Chọn hỗ trợ lãi suất vay",
                                                },
                                                {
                                                    id: 1,
                                                    value: "1",
                                                    label: "10%",
                                                },
                                                {
                                                    id: 2,
                                                    value: "2",
                                                    label: "20%",
                                                },
                                            ]
                                        } 
                                        form={form}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-2-cus">
                                <div className="form-group frm-btnwrap">
                                    <div className="frm-btn">
                                        <input className="btn btn-default frm-btn-submit" type="submit" value="Lọc"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form> */}
                <LoanOfferBankTableComponent
                    zoneUrl={zoneUrl}
                    cateId={cateId}
                    loanPeriodId={loanPeriodId}
                    rateOfferId={rateOfferId}
                    rateAfterIncentivesId={rateAfterIncentivesId}
                    appraisalTime={appraisalTime}
                />
            </div>
        </>
    );
}
export default LoanDetailComponent;
