import React, { Component } from 'react';

import InputField from '../../../../form-control/InputField';

import DepositTermsComponent from '../../../../popup/FormPopup/DepositTerms';
import TermsAndConditionComponent from '../../../../popup/FormPopup/TermsAndCondition';
import RequestCounsellingFormComponent from '../../../../popup/FormPopup/RequestCounselling';

class FormStep1Right extends Component {
    render() {
        const { form } = this.props;
        return (
            <div className="box-products">
                <div className="wrap-form">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group form-title">
                                <h2 className="title fz-14 fw-600 text-main text-up">Thông tin khách hàng</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField name="FullName" label="Họ tên" defaultValue="" form={form} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField name="CCCD" label="CCCD/CMTND" defaultValue="" form={form} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="PhoneNumber"
                                    label="Số điện thoại"
                                    defaultValue=""
                                    form={form}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField name="Email" label="Email" defaultValue="" form={form} required />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-title">
                                <h2 className="title fz-14 fw-600 text-main text-up">Tư vấn bán hàng</h2>
                            </div>
                        </div>
                        <div className="col-md-6 hidden">
                            <div className="form-group">
                                <InputField
                                    name="EmployeeCode"
                                    label="Mã nhân viên/họ tên tư vấn bán hàng"
                                    defaultValue=""
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="EmployeePhoneNumber"
                                    label="Số điện thoại nhân viên tư vấn bán hàng"
                                    defaultValue=""
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-checkbox">
                                <input type="checkbox" id="accept" name="AcceptPolicy" />
                                <label htmlFor="accept">
                                    Tôi đã đọc và xác nhận đồng ý với toàn bộ nội dung “ <DepositTermsComponent /> “ và
                                    ” <TermsAndConditionComponent />“ tại thời điểm đặt mua sản phẩm
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group frm-btnwrap">
                                <div className="frm-btn button">
                                    <RequestCounsellingFormComponent buttonText={'Tư vấn'} />
                                    <button className="btn btn-default frm-btn-submit" type="submit">
                                        Đặt hàng ngay
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormStep1Right;
