import React, { useState } from 'react';
import { encode as base64_encode } from 'base-64';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import './styles.sass';

import InputField from '../../../form-control/InputField';
import loginUser from '../../../../actions/login';

export default function LoginForm({ setToken }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();

    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });
    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await loginUser({
            email,
            password,
        });
        if (result.success) {
            setToken({
                token: result.accessToken,
            });
            // history.push('/admin/order-list');
        } else setError('Đăng nhập thất bại. Vui lòng thử lại.');
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="wrap-form">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputField
                                    name="email"
                                    type="text"
                                    label="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <InputField
                                    name="password"
                                    type="password"
                                    label="Mật khẩu"
                                    onChange={(e) => setPassword(base64_encode(e.target.value))}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-7">
                            {/* <div className="form-group frm-captcha">
                                <img src="http://html.canhcam.asia/tcmotor/img/capcha.png" alt=""/>
                            </div> */}
                        </div>
                        <div className="col-md-5">
                            <div className="form-group frm-btnwrap">
                                <div className="frm-btn">
                                    <input className="btn btn-default frm-btn-submit" type="submit" value="Gửi" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="error">{error}</div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

LoginForm.propTypes = {
    setToken: PropTypes.func.isRequired,
};
