import React, { Component } from 'react';
import { connect } from 'react-redux';
import userInfo from '../../../../actions/userInfo';
import axios from 'axios';
import Get from '../../../../utils/APIsHelper';
import AlertMessage from '../../../alert/index';

import { actFetchProductsRequest, actFetchProductPriceRequest } from '../../../../actions/index';

import './styles.sass';

// form
import InputField from '../../../form-control/InputField';
import Select from '../../../form-control/SelectField';

// import LoanInfoPopup from "../../../popup/ContentPopup/Loan";

function validatePhone(phoneNumber) {
    var phoneNumberPattern = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
    return phoneNumberPattern.test(phoneNumber);
}

function validateEmail(email) {
	// eslint-disable-next-line
    var emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailPattern.test(email);
}

function validateCardNumber(cardNumber) {
    var cardNumberPattern = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
    var length = cardNumber.length;
    if (length === 9 || length === 12) return cardNumberPattern.test(cardNumber);
    return false;
}

function hasSpecialChar(content) {
    //eslint-disable-next-line
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(content);
}

function convertToDate(dateString) {
    let d = dateString.split('/');
    let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return dat;
}

function formatDate($date) {
    let date = convertToDate($date);
    let result = '';
    if (date.getDate() < 10) result += '0' + date.getDate();
    else result += date.getDate();
    result += '/';
    let month = date.getMonth() + 1;
    if (month < 10) result += '0' + month;
    else result += month;
    result += '/';
    result += date.getFullYear();
    return result;
}

function formatLoanAmount(value) {
    return parseFloat(value.replaceAll('.', '').replaceAll('đ', ''));
}

async function GetOptions(id, parentId) {
    let options = [];
    let link = process.env.REACT_APP_API_ENDPOINT + '/api/CustomFiled/Get?id=' + id;
    if (parentId !== undefined)
        link = process.env.REACT_APP_API_ENDPOINT + '/api/CustomFiled/Get?id=' + id + '&parentId=' + parentId;
    let result = await axios.get(link);
    if (result.data.success) {
        result.data.data.forEach((element) => {
            options.push({
                id: element.Id,
                value: element.Value,
                label: element.Name,
            });
        });
    }
    return options;
}
function GetOptions2(id, parentId) {
    let options = [];
    let link = 'api/CustomFiled/Get?id=' + id;
    if (parentId !== undefined) link = 'api/CustomFiled/Get?id=' + id + '&parentId=' + parentId;

    Get(link, 'get', null).then((res) => {
        if (res.data.success) {
            res.data.data.forEach((element) => {
                options.push({
                    id: element.Id,
                    value: element.Value,
                    label: element.Name,
                });
            });
        }
    });
    return options;
}
function calculateYear(date) {
    var ageDifMs = Date.now() - date;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.round(ageDate.getUTCFullYear() - 1970);
}
class LoanForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertType: '',
            alertMessage: '',
            fullName: '',
            province: '',
            price: '',
            oldPrice: '472.100.000 đ',
            suggestLoanPrice: '401285000',
            productIdSelected: '61246075a536cae0578722f1', // 61246075a536cae0578722f1
            storeIdSelected: '6124a50e674bd75cfdf44c31',
            colorIdSelected: '611f32278e02d170461b36e6', // 611f32278e02d170461b36e6
            colorTitleSelected: '',
            modelTypeIdSelected: '61246153a536cae05787257d', //61246153a536cae05787257d
            placeIssued: [],
            provinces: [],
            districts: [],
            wards: [],
            geoZoneKey: '',
        };
    }

    async componentDidMount(): Promise<void> {
        this.setState({ geoZoneKey: process.env.REACT_APP_GEOZONE_KEY });
        this.props.fetchProducts();
        const result = await userInfo();
        if (result.success) {
            let name = result.data.fullName;
            if (name === '') name = result.data.email;
            this.setState({ fullName: name });
        }
        let provinces = await GetOptions(this.state.geoZoneKey, '-1');
        let districts = [];
        let wards = [];
        if (provinces.length > 0) {
            districts = await GetOptions(this.state.geoZoneKey, provinces[0].value);
            if (districts.length > 0) wards = await GetOptions(this.state.geoZoneKey, districts[0].value);
        }
        let placeIssued = await GetOptions('61ee101be0480b6de259addd');
        // Sleep for 10 seconds
        await new Promise((resolve) => {
            setTimeout(resolve, 500);
        });
        // This line of code will be executed after 0.5 seconds
        this.setState({ placeIssued, provinces, districts, wards });
        // console.clear();
        return Promise.resolve();
    }

    onChangeRelationshipEnable = (e) => {
        let val = document.querySelector('input[name="RelationshipEnable"]:checked').value;
        var elements = document.getElementsByClassName('relation-ship');
        Array.from(elements).forEach(function (element, index, array) {
            if (parseInt(val) === 1) element.style.display = 'block';
            else element.style.display = 'none';
        });
    };

    handleProductChange = (event) => {
        this.setState({ productIdSelected: event.target.value });
        var { products } = this.props;
        products.forEach((element) => {
            if (element.id === event.target.value) {
                if (element.modelTypes.length > 0) this.setState({ modelTypeIdSelected: element.modelTypes[0].id });
                if (element.colors.length > 0) this.setState({ colorIdSelected: element.colors[0].id });
            }
        });
        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleAttributeChange = (event) => {
        if (event.target.name === 'ProductModelId') this.setState({ modelTypeIdSelected: event.target.value });
        else if (event.target.name === 'ProductColorId') this.setState({ colorIdSelected: event.target.value });

        setTimeout(
            function () {
                this.handleFetchPrice();
            }.bind(this),
            200
        );
    };

    handleFetchPrice = () => {
        this.props.fetchProductPrice(
            this.state.productIdSelected,
            this.state.storeIdSelected,
            this.state.colorIdSelected,
            this.state.modelTypeIdSelected
        );
        setTimeout(
            function () {
                let { productPrice } = this.props;
                let priceValue = '';
                let oldPriceValue = '';
                let suggestLoanPriceValue = 0;
                if (productPrice !== null) {
                    priceValue = productPrice.price;
                    oldPriceValue = productPrice.oldPrice;
                    suggestLoanPriceValue = (
                        (parseFloat(oldPriceValue.replaceAll('.', '').replaceAll('đ', '')) * 85) /
                        100
                    ).toString();
                }
                this.setState({
                    price: priceValue,
                    oldPrice: oldPriceValue,
                    suggestLoanPrice: suggestLoanPriceValue,
                });
            }.bind(this),
            500
        );
    };

    onChangeProvince = (e) => {
        let province = e.target.value;
        let districts = GetOptions2(this.state.geoZoneKey, province);
        setTimeout(
            function () {
                this.setState({ districts });
                let wards = GetOptions2(this.state.geoZoneKey, districts[0].value);
                setTimeout(
                    function () {
                        this.setState({ wards });
                    }.bind(this),
                    500
                );
            }.bind(this),
            500
        );
    };

    onChangeDictrist = (e) => {
        let district = e.target.value;
        let wards = GetOptions2(this.state.geoZoneKey, district);
        setTimeout(
            function () {
                this.setState({ wards });
            }.bind(this),
            500
        );
    };

    save = (e) => {
        e.preventDefault();
        // console.clear();
        const formData = new FormData(e.currentTarget);
        var contract = {};
        formData.forEach(function (value, key) {
            contract[key] = value;
        });

        const loanAmount = parseFloat(contract.LoanAmount.replaceAll('.', '').replaceAll('đ', ''));

        if (loanAmount < 50000000) {
            alert('Số tiền vay tối thiểu là 50tr');
            e.target.LoanAmount.focus();
            return;
        }
        if (loanAmount > 2000000000) {
            alert('Số tiền vay tối đa là 2 tỷ');
            e.target.LoanAmount.focus();
            return;
        }

        const loanTerm = parseFloat(contract.LoanTerm.replaceAll('.', '').replaceAll('đ', ''));

        if (loanTerm < 48) {
            alert('Thời hạn vay tối thiểu từ 48 tháng');
            e.target.LoanTerm.focus();
            return;
        }

        if (loanTerm > 96) {
            alert('Thời hạn vay tối đa từ 96 tháng');
            e.target.LoanTerm.focus();
            return;
        }

        if (contract.FullName === '') {
            alert('Vui lòng điền Họ và tên!');
            e.target.FullName.focus();
            return;
        }

        if (hasSpecialChar(contract.FullName)) {
            alert('Họ và tên không hợp lệ!');
            e.target.FullName.focus();
            return;
        }

        let dob = convertToDate(contract.DateOfBirth);

        if (contract.DateOfBirth === '' || calculateYear(dob) < 18) {
            alert('Vui lòng chọn ngày sinh tối thiểu là 18 tuổi!');
            e.target.DateOfBirth.focus();
            return;
        }

        if (contract.IdentityCardNumber === '') {
            alert('Vui lòng điền CMND/CCCD!');
            e.target.IdentityCardNumber.focus();
            return;
        }

        // if (!validatePhone(contract.IdentityCardNumber)) {
        //     alert('CMND/CCCD không hợp lệ!');
        //     e.target.IdentityCardNumber.focus();
        //     return;
        // }

        if (!validateCardNumber(contract.IdentityCardNumber)) {
            alert('CMND/CCCD không hợp lệ!');
            e.target.IdentityCardNumber.focus();
            return;
        }

        let doCard = convertToDate(contract.IdentityCardDate);

        if (contract.IdentityCardDate === '' || calculateYear(doCard) < 0 || calculateYear(doCard) > 15) {
            alert('Vui lòng chọn ngày cấp CMND/CCCD tối đa là 15 năm!');
            e.target.IdentityCardDate.focus();
            return;
        }

        if (contract.Address === '') {
            alert('Vui lòng điền địa chỉ!');
            e.target.Address.focus();
            return;
        }

        if (hasSpecialChar(contract.Address)) {
            alert('Địa chỉ không hợp lệ!');
            e.target.Address.focus();
            return;
        }

        if (contract.PhoneNumber === '') {
            alert('Vui lòng điền số điện thoại di động!');
            e.target.PhoneNumber.focus();
            return;
        }

        if (contract.Email === '') {
            alert('Vui lòng điền email!');
            e.target.Email.focus();
            return;
        }

        if (!validatePhone(contract.PhoneNumber)) {
            alert('Số điện thoại di động không hợp lệ!');
            e.target.PhoneNumber.focus();
            return;
        }

        if (!validateEmail(contract.Email)) {
            alert('Email không hợp lệ!');
            e.target.Email.focus();
            return;
        }

        let relationshipEnable = document.querySelector('input[name="RelationshipEnable"]:checked').value;
        if (parseInt(relationshipEnable) === 0) {
            contract.DateOfBirthRelationship = '';
            contract.GenderRelationship = '';
            contract.IdentityCardAddressRelationship = '';
            contract.IdentityCardNumberRelationship = '';
            contract.IdentityCardDateRelationship = '';
            contract.MaritalStatusRelationship = '';
        }
        if (parseInt(relationshipEnable) === 1) {
            if (contract.FullNameRelationship === '') {
                alert('Vui lòng điền Họ và tên!');
                e.target.FullNameRelationship.focus();
                return;
            }
            if (hasSpecialChar(contract.FullNameRelationship)) {
                alert('Họ và tên không hợp lệ!');
                e.target.FullNameRelationship.focus();
                return;
            }
            dob = convertToDate(contract.DateOfBirthRelationship);
            if (contract.DateOfBirthRelationship === '' || calculateYear(dob) < 18) {
                alert('Vui lòng chọn ngày sinh tối thiểu là 18 tuổi!');
                e.target.DateOfBirthRelationship.focus();
                return;
            }
            if (contract.IdentityCardNumberRelationship === '') {
                alert('Vui lòng điền CMND/CCCD!');
                e.target.IdentityCardNumberRelationship.focus();
                return;
            }
            if (!validateCardNumber(contract.IdentityCardNumberRelationship)) {
                alert('CMND/CCCD không hợp lệ!');
                e.target.IdentityCardNumberRelationship.focus();
                return;
            }
            if (contract.IdentityCardNumberRelationship === contract.IdentityCardNumber) {
                alert('CMND/CCCD không được trùng với thông tin người vay!');
                e.target.IdentityCardNumberRelationship.focus();
                return;
            }
            doCard = convertToDate(contract.IdentityCardDateRelationship);
            if (
                contract.IdentityCardDateRelationship === '' ||
                calculateYear(doCard) < 0 ||
                calculateYear(doCard) > 15
            ) {
                alert('Vui lòng chọn ngày cấp CMND/CCCD tối đa là 15 năm!');
                e.target.IdentityCardDateRelationship.focus();
                return;
            }
            contract.DateOfBirthRelationship = formatDate(contract.DateOfBirthRelationship);
            contract.IdentityCardDateRelationship = formatDate(contract.IdentityCardDateRelationship);
        }

        contract.DateOfBirth = formatDate(contract.DateOfBirth);
        contract.IdentityCardDate = formatDate(contract.IdentityCardDate);
        contract.LoanAmount = formatLoanAmount(contract.LoanAmount);

        Get('api/order/SaveLoanNonOrder', 'post', contract).then((res) => {
            this.setState({
                alertMessage: 'Gửi yêu cầu Thẩm định vay nhanh thành công.',
                alertType: 'alert alert-success',
            });
            document.getElementById('box-loan-form').style.display = 'none';
        });
    };

    render() {
        var { form, products } = this.props;
        const { provinces, districts, wards, placeIssued } = this.state;
        var colorList = [];
        var models = [];
        var productSelectItems = [];
        var productId = this.state.productIdSelected;
        // var storeId = this.state.storeIdSelected;
        var colorId = this.state.colorIdSelected;
        var modelTypeId = this.state.modelTypeIdSelected;
        // var oldPrice = this.state.oldPrice;
        products.forEach((element) => {
            if (productId === '') productId = element.id;

            productSelectItems.push({
                id: element.id,
                value: element.id,
                label: element.title,
            });

            if (models.length === 0 || element.id === productId) {
                models = [];
                element.modelTypes.forEach((modelType) => {
                    if (modelTypeId === '') modelTypeId = modelType.id;
                    models.push({
                        id: modelType.id,
                        value: modelType.id,
                        label: modelType.name,
                    });
                });
            }

            if (colorList.length === 0 || element.id === productId) {
                colorList = [];

                element.colors.forEach((color) => {
                    if (colorId === '') colorId = color.id;
                    colorList.push({
                        id: color.id,
                        value: color.id,
                        label: color.name,
                    });
                });
            }
        });
        return (
            <div className="box-admin box-loan">
                <h1 className="heading-title fz-24 text-white fw-700">Thẩm định vay nhanh</h1>
                <AlertMessage message={this.state.alertMessage} type={this.state.alertType} />
                <form method="post" onSubmit={this.save} id="box-loan-form">
                    <div className="wrap-form">
                        <h2 className="box-form-title fz-16 fw-700 text-main text-up">
                            <span>Thông tin người vay</span>
                        </h2>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="ProductId"
                                        label="Loại xe"
                                        options={productSelectItems}
                                        form={form}
                                        handleChange={this.handleProductChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="ProductModelId"
                                        label="Phiên bản"
                                        options={models}
                                        form={form}
                                        handleChange={this.handleAttributeChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="ProductColorId"
                                        label="Màu sắc"
                                        options={colorList}
                                        form={form}
                                        handleChange={this.handleAttributeChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="TemporaryCarPrice"
                                        type="text"
                                        label="Giá xe tạm tính (VNĐ)"
                                        value={this.state.oldPrice}
                                        form={form}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="LoanAmount"
                                        type="text"
                                        label="Số tiền đề xuất vay vốn (VNĐ)"
                                        value={String(this.state.suggestLoanPrice)}
                                        form={form}
                                        isCurrency
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="LoanTerm"
                                        type="text"
                                        label="Thời gian vay (tháng)"
                                        defaultValue="96"
                                        form={form}
                                        // onChange={e => this.setState({ endDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="FullName"
                                        type="text"
                                        label="Họ tên"
                                        value={this.state.fullName}
                                        form={form}
                                        onChange={(e) => this.setState({ fullName: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="DateOfBirth"
                                        type="date"
                                        label="Ngày sinh"
                                        defaultValue=""
                                        form={form}
                                        // onChange={e => this.setState({ endDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="" className="loan-label">
                                    Giới tính
                                </label>
                                <div className="radio-wrap">
                                    <div className="form-group form-radio">
                                        <input
                                            type="radio"
                                            name="Gender"
                                            defaultChecked="checked"
                                            id="sex-1"
                                            value="M"
                                        />
                                        <label htmlFor="sex-1">Nam</label>
                                    </div>
                                    <div className="form-group form-radio">
                                        <input type="radio" name="Gender" id="sex-2" value="F" />
                                        <label htmlFor="sex-2">Nữ</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="PhoneNumber"
                                        type="text"
                                        label="Số điện thoại di động"
                                        defaultValue=""
                                        form={form}
                                        // onChange={e => this.setState({ endDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="Email"
                                        type="text"
                                        label="Email"
                                        defaultValue=""
                                        form={form}
                                        // onChange={e => this.setState({ endDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="IdentityCardNumber"
                                        type="text"
                                        label="CMND/CCCD"
                                        defaultValue=""
                                        form={form}
                                        // onChange={e => this.setState({ endDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="IdentityCardDate"
                                        type="date"
                                        label="Ngày cấp"
                                        defaultValue=""
                                        form={form}
                                        // onChange={e => this.setState({ endDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="IdentityCardAddress"
                                        label="Nơi cấp"
                                        options={placeIssued}
                                        form={form}
                                        // handleChange={onChangeModelType}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="AddressProvince"
                                        label="Tỉnh/ Thành phố"
                                        options={provinces}
                                        form={form}
                                        handleChange={this.onChangeProvince}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="AddressDistrict"
                                        label="Quận/ Huyện"
                                        options={districts}
                                        form={form}
                                        handleChange={this.onChangeDictrist}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        name="AddressWard"
                                        label="Phường/ Xã"
                                        options={wards}
                                        form={form}
                                        // handleChange={onChangeModelType}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputField
                                        name="Address"
                                        type="text"
                                        label="Địa chỉ hiện tại"
                                        defaultValue=""
                                        form={form}
                                        // onChange={e => this.setState({ endDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="" className="loan-label">
                                    Thông tin người đồng vay
                                </label>
                                <div className="radio-wrap">
                                    <div className="form-group form-radio">
                                        <input
                                            type="radio"
                                            name="RelationshipEnable"
                                            defaultChecked="checked"
                                            id="RelationshipEnable-1"
                                            value="1"
                                            onChange={this.onChangeRelationshipEnable}
                                        />
                                        <label htmlFor="RelationshipEnable-1">Có</label>
                                    </div>
                                    <div className="form-group form-radio">
                                        <input
                                            type="radio"
                                            name="RelationshipEnable"
                                            id="RelationshipEnable-2"
                                            value="0"
                                            onChange={this.onChangeRelationshipEnable}
                                        />
                                        <label htmlFor="RelationshipEnable-2">Không</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relation-ship">
                            <h2 className="box-form-title fz-16 fw-700 text-main text-up ">
                                <span>Thông tin Người đồng vay</span>
                            </h2>
                            <div className="row ">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="Relationship"
                                            label="Mối quan hệ với người vay chính"
                                            options={[
                                                {
                                                    id: 1,
                                                    value: 'C',
                                                    label: 'Đồng nghiệp',
                                                },
                                                {
                                                    id: 2,
                                                    value: 'FR',
                                                    label: 'Bạn bè',
                                                },
                                                {
                                                    id: 3,
                                                    value: 'R',
                                                    label: 'Họ hàng',
                                                },
                                                {
                                                    id: 4,
                                                    value: 'P',
                                                    label: 'Bố mẹ',
                                                },
                                                {
                                                    id: 5,
                                                    value: 'SB',
                                                    label: 'Anh chị em',
                                                },
                                                {
                                                    id: 6,
                                                    value: 'SD',
                                                    label: 'Con trai con gái',
                                                },
                                                {
                                                    id: 7,
                                                    value: 'S',
                                                    label: 'Vợ chồng',
                                                },
                                            ]}
                                            form={form}
                                            // handleChange={onProductChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="FullNameRelationship"
                                            label="Họ và tên"
                                            defaultValue=""
                                            form={form}
                                            // onChange={e => this.setState({ endDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="DateOfBirthRelationship"
                                            type="date"
                                            label="Ngày sinh"
                                            defaultValue=""
                                            form={form}
                                            // onChange={e => this.setState({ endDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="" className="loan-label">
                                        Giới tính
                                    </label>
                                    <div className="radio-wrap">
                                        <div className="form-group form-radio">
                                            <input
                                                type="radio"
                                                name="GenderRelationship"
                                                defaultChecked="checked"
                                                id="sexRelationship-1"
                                                value="M"
                                            />
                                            <label htmlFor="sexRelationship-1">Nam</label>
                                        </div>
                                        <div className="form-group form-radio">
                                            <input
                                                type="radio"
                                                name="GenderRelationship"
                                                id="sexRelationship-2"
                                                value="F"
                                            />
                                            <label htmlFor="sexRelationship-2">Nữ</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="IdentityCardNumberRelationship"
                                            type="text"
                                            label="CMND/CCCD"
                                            defaultValue=""
                                            form={form}
                                            // onChange={e => this.setState({ endDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField
                                            name="IdentityCardDateRelationship"
                                            type="date"
                                            label="Ngày cấp"
                                            defaultValue=""
                                            form={form}
                                            // onChange={e => this.setState({ endDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="IdentityCardAddressRelationship"
                                            label="Nơi cấp"
                                            options={placeIssued}
                                            form={form}
                                            // handleChange={onChangeModelType}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Select
                                            name="MaritalStatusRelationship"
                                            label="Tình trạng hôn nhân"
                                            options={[
                                                {
                                                    id: 1,
                                                    value: 'SINGLE',
                                                    label: 'Độc thân',
                                                },
                                                {
                                                    id: 2,
                                                    value: 'MARRIED',
                                                    label: 'Đã có gia đình',
                                                },
                                                {
                                                    id: 3,
                                                    value: 'DIVORCED',
                                                    label: 'Đã ly hôn',
                                                },
                                            ]}
                                            form={form}
                                            // handleChange={onChangeModelType}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group frm-btnwrap">
                                    {/* <div className="button form-download">
										<LoanInfoPopup></LoanInfoPopup>
									</div> */}
                                    <div className="frm-btn">
                                        <input
                                            className="btn btn-default frm-btn-submit"
                                            type="submit"
                                            value="Gửi đề nghị"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        stores: state.stores,
        products: state.products,
        productPrice: state.productPrice,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchProducts: () => {
            dispatch(actFetchProductsRequest('Home', ''));
        },
        fetchProductPrice: (productId, storeId, colorId, modelTypeId) => {
            dispatch(actFetchProductPriceRequest(productId, storeId, colorId, modelTypeId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanForm);
