import React from 'react';
import Login from '../../../components/customer/Login/index';

import CustomerNavLink from '../../../components/customer/Menu';
import LoanRegistrationListComponent from '../../../components/customer/LoanRegistrationList';

import useToken from '../../../actions/useToken-customer';

function CustomerLoanRegistrationListPage(props) {
    const { token, setToken } = useToken();
    if (!token) {
        return <Login setToken={setToken} />;
    }
    return (
        <section className="admin-section customer-section section bg-login bdbt-gray">
            <div className="container">
                <div className="row row-space">
                    <div className="col-lg-4 col-xl-3">
                        <CustomerNavLink />
                    </div>
                    <div className="col-lg-8 col-xl-9">
                        <LoanRegistrationListComponent />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CustomerLoanRegistrationListPage;
