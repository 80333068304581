import React from 'react';

import BannerChildList from './BannerChildList';

function BannerChildComponent(props) {
    const bannerChildList= {
        id: 1,
        title: 'Banner Child',
        imageUrl: props.imageBannerUrl,
    }
    return (
        <section className="banner-child"> 
            <BannerChildList bannerChildList={bannerChildList}/>
        </section>
    );
}

export default BannerChildComponent;