import React, { Component } from 'react';

import LoanDetailComponent from "../../components/loan-detail";

class NormalLoanPage extends Component {
    render() {
        var {zoneId, cateId, zoneUrl} = this.props;
		return (
			<section className="normal-loan-section section bg-login bdbt-gray">
				<div className="container">
					<LoanDetailComponent zoneId={zoneId} cateId={cateId} zoneUrl={zoneUrl}/>
				</div>
			</section>
		);
	}
}

export default NormalLoanPage;