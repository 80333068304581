import React from 'react';

function TableLoan({ item }) {
    return (
        <>
            <div className="table table-1 table-loan-info">
                <table>
                    <thead>
                        <tr>
                            <th colSpan={2}>Thông tin người vay</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Loại xe</td>
                            <td>{item.ProductName}</td>
                        </tr>
                        <tr>
                            <td>Phiên bản xe</td>
                            <td>{item.ProductModelName}</td>
                        </tr>
                        {item.PriceUnit ? (
                            <tr>
                                <td>Giá xe tạm tính</td>
                                <td>{item.PriceUnit}</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        <tr>
                            <td>Số tiền đề xuất vay vốn</td>
                            <td>{item.LoanAmount}</td>
                        </tr>
                        <tr>
                            <td>Thời gian vay (tháng)</td>
                            <td>{item.LoanTerm} Tháng</td>
                        </tr>
                        <tr>
                            <td>Họ và tên</td>
                            <td>{item.FullName}</td>
                        </tr>
                        {item.DateOfBirth ? (
                            <tr>
                                <td>Ngày sinh</td>
                                <td>{item.DateOfBirth}</td>
                            </tr>
                        ) : (
                            <></>
                        )}
                        <tr>
                            <td>Giới tính</td>
                            <td>{item.Gender === 'M' ? 'Nam' : 'Nữ'}</td>
                        </tr>
                        <tr>
                            <td>CMND/ CCCD</td>
                            <td>{item.IdentityCardNumber}</td>
                        </tr>
                        <tr>
                            <td>Ngày cấp</td>
                            <td>{item.IdentityCardDate}</td>
                        </tr>
                        <tr>
                            <td>Nơi cấp</td>
                            <td>{item.IdentityCardAddress}</td>
                        </tr>
                        <tr>
                            <td>Tỉnh/ Thành phố</td>
                            <td>{item.AddressProvince}</td>
                        </tr>
                        <tr>
                            <td>Quận/ Huyện</td>
                            <td>{item.AddressDistrict}</td>
                        </tr>
                        <tr>
                            <td>Địa chỉ hiện tại</td>
                            <td>{item.Address}</td>
                        </tr>
                        <tr>
                            <td>Số điện thoại di động</td>
                            <td>{item.PhoneNumber}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TableLoan;
