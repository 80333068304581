var initialState = [];

const districts = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_DISTRICTS": 
            return action.districts;  
        default: return state;
    }  
}; 
 
export default districts;