import React from "react"; 
import PropTypes from "prop-types";

AlertMessage.propTypes = {
	message: PropTypes.string.isRequired, 
	type: PropTypes.string.isRequired
};

function AlertMessage(props) {
	const { message, type } = props;
    if(message === '')
        return <></>;
    return (
		<div className={type}> 
            <p>{message}</p>
            <a className="btn btn-close-alert" href="#!">x</a>
        </div>
	);
}

export default AlertMessage;
 