import React from 'react';

import RecoverPasswordComponent from '../../components/customer/RecoverPassword';

function RecoverPasswordPages(props) {
    return (
        <>
            <RecoverPasswordComponent />
        </>
    );
}

export default RecoverPasswordPages;