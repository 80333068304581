import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actFetchStoresRequest, actFetchProductsRequest } from '../../../../../actions/index';

import InputField from '../../../../form-control/InputField';
import Select from '../../../../form-control/SelectField';
import SelectProvince from '../../../../form-control/select-provinces/index';

class RequestCounsellingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province: '',
            price: '',
            oldPrice: '',
            productIdSelected: '',
            storeIdSelected: '',
            colorIdSelected: '',
            modelTypeIdSelected: '',
        };
    }   componentDidMount() {
        this.props.fetchStores(this.state.province);
        this.props.fetchProducts();
        var productId = localStorage.getItem('productId');
        setTimeout(
            function () {
                var { stores, products } = this.props;
                if (stores.length > 0) this.setState({ storeIdSelected: stores[0].id });
                if (products.length > 0) {
                    if (productId === null || productId === '') productId = products[0].id;

                    this.setState({ productIdSelected: productId });
                    products.forEach((element) => {
                        if (element.id === productId) {
                            if (element.colors.length > 0) this.setState({ colorIdSelected: element.colors[0].id });
                            if (element.modelTypes.length > 0)
                                this.setState({ modelTypeIdSelected: element.modelTypes[0].id });
                        }
                    });
                }
            }.bind(this),
            500
        );
    }

    handleProvinceChange = (event) => {
        this.setState({ province: event.target.value });
        this.props.fetchStores(event.target.value);
    };

    handleProductChange = (event) => {
        this.setState({ productIdSelected: event.target.value });
    };

    handleStoreChange = (event) => {
        this.setState({ storeIdSelected: event.target.value });
    };

    handleColorChange = (event) => {
        this.setState({ colorIdSelected: event.target.value });
    };

    handleModelTypeChange = (event) => {
        this.setState({ modelTypeIdSelected: event.target.value });
    };
    render() {
        var { form, stores, products } = this.props;
        var colorList = [];
        var models = [];
        var productSelectItems = [];
        var images = [];
        var productId = this.state.productIdSelected;
        var storeId = this.state.storeIdSelected;
        var colorId = this.state.colorIdSelected;
        var modelTypeId = this.state.modelTypeIdSelected;
        var storeList = [];
        var storePhoneNumber = ''; 
        var storePhoneNumberTelAttr = ''; 
        stores.forEach((element) => {
            if (storeId === '') 
                storeId = element.id;
            if(element.id === storeId)
                storePhoneNumber = element.phoneNumber;
            storeList.push({
                id: element.id,
                label: element.name,
                value: element.id,
            });
        });
        storePhoneNumberTelAttr= "tel: " + storePhoneNumber;
        products.forEach((element) => {
            if (productId === '') productId = element.id;

            productSelectItems.push({
                id: element.id,
                value: element.id,
                label: element.title,
            });

            if (models.length === 0 || element.id === productId) {
                models = [];
                element.modelTypes.forEach((modelType) => {
                    if (modelTypeId === '') modelTypeId = modelType.id;
                    models.push({
                        id: modelType.id,
                        value: modelType.id,
                        label: modelType.name,
                    });
                });
            }

            if (colorList.length === 0 || element.id === productId) {
                colorList = [];
                element.colors.forEach((color) => {
                    if (colorId === '') colorId = color.id;

                    colorList.push({
                        id: color.id,
                        value: color.id,
                        label: color.name,
                    });
                });
            }

            if (element.id === productId) {
                images = [];
                element.images.forEach((image) => {
                    if (image.colorId === colorId && image.displayOrder < 98) {
                        images.push({
                            id: image.id,
                            title: image.title,
                            imageUrl: image.imageUrl,
                        });
                    }
                });
            }
        });

        var productList = [];
        products.forEach((element) => {
            productList.push({
                id: element.id,
                label: element.title,
                value: element.id,
            });
        });       
        return (  
            <div className="wrap-form">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <SelectProvince form={form} handleChange={this.handleProvinceChange} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Select
                                name="Store"
                                label="Showroom/ Đại lý"
                                required
                                options={storeList}
                                handleChange={this.handleStoreChange}
                                form={form}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group"> 
                            <InputField name="FullName" type="text" label="Họ và tên" defaultValue="" form={form} required/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-hotline">
                            <label>Hotline</label> 
                            <a className="hotline" href={storePhoneNumberTelAttr}>{storePhoneNumber}</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <InputField name="Email" type="email" label="Email" defaultValue="" form={form} required/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group"> 
                            <InputField name="PhoneNumber" type="text" label="Số điện thoại" defaultValue="" form={form} required/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Select
                                name="Product"
                                label="Chọn xe"
                                required
                                options={productList}
                                handleChange={this.handleProductChange}
                                form={form}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group"> 
                            <Select
                                name="ModelType"
                                label="Model xe"
                                required
                                options={models}
                                handleChange={this.handleModelTypeChange}
                                form={form}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group"> 
                            <Select
                                name="ProductColor"
                                label="Màu sắc"
                                required
                                options={colorList}
                                handleChange={this.handleColorChange}
                                form={form}
                            />
                        </div>
                    </div>
                    <div className="col-md-7"> 
                        {/* <div className="form-group frm-captcha">
                            <img src="http://html.canhcam.asia/tcmotor/img/capcha.png" alt=""/>
                        </div> */}
                    </div>
                    <div className="col-md-5">
                        <div className="form-group frm-btnwrap">
                            <div className="frm-btn">
                                <input className="btn btn-default frm-btn-submit" type="submit" value="Gửi"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
} 

const mapStateToProps = (state) => {
    return {
        stores: state.stores,
        products: state.products,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchStores: (province) => {
            dispatch(actFetchStoresRequest(province));
        },
        fetchProducts: () => {
            dispatch(actFetchProductsRequest('Home','')); 
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestCounsellingForm);
