import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchLoanContractManageListRequest } from '../../../actions/index';

import LoanListPopup from '../../popup/ContentPopup/LoanList';

class BankerLoanListComponent extends Component {
    componentDidMount() {
        this.props.fetchLoanContractManages();
    }
    render() {
        var { contracts } = this.props;
        var items = [];
        let i = 0;
        contracts.forEach((element) => {
            i++;
            let item = element;
            let NewDateFormat =
                new Date(item.CreatedOn).getDate() +
                '/' +
                new Date(item.CreatedOn).getMonth() +
                '/' +
                new Date(item.CreatedOn).getFullYear() +
                ' ' +
                new Date(item.CreatedOn).getHours() +
                ':' +
                new Date(item.CreatedOn).getMinutes();
            item.number = i;
            item.newDateFormat = NewDateFormat;
            items.push(item);
        });
        return (
            <div className="box-admin box-loan box-load-list">
                <h1 className="heading-title fz-24 text-white fw-700">Danh sách thẩm định vay nhanh</h1>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Số đề nghị</th>
                                <th>Đăng ký vay (Ngân hàng/ Ngày)</th>
                                <th>Ngày, giờ</th>
                                <th>Tình trạng hồ sơ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr key={item.number}>
                                    <td className="id">
                                        <strong>
                                            {item.number < 10 ? '0' : ''}
                                            {item.number}
                                        </strong>
                                    </td>
                                    <td>
                                        <LoanListPopup item={item} />
                                    </td>
                                    <td>VP Bank</td>
                                    <td>{item.newDateFormat}</td>
                                    <td>{item.OrderStatus}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contracts: state.contracts,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchLoanContractManages: (body) => {
            dispatch(actFetchLoanContractManageListRequest(body));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankerLoanListComponent);
