import React from 'react';

import imagesBanner from '../../assets/images/child.png';

import BannerChildComponent from "../../components/banner/BannerChild";
import BreadcrumbComponent from "../../components/breadcrumb";
import FaqsComponent from "../../components/faqs";

function FaqsPages({cateId}) { 
    return (
        <>
            <BannerChildComponent imageBannerUrl={imagesBanner}/>
            <BreadcrumbComponent />
            <FaqsComponent  cateId={cateId}/> 
        </>
    );
}

export default FaqsPages;