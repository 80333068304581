import React from 'react';

import '../styles.sass';

// popup
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import InputField from '../../../form-control/InputField';

// count down
import Countdown from 'react-countdown';

// import facebookImage from '../../../../assets/images/facebook.png';
// import googleImage from '../../../../assets/images/google.png';

const Completionist = () => <span>Hết thời gian nhập mã xác thực, vui lòng Gửi lại mã xác thực mới!</span>;

const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
        // Render a complete state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <p>
                Mã xác thực (
                <span>
                    {minutes}:{seconds}
                </span>
                )
            </p>
        );
    }
};

const styles = (theme) => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiDialogContent);

function VerificationCodeFormComponent(props) {
    const { openDialog, handleClose, phoneNumber, onSubmit, onClickReSend, message } = props;
    // popup

    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });
    return (
        <>
            <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title-4" open={openDialog}>
                <div className="form-popup" id="verification-code">
                    <section className="verification-code-section">
                        <div className="box-form-login bg-white">
                            <DialogTitle id="customized-dialog-title-4" onClose={handleClose}>
                                <h3 className="main-title text-center">Mã xác thực</h3>
                            </DialogTitle>
                            <DialogContent>
                                <div className="desc text-center">
                                    <p>
                                        Vui lòng nhập mã xác thực đã gửi đến số điện thoại <span>{phoneNumber}</span>
                                    </p>
                                </div>
                                <form method="post" onSubmit={onSubmit}>
                                    <div className="wrap-form">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <InputField
                                                        name="OTPCode"
                                                        type="text"
                                                        label="Mã xác thực"
                                                        defaultValue=""
                                                        form={form}
                                                    />
                                                    <div className="error">{message}</div>
                                                </div>
                                                <div className="count-down text-center">
                                                    <Countdown date={Date.now() + 120000} renderer={renderer} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group frm-btnwrap">
                                                    <div className="frm-btn button">
                                                        <input
                                                            className="btn btn-default frm-btn-submit"
                                                            type="submit"
                                                            value="Xác thực"
                                                        />
                                                        <button className="btn btn-67" onClick={onClickReSend}>
                                                            <span>Gửi lại mã xác thực</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-6">
                                                <div className="form-group form-image">
                                                    <a href="/#" title="Xác thực với Facebook">
                                                        <img src={facebookImage} alt="facebook"></img>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group form-image">
                                                    <a href="/#" title="Xác thực với Google">
                                                        <img src={googleImage} alt="google"></img>
                                                    </a>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </DialogContent>
                        </div>
                    </section>
                </div>
            </Dialog>
        </>
    );
}

export default VerificationCodeFormComponent;
