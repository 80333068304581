import React, { Component } from 'react';

import ColorList from '../ColorList/index';
import Select from '../SelectField';
 
class ProductSelect extends Component { 
    render() {
        var { form, colorList, models, productSelectItems, onProductChange, onChangeColor,colorTitle, onChangeModelType,  } = this.props;
        return ( 
            <> 
                <div className="col-12">
                    <div className="form-group"> 
                        <Select
                            name="Product"
                            label="Chọn loại xe"
                            required
                            options={productSelectItems}  
                            form={form}
                            handleChange={onProductChange}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group"> 
                        <Select name="ModelType" label="Phiên bản" required options={models} form={form} handleChange={onChangeModelType}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <Select
                            name="Quantity"
                            label="Số lượng"
                            required
                            options={[
                                {
                                    id: 1,
                                    value: '1',
                                    label: '1',
                                },
                            ]}
                            form={form}
                        />
                    </div>
                </div>
                <div className="col-12"> 
                    <ColorList colorList={colorList} onChangeColor={onChangeColor} colorTitle={colorTitle} />  
                </div>
            </>
        );
    }
}

export default ProductSelect;
