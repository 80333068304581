import Get from '../utils/APIsHelper';
import GetVendors from '../utils/APIsHelper2';


//Cart
export function actSelectProduct(productId) {
	return {
		type: 'SELECT_PRODUCT',
		productId
	}
}

//Product
export const actFetchProductsRequest = (position, categoryId) => {
	return (dispatch) => {
		let url = 'api/Product/Get';
		let spec = '?';
		if (position !== '') {
			url += spec + 'position=' + position;
			spec = "&";
		}

		if (categoryId !== '') {
			url += spec + 'categoryId=' + categoryId;
			spec = "&";
		}
		return Get(url, 'GET', null).then(res => {
			dispatch(actFetchProducts(res.data));
		});
	}
}

export const actFetchProducts = (products) => {
	return {
		type: "FETCH_PRODUCTS",
		products
	}
}
//Product Price
export const actFetchProductPriceRequest = (productId, storeId, colorId, modelTypeId) => {
	return (dispatch) => {
		let url = 'api/Product/GetPrice';
		url += '?productId=' + productId;
		url += '&storeId=' + storeId;
		url += '&colorId=' + colorId;
		url += '&modelTypeId=' + modelTypeId;
		return Get(url, 'GET', null).then(res => {
			dispatch(actFetchProductPrice(res.data));
		});
	}
}

export const actFetchProductPrice = (productPrice) => {
	return {
		type: "FETCH_PRODUCTPRICE",
		productPrice
	}
}

//News
export const actFetchNewsItemsRequest = (position, categoryId) => {
	return (dispatch) => {
		let url = 'api/NewsItem/Get';
		let spec = '?';
		if (position !== '') {
			url += spec + 'position=' + position;
			spec = "&";
		}
		if (categoryId !== '') {
			url += spec + 'categoryId=' + categoryId;
			spec = "&";
		}
		return Get(url, 'GET', null).then(res => {
			dispatch(actFetchNewsItems(res.data));
		});
	}
}

export const actFetchNewsItems = (newsItems) => {
	return {
		type: "FETCH_NEWSITEMS",
		newsItems
	}
}

/// Banner
export const actFetchBannersRequest = () => {
	return (dispatch) => {
		return Get('api/Banner/Get?position=Home', 'GET', null).then(res => {
			dispatch(actFetchBanners(res.data));
		});
	}
}

export const actFetchBanners = (banners) => {
	return {
		type: "FETCH_BANNERS",
		banners
	}
}
/// Provinces
export const actFetchProvincesRequest = () => {
	return (dispatch) => {
		return Get('api/GeoZone/GetProvinces', 'GET', null).then(res => {
			dispatch(actFetchProvinces(res.data));
		});
	}
}

export const actFetchProvinces = (provinces) => {
	return {
		type: "FETCH_PROVINCES",
		provinces
	}
}
/// Districts
export const actFetchDistrictsRequest = (provinceId) => {
	return (dispatch) => {
		return Get('api/GeoZone/GetDistricts?parentId=' + provinceId, 'GET', null).then(res => {
			dispatch(actFetchDistricts(res.data));
		});
	}
}

export const actFetchDistricts = (districts) => {
	return {
		type: "FETCH_DISTRICTS",
		districts
	}
}
/// Stores 
export const actFetchStoresRequest = (province, filter, customerLat, customerLng) => {
	return (dispatch) => {
		let url = 'api/Store/Get';
		let spec = '?';
		if (province !== '') {
			url += spec + 'province=' + province;
			spec = "&";
		}
		if (filter === true) {
			url += spec + 'filter=true';
			spec = "&";
		}
		if (customerLat !== undefined) {
			url += spec + 'customerLat=' + customerLat;
			spec = "&";
		}
		if (customerLng !== undefined) {
			url += spec + 'customerLng=' + customerLng;
			spec = "&";
		}
		return Get(url, 'GET', null).then(res => {
			dispatch(actFetchStores(res.data));
		});
	}
}

export const actFetchStores = (stores) => {
	return {
		type: "FETCH_STORES",
		stores
	}
}



/// Category

export const actFetchCategoryRequest = (seName) => {
	return (dispatch) => {
		let url = 'api/Category/GetBySeName?seName=' + seName;
		return Get(url, 'GET', null).then(res => {
			dispatch(actFetchCategory(res.data));
		});
	}
}

export const actFetchCategory = (category) => {
	return {
		type: "FETCH_CATEGORY",
		category
	}
}
export const actFetchCategoriesRequest = (position, categoryId, showProduct) => {
	return (dispatch) => {
		let url = 'api/Category/Get';
		let spec = '?';
		if (position !== '') {
			url += spec + 'position=' + position;
			spec = "&";
		}
		if (categoryId !== '') {
			url += spec + 'categoryId=' + categoryId;
			spec = "&";
		}
		if (showProduct === true) {
			url += spec + 'showProduct=true';
			spec = "&";
		}
		return Get(url, 'GET', null).then(res => {
			dispatch(actFetchCategories(res.data));
		});
	}
}

export const actFetchCategories = (categories) => {
	return {
		type: "FETCH_CATEGORIES",
		categories
	}
}


/// Customer Purchase 
export const actFetchPurchaseListRequest = (body) => {
	return (dispatch) => {
		return Get('api/Order/GetOrder', 'Post', body).then(res => {
			dispatch(actFetchOrderList(res.data.data));
		});
	}
}

/// Customer Registration 
export const actFetchRegistrationListRequest = (body) => {
	return (dispatch) => {
		return Get('api/Order/GetLoanList', 'Post', body).then(res => {
			dispatch(actFetchOrderList(res.data.data));
		});
	}
}

/// Customer Registration - Vendors
export const actFetchRegistrationListManageRequest = (body) => {
	return (dispatch) => {
		return GetVendors('api/Order/GetLoanListManage?page=1', 'Post', body).then(res => {
			dispatch(actFetchOrderList(res.data.data));
		});
	}
}

/// Order 
export const actFetchOrderListRequest = (body) => {
	return (dispatch) => {
		return Get('api/Order/Get', 'Post', body).then(res => {
			dispatch(actFetchOrderList(res.data.data));
		});
	}
}
/// Request Counselling 
export const actFetchRequestCounsellingRequest = (body) => {
	return (dispatch) => {
		return Get('api/Order/GetRequestCounsellings', 'Post', body).then(res => {
			dispatch(actFetchOrderList(res.data.data));
		});
	}
}
export const actFetchOrderDetailRequest = (body) => {
	return (dispatch) => {
		return Get('api/Order/Detail', 'Post', body).then(res => {
			dispatch(actFetchOrderDetail(res.data));
		});
	}
}

export const actFetchOrderList = (orders) => {
	return {
		type: "FETCH_ORDER_LIST",
		orders
	}
}
export const actFetchOrderDetail = (orderDetail) => {
	return {
		type: "FETCH_ORDER_DETAIL",
		orderDetail
	}
}


///Banks

export const actFetchBankPromotionRequest = (cateId, loanPeriodId, rateOfferId, rateAfterIncentivesId, appraisalTime) => {
	return (dispatch) => {
		let url = 'api/Bank/GetPromotions';
		let spec = '?';
		if (cateId !== '') {
			url += spec + 'cateId=' + cateId;
			spec = "&";
		}
		if (loanPeriodId !== '') {
			url += spec + 'loanPeriodId=' + loanPeriodId;
			spec = "&";
		}
		if (rateOfferId !== '') {
			url += spec + 'rateOfferId=' + rateOfferId;
			spec = "&";
		}
		if (rateAfterIncentivesId !== '') {
			url += spec + 'rateAfterIncentivesId=' + rateAfterIncentivesId;
			spec = "&";
		}
		if (appraisalTime !== '') {
			url += spec + 'appraisalTime=' + appraisalTime;
			spec = "&";
		}

		return Get(url, 'GET', null).then(res => {
			dispatch(actFetchBankPromotion(res.data));
		});
	}
}
export const actFetchBankPromotion = (bankPromotions) => {
	return {
		type: "FETCH_BankPromotion",
		bankPromotions
	}
}

export const actFetchBankPromotionDetailRequest = (Id) => {
	return (dispatch) => {
		let url = 'api/Bank/GetPromotionDetail?id=' + Id;
		return Get(url, 'GET', null).then(res => {
			dispatch(actFetchBankPromotionDetail(res.data));
		});
	}
}
export const actFetchBankPromotionDetail = (promotionDetail) => {
	return {
		type: "FETCH_BankPromotionDetail",
		promotionDetail
	}
}

/// Loan Contract 
export const actFetchLoanContractListRequest = (body) => {
	return (dispatch) => {
		return Get('api/Order/GetLoanContract', 'Post', body).then(res => {
			dispatch(actFetchLoanContractList(res.data.data));
		});
	}
}
export const actFetchLoanContractList = (contracts) => {
	return {
		type: "FETCH_LoanContract_LIST",
		contracts
	}
}

/// Loan Contract Manage
export const actFetchLoanContractManageListRequest = (body) => {
	return (dispatch) => {
		return GetVendors('api/Order/GetLoanContractManage?page=1', 'Post', body).then(res => {
			dispatch(actFetchLoanContractManageList(res.data.data));
		});
	}
}

export const actFetchLoanContractManageList = (contracts) => {
	return {
		type: "FETCH_LoanContract_LIST",
		contracts
	}
}
/// Loan Contract 
export const actFetchCalculateLoanRequest = (amount, rate, month, dateTime, method, preferentialRatePerYear, preferentialMonth) => {
	return (dispatch) => {
		let url = 'api/Bank/CalculateLoan';
		url += "?amount=" + amount;
		url += "&rate=" + rate;
		url += "&month=" + month;
		url += "&dateTime=" + dateTime;
		url += "&method=" + method;
		url += "&preferentialRatePerYear=" + preferentialRatePerYear;
		url += "&preferentialMonth=" + preferentialMonth;
		return Get(url, 'Get', null).then(res => {
			dispatch(actFetchCalculateLoan(res.data));
		});
	}
}
export const actFetchCalculateLoan = (calculateLoan) => {
	return {
		type: "FETCH_CalculateLoan",
		calculateLoan
	}
}