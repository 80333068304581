import React from 'react';
import AdminNavLink from '../../../components/admin/Menu';
import Login from '../../../components/admin/Login/index';

import CRequestCounsellingComponent from '../../../components/admin/RequestCounselling';
import useToken from '../../../actions/useToken';


function AdminChangePasswordPages(props) {
	
    const { token, setToken } = useToken();
    if(!token) { 
      return <Login setToken={setToken} />
    }
    return (
        <>
          <section className="admin-section section bg-login bdbt-gray ">
            <div className="container"> 
                <div className="row row-space">
                    <div className="col-lg-4 col-xl-3"> 
                        <AdminNavLink/>
                    </div>
                    <div className="col-lg-8 col-xl-9"> 
						<CRequestCounsellingComponent />
                    </div> 
                </div>
            </div>
          </section>
        </>
    );
}

export default AdminChangePasswordPages;