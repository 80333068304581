import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Get from '../../../../utils/APIsHelper';

import Select from '../../../form-control/SelectField';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

TableOrder.propTypes = {
    orderTableList: PropTypes.array.isRequired,
};

function TableOrder({ orderTableList }) {
    // form
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    const onChange = (key) => {
        if (document.getElementById('ProcessingStatus' + key) != null) {
            let status = document.getElementById('ProcessingStatus' + key).value;
            let body = {
                key,
                status,
            };
            Get('api/order/UpdateOrder', 'post', body).then((res) => {
                if (res.data.success) {
                }
            });
        }
    };
    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Mã đơn hàng</th>
                        <th>Người đặt</th>
                        <th>Model</th>
                        <th>Tổng tiền</th>
                        <th>Trạng thái xử lý</th>
                        <th>Tình trạng thành toán</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {orderTableList.map((order) => (
                        <tr key={order.id}>
                            <td className="id">
                                {order.number < 10 ? '0' : ''}
                                {order.number}
                            </td>
                            <td>{order.code}</td>
                            <td>{order.user}</td>
                            <td>
                                <p>{order.title}</p>
                                <p>{order.verison}</p>
                                <p>{order.color}</p>
                            </td>
                            <td>{order.total}</td>
                            <td>
                                <Select
                                    name={'ProcessingStatus' + order.id}
                                    label="Trạng thái xử lý"
                                    required
                                    defaultValue={order.ProcessStatusId}
                                    options={[
                                        {
                                            id: 1,
                                            value: 0,
                                            label: 'Mới',
                                        },
                                        {
                                            id: 2,
                                            value: 10,
                                            label: 'Đã liên hệ - khách đã phản hồi',
                                        },
                                        {
                                            id: 3,
                                            value: 20,
                                            label: 'Đã liên hệ - khách không phản hồi',
                                        },
                                    ]}
                                    handleChange={(e) => onChange(order.id)}
                                    form={form}
                                />
                            </td>
                            <td>{order.paymentMethods}</td>
                            <td>
                                <Link to={order.url} className="btn btn-detail">
                                    {' '}
                                    Chi tiết{' '}
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TableOrder;
