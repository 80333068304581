import React from 'react';
import PropTypes from 'prop-types';

import './styles.sass';

FilterList.propTypes = {
    dealersList: PropTypes.array.isRequired,
};

function FilterList({dealersList, mapZoom}) {
    const displayMarkers = () => {
        return dealersList.map((store,index) => { 
            let lat = 0;
            let lng = 0;
            if(store.latitude !== '' && store.latitude !== null) 
                lat = parseFloat(store.latitude.replace(",", "."));
			// if(store.latitude === null)
			// 	lat = 0;
            if(store.longitude !== '' && store.longitude !== null)
                lng = parseFloat(store.longitude.replace(",", "."));
            // if(store.longitude === null)
			// 	lng = 0;
            if(lat !== 0 && lng !== 0)
                return (
                    <li
                        className={index === 0 ? 'clinic_list active' : 'clinic_list'}
                        id={`1_${store.id}`}
                        data-map={store.dataMap}
                        key={store.id}
                        onClick={() => mapZoom(lat, lng)}
                    >
                        <p className="name">{store.title}</p>
                        <div dangerouslySetInnerHTML={{ __html: store.description }}></div>
                    </li>
                );
            return <></>;
        })
    }
    return (
        <>
            <ul className="ls-none list type-none" id="list">
                {displayMarkers()}
            </ul>
        </>
    );
}

export default FilterList;