import React, { Component } from 'react';


import './styles.sass';

// import {CopyToClipboard} from 'react-copy-to-clipboard';

class SuccessCK extends Component {
    render() {
        var {orderDetail} = this.props; 
        return (
            <div className="box-finish text-center">
                <h1 className="finish-title fz-40 fw-300 text-up text-main">
                    Cám ơn quý khách đã đặt mua <span>{orderDetail.ProductName}</span>
                </h1>
                <div className="desc fz-15 text-67">
                    <p>
                        Hệ thống đã ghi nhận đơn hàng thành công mã đơn hàng của bạn là <span>{orderDetail.Code}</span>
                    </p>
                    <p>Quý khách vui lòng kiểm tra thông tin chi tiết tại Email đã đăng ký</p>
                </div>
                {/* <h2 className="main-title">
                    Quý khách vui lòng thanh toán
                </h2> */}
                {/* <h2 className="main-title">
                    Thông tin đặt hàng: 
                </h2> */}
                <div className="box-ck">
                    <p className="text-main">Thông tin đặt hàng</p>
                    <div className="row">
                        <div className="col-sm-5 col-lg-4">
                            <p className="text-black fz-15 fw-500">Họ Tên</p>
                        </div>
                        <div className="col-sm-7 col-lg-8">
                            <p className="text-black fz-15">{orderDetail.FullName}</p>
                        </div>
                        <div className="col-sm-5 col-lg-4">
                            <p className="text-black fz-15 fw-500">Email</p>
                        </div>
                        <div className="col-sm-7 col-lg-8">
                            <p className="text-black fz-15">{orderDetail.Email}</p>
                        </div>
                        <div className="col-sm-5 col-lg-4">
                            <p className="text-black fz-15 fw-500">Số điện thoại</p>
                        </div>
                        <div className="col-sm-7 col-lg-8">
                            <p className="text-black fz-15">{orderDetail.PhoneNumber}</p>
                        </div>
                        <div className="col-sm-5 col-lg-4">
                            <p className="text-black fz-15 fw-500">Đại lý</p>
                        </div>
                        <div className="col-sm-7 col-lg-8">
                            <p className="text-black fz-15">{orderDetail.StoreName}</p>
                        </div>
                        <div className="col-sm-5 col-lg-4">
                            <p className="text-black fz-15 fw-500">Dòng xe + phiên bản</p>
                        </div>
                        <div className="col-sm-7 col-lg-8">
                            <p className="text-black fz-15">{orderDetail.ProductName} - {orderDetail.ProductModelName}</p>
                        </div>
                        <div className="col-sm-5 col-lg-4">
                            <p className="text-black fz-15 fw-500">Màu sắc</p>
                        </div>
                        <div className="col-sm-7 col-lg-8">
                            <p className="text-black fz-15">{orderDetail.ProductColorName}</p>
                        </div>
                    </div>
                </div>
                {/* <div className="box-ck">
                    <p className="text-main">Thông tin chuyển khoản đặt cọc</p>
                    <div className="row">
                        <div className="col-sm-4 col-md-3 col-lg-3">
                            <p className="text-black fz-14 fw-500">Ngân hàng</p>
                        </div>
                        <div className="col-sm-8 col-md-9 col-lg-9">
                            <p className="text-black fz-14">{orderDetail.BankName}</p>
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-3">
                            <p className="text-black fz-14 fw-500">Số tài khoản</p>
                        </div>
                        <div className="col-sm-8 col-md-9 col-lg-9">
                            <p className="text-black fz-14 form-copied">
                                <input value={orderDetail.BankAccount} />
                                <CopyToClipboard text={orderDetail.BankAccount}>
                                    <button>
                                        <em 
                                            className="ri-file-copy-fill"
                                        >
                                        </em>
                                    </button>
                                </CopyToClipboard>
                            </p>
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-3">
                            <p className="text-black fz-14 fw-500">Tên tài khoản</p>
                        </div>
                        <div className="col-sm-8 col-md-9 col-lg-9">
                            <p className="text-black fz-14">{orderDetail.BankAccountName}</p>
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-3">
                            <p className="text-black fz-14 fw-500">Nội dung</p>
                        </div>
                        <div className="col-sm-8 col-md-9 col-lg-9">
                            <p className="text-black fz-14 form-copied">
                                <input   value={orderDetail.BankNote} />
                                <CopyToClipboard text={orderDetail.BankNote}>
                                    <button>
                                        <em 
                                            className="ri-file-copy-fill"
                                        >
                                        </em>
                                    </button>
                                </CopyToClipboard>
                            </p>
                        </div>
                    </div>
                </div> */}
                <div className="image">
                    <img src={orderDetail.ImageUrl} alt={orderDetail.ProductName} />
                </div>
                <div className="desc">
                    <p className="hotline fz-15 fw-400 text-white">
                        Bằng việc bấm Hoàn thành đồng nghĩa quý khách hàng đã xác nhận việc thanh toán
                        <br/> chuyển khoản theo yêu cầu 
                    </p>
                </div>
                <div className="button flex center">
                    <a className="btn btn-white" href='/'>
                        <span>Hoàn thành</span>
                    </a>  
                    {/* <a className="btn btn-light-blue" href='/'> 
                        <span>Kiểm tra đơn hàng</span>
                    </a> */}
                </div>
                <div className="info">
                    <h2 className="name">Thông tin liên hệ </h2>
                    <p className="hotline fz-18 fw-500 text-white">
                        Hotline: <a href="tel: 1900561212">1900561212</a>
                    </p>
                    <p className="mail fz-15 fw-400 text-white">
                        <a href="mailto: cs@hyundai.tcmotor.vn">cs@hyundai.tcmotor.vn</a>
                    </p>
                </div>
            </div>

        );
    }
}

export default SuccessCK;
