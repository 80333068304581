var initialState = [];

const categories = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CATEGORIES":
            return action.categories;  
        default: return state;
    }  
};  

export default categories;