import React from 'react';
import PropTypes from 'prop-types';
import Get from '../../../../utils/APIsHelper'; 

import InputField from "../../../form-control/InputField";
import Select from "../../../form-control/SelectField";

TablePayment.propTypes = {
    paymentTableList: PropTypes.array.isRequired,
};

function TablePayment({paymentTableList, form}) {
    const onChangePaid = (key) => { 
		if(document.getElementById('Status' + key) != null && document.getElementById('PaidAmount' + key) != null)   
		{  
			let status = document.getElementById('Status' + key).value;
			let paidAmount = document.getElementById('PaidAmount' + key).value;
			let body = {
			    key, 
				paidAmount,
			    status
			};
			Get('api/order/UpdateTransaction', 'post', body).then((res) => {
			    if (res.data.success) {

			    }
			});
		}
    }
    return (
        <div className="table table-payments">
            <h3 className="title-table fw-700 text-main fz-14 text-up">CÁc đợt thanh toán</h3>
			<form>
				<table> 
					<thead> 
						<tr> 
							<th>Đợt</th>
							<th>Ngày thành toán</th>
							<th>Số tiền thanh toán</th>
							<th>Các file ủy nhiệm chi</th>
							<th>Phương thức thanh toán</th>
							<th>Trạng thái</th>
							<th>Thời gian nhận</th>
						</tr>
					</thead>
					<tbody> 
						{paymentTableList.map(paymentTable => (
							<tr key={paymentTable.Id}> 
								<td> 
									{paymentTable.number}
								</td> 
								<td>{paymentTable.CreatedOn}</td> 
								<td> 
									<InputField  
										name={'PaidAmount' + paymentTable.Id}  
										type="number" 
										defaultValue={paymentTable.PaidAmount} 
										onChange={e => onChangePaid(paymentTable.Id)}  
										form={form}
									/> 
								</td>
								<td>
								{paymentTable.Files.map(({file,index}) => (
									<a className="btn btn-file" href={file} download="">
										File {index} 
									</a>
								))}
								</td>
								<td>{paymentTable.PaymentMethodName}</td>
								<td> 
									<Select  
										name={'Status' + paymentTable.Id}  
										options={
											paymentTable.status.map(sts => (
												{
													id: sts.id,
													value: sts.id,
													label: sts.title,
												}
											))
										} 
										handleChange={e => onChangePaid(paymentTable.Id)} 
										form={form}
									/> 
								</td>
								<td>{paymentTable.CreatedOn}</td>
							</tr>
						))}
					</tbody>
				</table>
			</form>
		</div>
    );
}

export default TablePayment;