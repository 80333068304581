import React from 'react';
import Login from '../../../components/customer/Login/index';

import PurchaseHistoryComponent from '../../../components/customer/PurchaseHistory';
import useToken from '../../../actions/useToken-customer';

function CustomerPurchaseHistoryPages(props) {
    const { token, setToken } = useToken();
    if (!token) {
        return <Login setToken={setToken} />;
    }
    return (
        <>
            <PurchaseHistoryComponent />
        </>
    );
}

export default CustomerPurchaseHistoryPages;
