import { combineReducers } from 'redux';
import category from './category';
import categories from './categories';
import banners from './banners';
import products from './products';
import productPrice from './productPrice';
import newsItems from './news-items';
import provinces from './provinces';
import districts from './districts';
import stores from './stores';
import orders from './orders';
import orderDetail from './order-detail';
import bankPromotions from './bank-promotion';
import promotionDetail from './bank-promotion-detail';
import contracts from './contracts';
import calculateLoan from './calculate-loan';

const appReducers = combineReducers({
	category,
	categories,
	banners,
	stores,
	products,
	productPrice,
	orders,
	orderDetail,
	newsItems,
	provinces,
	districts,
	bankPromotions,
	promotionDetail,
	contracts,
	calculateLoan
});

export default appReducers;