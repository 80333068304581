var initialState = {};

const category = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CATEGORY":
            return action.category;  
        default: return state;
    }  
};  
 
export default category;