import React, { Component } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

class InfoPayment extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.state = {
            files: [],
        };
    }

    onChange(e) {
        var files = e.target.files;
        var filesArr = Array.prototype.slice.call(files);
        this.setState({ files: [...this.state.files, ...filesArr] });
    }

    removeFile(f) {
        this.setState({ files: this.state.files.filter((x) => x !== f) });
    }

    render() {
        var { orderDetail, form } = this.props;
        return (
            <div className="widget widget-box-ck">
                <h2 className="widget-title fz-14 fw-600 text-main text-up">Thông tin chuyển khoản đặt cọc</h2>
                <div className="row">
                    <div className="col-sm-4 col-md-3 col-lg-3">
                        <p className="text-black fz-14 fw-500">Ngân hàng</p>
                    </div>
                    <div className="col-sm-8 col-md-9 col-lg-9">
                        <p className="text-black fz-14">{orderDetail.BankName}</p>
                    </div>
                    <div className="col-sm-4 col-md-3 col-lg-3">
                        <p className="text-black fz-14 fw-500">Số tài khoản</p>
                    </div>
                    <div className="col-sm-8 col-md-9 col-lg-9">
                        <p className="text-black fz-14 form-copied">
                            <input defaultValue={orderDetail.BankAccount} />
                            <CopyToClipboard text={orderDetail.BankAccount}>
                                <button type="button">
                                    <em className="ri-file-copy-fill"></em>
                                </button>
                            </CopyToClipboard>
                        </p>
                    </div>
                    <div className="col-sm-4 col-md-3 col-lg-3">
                        <p className="text-black fz-14 fw-500">Tên tài khoản</p>
                    </div>
                    <div className="col-sm-8 col-md-9 col-lg-9">
                        <p className="text-black fz-14">{orderDetail.BankAccountName}</p>
                    </div>
                    <div className="col-sm-4 col-md-3 col-lg-3">
                        <p className="text-black fz-14 fw-500">Nội dung</p>
                    </div>
                    <div className="col-sm-8 col-md-9 col-lg-9">
                        <p className="text-black fz-14 form-copied">
                            <input defaultValue={orderDetail.BankNote} />
                            <CopyToClipboard text={orderDetail.BankNote}>
                                <button type="button">
                                    <em className="ri-file-copy-fill"></em>
                                </button>
                            </CopyToClipboard>
                        </p>
                    </div>
                </div>
                <div className="content-upload-file">
                    <p className="text-black fz-14">
                        Sau khi chuyển khoản, quý khách vui lòng làm theo các bước dưới đây
                    </p>
                    <p className="text-black fz-14">Tải lên Uỷ nhiệm chi/ Biên lai chuyển tiền</p>
                    <div className="form-group">
                        <div className="input-upload flex align-center between">
                            <div className="file-preview flex align-center ">
                                {this.state.files.map((x) => (
                                    <p key={x.lastModified}>
                                        {x.name}
                                        <em className="remove" onClick={this.removeFile.bind(this, x)}>
                                            x
                                        </em>
                                    </p>
                                ))}
                            </div>
                            <label className="custom-file-upload flex align-center center">
                                <input
                                    type="file"
                                    name="fileAttachment"
                                    multiple
                                    onChange={this.onChange}
                                    className="input-file-upload"
                                    defaultValue=""
                                    form={form}
                                    required
                                />
                                Chọn file
                            </label>
                        </div>
                        <div className="note">
                            <p className="text-66 fz-12 text-italic">
                                Chỉ tải file ảnh hoặc PDF, dung lượng nhỏ hơn 3MB/file. Quý khách có thể tải lên tối đa
                                5 file.
                            </p>
                            <p className="text-66 fz-12 text-italic">
                                Trong trường hợp Quý khách cần hỗ trợ khác, vui lòng liên hệ ngay với chúng tôi.
                                Hotline: 1900 561212
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InfoPayment;
