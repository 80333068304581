import React from 'react';
import AdminNavLink from '../../../components/admin/Menu';
import Login from '../../../components/admin/Login/index';

import OrderDetailComponent from '../../../components/admin/OrderDetail';
import useToken from '../../../actions/useToken';

function AdminOrderDetailPage() {
    const { token, setToken } = useToken();
    if (!token) {
        return <Login setToken={setToken} />;
    }
    return (
        <>
            <section className="admin-section section bg-login bdbt-gray ">
                <div className="container">
                    <div className="row row-space">
                        <div className="col-lg-4 col-xl-3">
                            <AdminNavLink />
                        </div>
                        <div className="col-lg-8 col-xl-9">
                            <OrderDetailComponent token={token} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AdminOrderDetailPage;
