

import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './routes';


const ScrollToTop = lazy(() => import('./components/scroll-to-top'));
const Header = lazy(() => import('./components/global/Header'));
const Footer = lazy(() => import('./components/global/Footer'));
const NavFixedComponent = lazy(() => import('./components/navFixed'));

const renderLoader = () => <></>;
class App extends Component {
	render() {
		return (
			<Router >
				<Suspense className="App" fallback={renderLoader()}>
					<Header />
					<main>
						<ScrollToTop>
							{this.showContentMenus(routes)}
						</ScrollToTop>
					</main>
					<NavFixedComponent />
					<Footer />
				</Suspense>
			</Router>
		);
	}

	showContentMenus = (routes) => {
		var result = null;
		if (routes.length > 0) {
			result = routes.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						exact={route.exact}
						component={route.main}
					/>
				);
			});
		}
		return <Switch>{result}</Switch>;
	}

}
export default App;