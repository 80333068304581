import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import Get from '../../../../utils/APIsHelper';
import './styles.sass';

import FormStep2Left from './Left';
import Coupon from './Coupon';
import Payment from './Payment';

// import TablePrice from '../TablePrice';
import Car from './Car'; 

FormStep1.propTypes = {
    onSubmit: PropTypes.func,
};
 
function FormStep1({orderDetail}) {
    const history = useHistory();
    let save = (e) => {
        e.preventDefault(); 
        let orderId = localStorage.getItem('orderId');
        let orderToken = localStorage.getItem('orderToken');
        const paymentMethodId = e.target.payment.value;
        let body = {
            key : orderId,  
            token : orderToken,  
            paymentMethodId : paymentMethodId
        }; 
        Get('api/order/Complete', 'post', body).then((res) => {
            if (res.data.success) {
                localStorage.setItem('orderCompleteId', orderId);
                localStorage.setItem('orderCompleteToken', orderToken); 
                if(res.data.redirect !== undefined && res.data.redirect !== '')
                    window.location.href = res.data.redirect; 
                else
                {
                    //eslint-disable-next-line
                    if(paymentMethodId == 20)
                        history.push('step-3-thong-tin-vay');
                    else
                        history.push('step-3');
                }
                    
            } 
        });
    };
    return (
        <>
            <form method="post" onSubmit={save}>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="box-payment">
                            <FormStep2Left  orderDetail={orderDetail}/>
                            <Coupon />
                            <Payment orderDetail={orderDetail}/>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="box-car">
                            <Car  orderDetail={orderDetail}/>
                        </div>
                    </div>
                </div>
            </form> 
        </>
    );
}

export default FormStep1;
