import React from 'react';
import useToken from '../../../actions/useToken-vendors';

import Login from '../../../components/banker/Login';
import BankerNavLink from '../../../components/banker/Menu';
import LoanRegistrationListComponent from '../../../components/banker/LoanRegistrationList';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

function BankerLoanRegistrationListPage() {
    const { token, setToken } = useToken();
    // form
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    if (!token) {
        return <Login setToken={setToken} />;
    }

    return (
        <section className="admin-section banker-section section bg-login bdbt-gray">
            <div className="container">
                <div className="row row-space">
                    <div className="col-lg-4 col-xl-3">
                        <BankerNavLink />
                    </div>
                    <div className="col-lg-8 col-xl-9">
                        <LoanRegistrationListComponent form={form} token={token} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BankerLoanRegistrationListPage;
