import React from 'react';

import '../styles.sass';

// popup
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import InputField from '../../../form-control/InputField';
import Select from '../../../form-control/SelectField';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import './styles.sass';

const styles = (theme) => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(0),
    },
}))(MuiDialogContent);

function LoanOfferConsultationFormPopup(props) {
    // popup
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // form
    const { onSubmit } = props;

    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });
    return (
        <>
            <Button className="btn btn-light-blue" variant="outlined" color="primary" onClick={handleClickOpen}>
                <span>Đăng ký tư vấn</span>
            </Button>
            <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title-3" open={open}>
                <div className="form-popup" id="request-counselling">
                    <section className="request-counselling-section loan-offer-counselling-section">
                        <div className="box-form-popup bg-white">
                            <DialogTitle id="customized-dialog-title-3" onClose={handleClose}>
                                <h3 className="main-title text-center">Đăng ký tư vấn</h3>
                            </DialogTitle>
                            <DialogContent>
                                <form onSubmit={form.handleSubmit(onSubmit)}>
                                    <div className="wrap-form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <InputField
                                                        name="FullName"
                                                        type="text"
                                                        label="Họ và tên"
                                                        defaultValue="Nguyễn Ngọc Phát"
                                                        form={form}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <InputField
                                                        name="PhoneNumber"
                                                        type="text"
                                                        label="Số điện thoại"
                                                        defaultValue="0908123456"
                                                        form={form}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <InputField
                                                        name="Email"
                                                        type="text"
                                                        label="Email"
                                                        defaultValue="test123@gmail.com"
                                                        form={form}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <Select
                                                        name="Store"
                                                        label="Chỗ ở"
                                                        required
                                                        options={[
                                                            {
                                                                id: 0,
                                                                value: '1',
                                                                label: 'Tỉnh/ Thành phố',
                                                            },
                                                            {
                                                                id: 1,
                                                                value: '1',
                                                                label: 'HCM',
                                                            },
                                                            {
                                                                id: 2,
                                                                value: '2',
                                                                label: 'HN',
                                                            },
                                                        ]}
                                                        // handleChange={this.handleStoreChange}
                                                        form={form}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group frm-btnwrap">
                                                    <div className="frm-btn">
                                                        <input
                                                            className="btn btn-default frm-btn-submit"
                                                            type="submit"
                                                            value="Đăng ký tư vấn"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </DialogContent>
                        </div>
                    </section>
                </div>
            </Dialog>
        </>
    );
}

export default LoanOfferConsultationFormPopup;
