import React from 'react';

import ChangePasswordComponent from '../../../components/admin/ChangePassword';

function AdminChangePasswordPages(props) {
	return (
		<>
			<ChangePasswordComponent />
		</>
	);
}

export default AdminChangePasswordPages;