import React from 'react';
import PropTypes from 'prop-types';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

// install Swiper modules
SwiperCore.use([Navigation]);

ProductSlide.propTypes = {
    images: PropTypes.array.isRequired,
};

function ProductSlide({images}) {
    return (
        <div className="box-products"> 
            <div className="main-slide"> 
                <Swiper
                    speed={300}
                    navigation={{nextEl:".step-section .button-next", prevEl: ".step-section .button-prev"}}
                    spaceBetween={20}
                    observer={true}
                    observeParents={true}
                >
                    {images.map(image => (
                        <SwiperSlide key={image.id}> 
                            <div className="image"> 
                                <img 
                                    src={image.imageUrl} 
                                    alt={image.title}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="main-button"> 
                    <div className="button-prev"> <em className="ri-arrow-left-s-line" /></div>
                    <div className="button-next"> <em className="ri-arrow-right-s-line" /></div>
                </div>
            </div>
        </div>
    );
}

export default ProductSlide;