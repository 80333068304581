import React from 'react';
import { Link } from 'react-router-dom';

import NavList from '../../components/step/NavList';
import Step3FormWorkIncome from '../../components/step/Step3-WorkIncome';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

function Step3WorkIncomePages(props) {
    const stepList = [
        {
            id: 1,
            title: 'Lựa chọn xe',
            subTitle: '',
            description: '',
            url: '',
            imageUrl: '',
            thumbnailUrl: '',
            isActive: false,
            isTarget: false,
        },
        {
            id: 2,
            title: 'Thanh toán & ưu đãi',
            subTitle: '',
            description: '',
            url: '',
            imageUrl: '',
            thumbnailUrl: '',
            isActive: false,
            isTarget: false,
        },
        {
            id: 3,
            title: 'Thông tin vay',
            subTitle: '',
            description: '',
            url: '',
            imageUrl: '',
            thumbnailUrl: '',
            isActive: true,
            isTarget: false,
        },
    ];

    // form
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    return (
        <section className="step-section section bg-login bdbt-gray">
            <div className="container">
                <NavList stepList={stepList} />
                <div className="loan-info-form-wrap">
                    <ul className="loan-info-link type-none flex align-center center">
                        <li>
                            <Link className="flex align-center" to="/step-3-thong-tin-vay">
                                <span>Thông tin Sản phẩm vay</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="flex align-center" to="/step-3-thong-tin-ca-nhan">
                                <span>Thông tin cá nhân</span>
                            </Link>
                        </li>
                        <li className="active">
                            <Link className="flex align-center" to="/step-3-cong-viec-thu-nhap">
                                <span>Công việc, thu nhập</span>
                            </Link>
                        </li>
                    </ul>
                    <Step3FormWorkIncome form={form} />
                </div>
            </div>
        </section>
    );
}

export default Step3WorkIncomePages;
