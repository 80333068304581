var initialState = [];

const bankPromotions = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_BankPromotion":
            return action.bankPromotions;  
        default: return state;
    }  
}; 

export default bankPromotions;