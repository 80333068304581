import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actFetchOrderDetailRequest } from '../../../actions/index';
 
import NavList from '../NavList';
import Success from '../Success';
import SuccessCK from '../SuccessCK';

class Step3Component extends Component {
     
    componentDidMount() {
        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        this.props.fetchOrderDetail(orderId,orderToken);
        setTimeout(function() {
            localStorage.clear(); 
        }, 15000);   
    } 
    render() {
        let orderId = localStorage.getItem('orderCompleteId');
        let orderToken = localStorage.getItem('orderCompleteToken');
        if (orderId === null || orderToken === null)  
            return <Redirect path="/" />;
        const stepList = [
            {
                id: 1,
                title: 'Lựa chọn xe',
                subTitle: '',
                description: '',
                url: '',
                imageUrl: '',
                thumbnailUrl: '',
                isActive: false,
                isTarget: false,
            },
            {
                id: 2,
                title: 'Thanh toán & ưu đãi',
                subTitle: '',
                description: '',
                url: '',
                imageUrl: '',
                thumbnailUrl: '',
                isActive: false,
                isTarget: false,
            },
            {
                id: 3,
                title: 'Hoàn tất',
                subTitle: '',
                description: '',
                url: '',
                imageUrl: '',
                thumbnailUrl: '',
                isActive: true,
                isTarget: false,
            },
        ];
        var {orderDetail} =this.props;
 
        if(orderDetail.ProductImages != null)
        { 
            orderDetail['ImageUrl'] = orderDetail.ProductImages[0];
            if(orderDetail.ProductImages.length > 1)
                orderDetail['ImageUrl']  = orderDetail.ProductImages[1]; 
        }
        orderDetail.IsBankTranfer = true;
        if(orderDetail.IsBankTranfer)
            return (
                <>
                    <NavList stepList={stepList} />
                    <SuccessCK  orderDetail={orderDetail}/> 
                </>
            ); 

        else
            return (
                <>
                    <NavList stepList={stepList} />
                    <Success  orderDetail={orderDetail}/> 
                </>
            );
    }
}

const mapStateToProps = (state) => {
    return {
        orderDetail: state.orderDetail,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
		fetchOrderDetail: (orderId, orderToken) => {
            let body = {Key : orderId, Token: orderToken};
			dispatch(actFetchOrderDetailRequest(body));
		},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3Component);
