import React from 'react';
import PropTypes from 'prop-types';

import './styles.sass';

BannerChildList.propTypes = {
    bannerChildList: PropTypes.object.isRequired,
};

function BannerChildList({bannerChildList}) {
    return (
        <>
            <div className="image img-scale" key={bannerChildList.id}>
                <img src={bannerChildList.imageUrl} alt={bannerChildList.title}/>
            </div>
        </>
    );
}

export default BannerChildList;