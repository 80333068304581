var initialState = [];

const newsItems = (state = initialState, action) => {
    switch (action.type) { 
        case "FETCH_NEWSITEMS":
            return action.newsItems;  
        default: return state;
    }  
}; 

export default newsItems; 