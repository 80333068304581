import React from 'react';
import PropTypes from 'prop-types';
import Get from '../../../../utils/APIsHelper';

import Select from '../../../form-control/SelectField';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

TableOrder.propTypes = {
    orderTableList: PropTypes.array.isRequired,
};

function TableOrder({ orderTableList }) {
    // form
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });
    const onChange = (key) => {
        if (document.getElementById('Status' + key) != null) {
            let status = document.getElementById('Status' + key).value;
            let body = {
                key,
                status,
            };
            Get('api/order/UpdateRequest', 'post', body).then((res) => {
                if (res.data.success) {
                }
            });
        }
    };
    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Thời gian</th>
                        <th>Tên KH</th>
                        <th>Model</th>
                        <th>Tỉnh/TP</th>
                        <th>Đại lý</th>
                        <th>Số điện thoại</th>
                        <th>Email</th>
                        <th>Trạng thái</th>
                    </tr>
                </thead>
                <tbody>
                    {orderTableList.map((order) => (
                        <tr key={order.id}>
                            <td className="id">
                                {order.number < 10 ? '0' : ''}
                                {order.number}
                            </td>
                            <td>
                                <p>
                                    <time>{order.CreateOn}</time>
                                </p>
                            </td>
                            <td>{order.user}</td>
                            <td>
                                <p>{order.title}</p>
                                <p>{order.verison}</p>
                                <p>{order.color}</p>
                            </td>
                            <td>{order.province}</td>
                            <td>{order.store}</td>
                            <td>{order.phone}</td>
                            <td>{order.email}</td>
                            <td>
                                <Select
                                    name={'Status' + order.id}
                                    label="Trạng thái xử lý"
                                    required
                                    defaultValue={order.statusId}
                                    options={order.status.map((sts) => ({
                                        id: sts.id,
                                        value: sts.value,
                                        label: sts.label,
                                    }))}
                                    handleChange={(e) => onChange(order.id)}
                                    form={form}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TableOrder;
