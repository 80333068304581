import React from 'react'
import { withGoogleMap, withScriptjs, GoogleMap, Marker  } from "react-google-maps"
  
function Map({lat, lng, zoom , mapZoom, stores}) { 
        
    const displayMarkers = () => {
        return stores.map(store => {
            let lat = 0;
            let lng = 0;
            if(store.latitude !== '' && store.latitude !== null) 
                lat = parseFloat(store.latitude.replace(",", "."));
			// if(store.latitude === null)
			// 	lng = 0;
            if(store.longitude !== '' && store.longitude !== null)
                lng = parseFloat(store.longitude.replace(",", "."));
			// if(store.longitude === null)
			// 	lng = 0;
            if(lat !== 0 && lng !== 0)
                return (
                    <Marker
                        icon={{
                            url: '/assets/images/uploaded/icon-maps.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        key={store.id}
                        id={store.id}
                        position={{
                            lat: lat,
                            lng: lng,
                        }}
                        onClick={() => mapZoom(lat, lng)}
                    ></Marker>
                );
            return <></>;
        })
    }
    return (
    <div>
      <GoogleMap
          zoom={zoom} 
          center={{ lat: lat, lng: lng }}
        >
        {displayMarkers()}
      </GoogleMap>
    </div>
  ); 
} 

export default withScriptjs(withGoogleMap(Map));