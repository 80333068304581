import React from 'react';
import { useHistory } from 'react-router-dom';

import Get from '../../../utils/APIsHelper';

import './styles.sass';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import NavList from '../NavList';
import Step1Form from './Form';
import VerificationCodeFormComponent from '../../popup/FormPopup/VerificationCode';

function validatePhone(phoneNumber) {
    var phoneNumberPattern = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
    return phoneNumberPattern.test(phoneNumber);
}

function validateCardNumber(cardNumber) {
    var cardNumberPattern = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
    var length = cardNumber.length;
    if (length === 9 || length === 12) return cardNumberPattern.test(cardNumber);
    return false;
}

function validateEmail(email) {
    //eslint-disable-next-line
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function hasSpecialChar(content) {
    //eslint-disable-next-line
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(String(content).toLowerCase());
}

function Step1Component(props) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [OTPMessage, setOTPMessage] = React.useState('');
    const [orderId, setOrderId] = React.useState('');
    const [orderToken, setOrderToken] = React.useState('');

    const history = useHistory();
    const schema = yup.object().shape({
        content: yup.string().required('Please enter title'),
    });

    let save = (e) => {
        e.preventDefault();
        let storeId = '';
        if (e.target.Store !== undefined) storeId = e.target.Store.value;
        if (storeId === '') {
            alert('Vui lòng chọn cửa hàng!');
            return;
        }
        let productColorId = e.target.ProductColor.value;
        if (productColorId === '') {
            alert('Vui lòng chọn màu sắc xe!');
            return;
        }
        let productId = e.target.Product.value;
        let productModelId = e.target.ModelType.value;
        let quantity = e.target.Quantity.value;

        let fullName = e.target.FullName.value;
        let CCCD = e.target.CCCD.value;
        let phoneNumber = e.target.PhoneNumber.value;
        let email = e.target.Email.value;
        if (!validatePhone(phoneNumber)) {
            alert('Số điện thoại không hợp lệ!');
            e.target.PhoneNumber.focus();
            return;
        }
        if (!validateEmail(email)) {
            alert('Email không hợp lệ!');
            e.target.Email.focus();
            return;
        }
        if (hasSpecialChar(fullName)) {
            alert('Họ và tên không hợp lệ!');
            e.target.FullName.focus();
            return;
        }

        // if (!validatePhone(CCCD)) {
        //     alert('Vui lòng điền CMND/CCCD hợp lệ!');
        //     e.target.CCCD.focus();
        //     return;
        // }
        if (!validateCardNumber(CCCD)) {
            alert('Vui lòng điền CMND/CCCD hợp lệ!');
            e.target.CCCD.focus();
            return;
        }
        let acceptPolicy = e.target.AcceptPolicy.checked;
        if (acceptPolicy !== true) {
            alert('Vui lòng đồng ý điều khoản để đặt hàng!');
            return;
        }
        let employeeCode = e.target.EmployeeCode.value;
        let employeePhoneNumber = e.target.EmployeePhoneNumber.value;
        let body = {
            storeId,
            productId,
            productModelId,
            productColorId,
            quantity,
            fullName,
            CCCD,
            phoneNumber,
            email,
            employeeCode,
            employeePhoneNumber,
            acceptPolicy,
        };
        Get('api/order/Create', 'post', body).then((res) => {
            if (res.data.success) {
                setOpenDialog(true);
                setPhoneNumber(phoneNumber);
                setOrderId(res.data.id);
                setOrderToken(res.data.token);
                localStorage.setItem('orderId', res.data.id);
                localStorage.setItem('orderToken', res.data.token);
                setOTPMessage('');
            }
        });
    };

    const onVerificationOTP = (e) => {
        e.preventDefault();
        let OTPCode = e.target.OTPCode.value;
        if (OTPCode !== '') {
            let body = {
                key: orderId,
                token: orderToken,
                code: OTPCode,
            };
            Get('api/order/Verification', 'post', body).then((res) => {
                if (res.data.success) {
                    setOTPMessage('Xác thực thành công!');
                    setOpenDialog(false);
                    history.push('step-2');
                } else if (res.data.status === 1) {
                    setOTPMessage('Mã xác thực hết hạn!');
                } else if (res.data.status === 5) {
                    setOTPMessage('Mã xác thực không đúng!');
                } else if (res.data.status === 10) {
                    setOTPMessage('Xác thực OTP thất bại!. Vui lòng bấm gửi lại OTP để nhận OTP mới');
                }
            });
        }
    };
    const onClickReSend = (e) => {
        e.preventDefault();
        let body = {
            key: orderId,
        };
        Get('api/order/ReSendOTP', 'post', body).then((res) => {
            if (res.data.success) setOTPMessage('');
            setOpenDialog(false);
            setOpenDialog(true);
        });
    };
    const form = useForm({
        defaultValues: {
            content: '',
        },
        resolver: yupResolver(schema),
    });

    const handleClose = () => {
        setOpenDialog(false);
    };
    const stepList = [
        {
            id: 1,
            title: 'Lựa chọn xe',
            subTitle: '',
            description: '',
            url: '',
            imageUrl: '',
            thumbnailUrl: '',
            isActive: true,
            isTarget: false,
        },
        {
            id: 2,
            title: 'Thanh toán & ưu đãi',
            subTitle: '',
            description: '',
            url: '',
            imageUrl: '',
            thumbnailUrl: '',
            isActive: false,
            isTarget: false,
        },
        {
            id: 3,
            title: 'Hoàn tất',
            subTitle: '',
            description: '',
            url: '',
            imageUrl: '',
            thumbnailUrl: '',
            isActive: false,
            isTarget: false,
        },
    ];
    return (
        <>
            <NavList stepList={stepList} />
            <form method="post" onSubmit={save}>
                <Step1Form form={form} />
            </form>
            <VerificationCodeFormComponent
                openDialog={openDialog}
                handleClose={handleClose}
                phoneNumber={phoneNumber}
                message={OTPMessage}
                orderId={orderId}
                onSubmit={onVerificationOTP}
                onClickReSend={onClickReSend}
            />
        </>
    );
}

export default Step1Component;
