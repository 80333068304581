import React from 'react';

import './styles.sass';

// popup
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import TableLoan from './Table';

const styles = (theme) => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(0),
    },
}))(MuiDialogContent);

function LoanListPopup({ item }) {
    // popup
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button className="btn btn-light-blue" variant="outlined" color="primary" onClick={handleClickOpen}>
                <span>{item?.Code}</span>
            </Button>
            <Dialog maxWidth="xl" onClose={handleClose} aria-labelledby="customized-dialog-title-3" open={open}>
                <div className="content-popup" id="loan-info">
                    <section className="loan-info-section">
                        <div className="box-form-popup bg-white">
                            <DialogTitle id="customized-dialog-title-3" onClose={handleClose}>
                                <h3 className="main-title text-center">Số đề nghị {item?.Code}</h3>
                            </DialogTitle>
                            <DialogContent>
                                <TableLoan item={item}></TableLoan>
                            </DialogContent>
                        </div>
                    </section>
                </div>
            </Dialog>
        </>
    );
}

export default LoanListPopup;
