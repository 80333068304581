import 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// form
import InputField from '../../../form-control/InputField';
import Select from '../../../form-control/SelectField';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    // KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

function validatePhone(phoneNumber) {
    var phoneNumberPattern = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
    return phoneNumberPattern.test(phoneNumber);
}

function validateCardNumber(cardNumber) {
    var cardNumberPattern = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
    var length = cardNumber.length;
    if (length === 9 || length === 12) return cardNumberPattern.test(cardNumber);
    return false;
}

function hasSpecialChar(content) {
    //eslint-disable-next-line
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(content);
}
function calculateYear(date) {
    var ageDifMs = Date.now() - date;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.round(ageDate.getUTCFullYear() - 1970);
}
function convertToDate(dateString) {
    let d = dateString.split('/');
    let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return dat;
}
function formatDate($date) {
    let date = convertToDate($date);
    let result = '';
    if (date.getDate() < 10) result += '0' + date.getDate();
    else result += date.getDate();
    result += '/';
    let month = date.getMonth() + 1;
    if (month < 10) result += '0' + month;
    else result += month;
    result += '/';
    result += date.getFullYear();
    return result;
}

// function formatLoanAmount(value) {
//     return parseFloat(value.replaceAll('.', '').replaceAll('đ', ''));
// }

function FormStep3FormPersonalInfo(props) {
    const [selectedBirthDay, setSelectedBirthDay] = React.useState(new Date('2000-01-01'));
    const [selectedIdentityCardDate, setSelectedIdentityCardDate] = React.useState(new Date('2000-01-01'));
    const [selectedOtherBirthDay, setSelectedOtherBirthDay] = React.useState(new Date('2000-01-01'));
    const [selectedOtherPersonIdentityCardDate, setSelectedOtherPersonIdentityCardDate] = React.useState(
        new Date('2000-01-01')
    );
    const handleBirthDayChange = (date) => {
        setSelectedBirthDay(date);
    };
    const handleIdentityCardDateChange = (date) => {
        setSelectedIdentityCardDate(date);
    };
    const handleOtherBirthDayChange = (date) => {
        setSelectedOtherBirthDay(date);
    };
    const handleOtherPersonIdentityCardDateChange = (date) => {
        setSelectedOtherPersonIdentityCardDate(date);
    };
    const {
        form,
        order,
        maritalStatus,
        placeIssued,
        provinces,
        districts,
        wards,
        onChangeProvince,
        onChangeDictrist,
        currentProvinces,
        currentDistricts,
        currentWards,
        onChangeCurrentProvince,
        onChangeCurrentDictrist,
    } = props;
    let onChangeDisplayOtherAddress = (e) => {
        let val = document.querySelector('input[name="ResidentInformation"]:checked').value;
        var elements = document.getElementsByClassName('other-address');
        Array.from(elements).forEach(function (element, index, array) {
            if (parseInt(val) === 1) element.style.display = 'block';
            else element.style.display = 'none';
        });
    };
    let onChangeMaritalStatus = (e) => {
        let val = document.querySelector('select[name="MaritalStatus"]').value;
        var elements = document.getElementsByClassName('other-person-info');
        Array.from(elements).forEach(function (element, index, array) {
            if (val === 'MARRIED') element.style.display = 'block';
            else element.style.display = 'none';
        });
    };

    const history = useHistory();
    let save = (e) => {
        e.preventDefault();
        let contract = JSON.parse(localStorage.getItem('LoanContract'));
        const birthDay = e.target.BirthDay.value;
        var dob = convertToDate(birthDay);
        const aYearFromNow = new Date();
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() - 18);
        if (dob > aYearFromNow || calculateYear(dob) < 18) {
            alert('Vui lòng chọn ngày sinh tối thiểu là 18 tuổi!');
            e.target.BirthDay.focus();
            return;
        }
        const identityCardDate = e.target.IdentityCardDate.value;
        var doCard = convertToDate(identityCardDate);
        if (calculateYear(doCard) < 0 || calculateYear(doCard) > 15) {
            alert('Vui lòng chọn ngày cấp CMND/CCCD tối đa là 15 năm!');
            e.target.IdentityCardDate.focus();
            return;
        }
        const sex = e.target.Sex.value;
        const maritalStatus = e.target.MaritalStatus.value;
        const identityCardNumber = e.target.IdentityCardNumber.value;
        const identityCardAddress = e.target.IdentityCardAddress.value;
        const addressProvince = e.target.AddressProvince.value;
        const addressDistrict = e.target.AddressDistrict.value;
        const addressWard = e.target.AddressWard.value;
        const address = e.target.Address.value;
        const currentProvince = e.target.CurrentProvince.value;
        const currentDistrict = e.target.CurrentDistrict.value;
        const currentWard = e.target.CurrentWard.value;

        const currentAddress = e.target.CurrentAddress.value;
        const referenceFullName = e.target.ReferenceFullName.value;
        const referenceCode = e.target.ReferenceCode.value;
        const referencePhoneNumber = e.target.ReferencePhoneNumber.value;
        const otherPersonFullName = e.target.otherPersonFullName.value;
        const otherPersonBirthDay = e.target.otherPersonBirthDay.value;
        const otherPersonSex = e.target.otherPersonSex.value;
        const otherPersonIdCard = e.target.otherPersonIdCard.value;
        const residentInformation = parseInt(e.target.ResidentInformation.value);
        const otherPersonIdentityCardDate = e.target.otherPersonIdentityCardDate.value;
        const otherPersonIdentityCardAddress = e.target.otherPersonIdentityCardAddress.value;
        if (!validateCardNumber(identityCardNumber)) {
            alert('Vui lòng điền CMND/CCCD hợp lệ!');
            e.target.IdentityCardNumber.focus();
            return;
        }
        if (address === '') {
            alert('Vui lòng điền Địa chỉ!');
            e.target.Address.focus();
            return;
        }
        if (hasSpecialChar(address)) {
            alert('Địa chỉ không hợp lệ!');
            e.target.Address.focus();
            return;
        }
        if (residentInformation === 1) {
            if (currentAddress === '') {
                alert('Vui lòng điền Địa chỉ!');
                e.target.CurrentAddress.focus();
                return;
            }
            if (hasSpecialChar(currentAddress)) {
                alert('Địa chỉ không hợp lệ!');
                e.target.CurrentAddress.focus();
                return;
            }
        }
        if (maritalStatus !== 'MARRIED') {
            contract.otherPersonBirthDay = '';
            contract.otherPersonSex = '';
            contract.otherPersonIdentityCardDate = '';
            contract.otherPersonIdentityCardAddress = '';
        }
        if (maritalStatus === 'MARRIED') {
            if (otherPersonFullName === '') {
                alert('Vui lòng điền họ và tên người đồng vay!');
                e.target.otherPersonFullName.focus();
                return;
            }
            if (hasSpecialChar(otherPersonFullName)) {
                alert('Họ và tên người đồng vay không hợp lệ!');
                e.target.otherPersonFullName.focus();
                return;
            }
            dob = convertToDate(otherPersonBirthDay);
            if (otherPersonBirthDay === '' || calculateYear(dob) < 18) {
                alert('Vui lòng chọn ngày sinh tối thiểu là 18 tuổi!');
                e.target.otherPersonBirthDay.focus();
                return;
            }
            doCard = convertToDate(otherPersonIdentityCardDate);
            if (otherPersonIdentityCardDate === '' || calculateYear(doCard) < 0 || calculateYear(doCard) > 15) {
                alert('Vui lòng chọn ngày cấp CMND/CCCD tối đa là 15 năm!');
                e.target.otherPersonIdentityCardDate.focus();
                return;
            }
            if (!validateCardNumber(otherPersonIdCard)) {
                alert('Số CMND/CCCD không hợp lệ!');
                e.target.otherPersonIdCard.focus();
                return;
            }
            if (otherPersonIdCard === identityCardNumber) {
                alert('Vui lòng điền CMND/CCCD  người đồng vay khác với người vay');
                e.target.otherPersonIdCard.focus();
                return;
            }
            contract.otherPersonBirthDay = formatDate(otherPersonBirthDay);
            contract.otherPersonSex = otherPersonSex;
            contract.otherPersonIdentityCardDate = formatDate(otherPersonIdentityCardDate);
            contract.otherPersonIdentityCardAddress = otherPersonIdentityCardAddress;
        }
        if (referenceFullName === '') {
            alert('Vui lòng điền họ và tên người tham chiếu!');
            e.target.ReferenceFullName.focus();
            return;
        }
        if (hasSpecialChar(referenceFullName)) {
            alert('Họ và tên người tham chiếu không hợp lệ!');
            e.target.ReferenceFullName.focus();
            return;
        }
        if (referencePhoneNumber === '') {
            alert('Vui lòng điền số điện thoại người tham chiếu!');
            e.target.ReferencePhoneNumber.focus();
            return;
        }
        if (!validatePhone(referencePhoneNumber)) {
            alert('Số điện thoại người tham chiếu không hợp lệ!');
            e.target.ReferencePhoneNumber.focus();
            return;
        }
        if (referencePhoneNumber === e.target.PhoneNumber.value) {
            alert('Vui lòng điền số điện thoại người tham chiếu khắc với người vay');
            e.target.ReferencePhoneNumber.focus();
            return;
        }
        contract.dateOfBirth = formatDate(birthDay);
        contract.gender = sex;
        contract.maritalStatus = maritalStatus;
        contract.identityCardNumber = identityCardNumber;
        contract.identityCardAddress = identityCardAddress;
        contract.identityCardDate = formatDate(identityCardDate);
        contract.addressProvince = addressProvince;
        contract.addressDistrict = addressDistrict;
        contract.addressWard = addressWard;
        contract.address = address;

        contract.currentAddressSame = residentInformation === 1 ? false : true;
        contract.currentAddressProvince = currentProvince;
        contract.currentAddressDistrict = currentDistrict;
        contract.currentAddressWard = currentWard;

        contract.currentAddress = currentAddress;
        contract.referenceFullName = referenceFullName;
        contract.referenceCode = referenceCode;
        contract.referencePhoneNumber = referencePhoneNumber;

        contract.otherPersonFullName = otherPersonFullName;
        contract.otherPersonIdCard = otherPersonIdCard;

        let json = JSON.stringify(contract);
        localStorage.setItem('LoanContract', json);
        history.push('step-3-cong-viec-thu-nhap');
    };

    return (
        <>
            <form method="post" onSubmit={save}>
                <div className="wrap-form box-loan-info box-personal-info">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="firstName"
                                    type="text"
                                    label="Họ và tên"
                                    value={order.FullName || ''}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="PhoneNumber"
                                    type="text"
                                    label="Số điện thoại"
                                    value={order.PhoneNumber || ''}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="email"
                                    type="text"
                                    label="Email"
                                    value={order.Email || ''}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                {/* <InputField 
									name="BirthDay" 
									type="date" 
									format="dd/MM/yyyy"
									label="Ngày sinh" 
									form={form} 
								/> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name="BirthDay"
                                        format="dd/MM/yyyy"
                                        // value="01/01/2020"
                                        margin="normal"
                                        label="Ngày sinh"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        value={selectedBirthDay}
                                        onChange={handleBirthDayChange}
                                        form={form}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="" className="loan-label">
                                Giới tính
                            </label>
                            <div className="radio-wrap">
                                <div className="form-group form-radio">
                                    <input type="radio" name="Sex" defaultChecked="checked" id="sex-1" value="M" />
                                    <label htmlFor="sex-1">Nam</label>
                                </div>
                                <div className="form-group form-radio">
                                    <input type="radio" name="Sex" id="sex-2" value="F" />
                                    <label htmlFor="sex-2">Nữ</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="MaritalStatus"
                                    label="Tình trạng hôn nhân"
                                    options={maritalStatus}
                                    form={form}
                                    handleChange={onChangeMaritalStatus}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField
                                    name="IdentityCardNumber"
                                    type="text"
                                    label="Số CMND/CCCD"
                                    value={order.CCCD || ''}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select name="IdentityCardAddress" label="Nơi cấp" options={placeIssued} form={form} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                {/* <InputField 
									name="IdentityCardDate" 
									type="date" 
									label="Ngày cấp" 
									defaultValue="2015-07-21"
									form={form} 
								/> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name="IdentityCardDate"
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        // value="01/01/2020"
                                        margin="normal"
                                        // id="date-picker-inline"
                                        label="Ngày cấp"
                                        value={selectedIdentityCardDate}
                                        onChange={handleIdentityCardDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        form={form}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="AddressProvince"
                                    label="Tỉnh/Thành phố (Địa chỉ thường trú)"
                                    options={provinces}
                                    form={form}
                                    handleChange={onChangeProvince}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="AddressDistrict"
                                    label="Quận/Huyện (Địa chỉ thường trú)"
                                    options={districts}
                                    form={form}
                                    handleChange={onChangeDictrist}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="AddressWard"
                                    label="Phường/xã (Địa chỉ thường trú)"
                                    options={wards}
                                    form={form}
                                    // handleChange={onChangeModelType}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <InputField name="Address" type="text" label="Địa chỉ thường trú" form={form} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="" className="loan-label">
                                Thông tin thường trú có trùng với thông tin hiện tại
                            </label>
                            <div className="radio-wrap">
                                <div className="form-group form-radio">
                                    <input
                                        type="radio"
                                        name="ResidentInformation"
                                        id="Resident-1"
                                        value="0"
                                        onChange={onChangeDisplayOtherAddress}
                                    />
                                    <label htmlFor="Resident-1">Có</label>
                                </div>
                                <div className="form-group form-radio">
                                    <input
                                        type="radio"
                                        name="ResidentInformation"
                                        defaultChecked="checked"
                                        id="Resident-2"
                                        value="1"
                                        onChange={onChangeDisplayOtherAddress}
                                    />
                                    <label htmlFor="Resident-2">Không</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 other-address">
                            <div className="form-group">
                                <Select
                                    name="CurrentProvince"
                                    label="Tỉnh/Thành phố (Địa chỉ hiện tại)"
                                    options={currentProvinces}
                                    form={form}
                                    handleChange={onChangeCurrentProvince}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 other-address">
                            <div className="form-group">
                                <Select
                                    name="CurrentDistrict"
                                    label="Quận/Huyện (Địa chỉ hiện tại)"
                                    options={currentDistricts}
                                    form={form}
                                    handleChange={onChangeCurrentDictrist}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 other-address">
                            <div className="form-group">
                                <Select
                                    name="CurrentWard"
                                    label="Phường/Xã (Địa chỉ hiện tại)"
                                    options={currentWards}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 other-address">
                            <div className="form-group">
                                <InputField name="CurrentAddress" type="text" label="Địa chỉ hiện tại" form={form} />
                            </div>
                        </div>
                        <div className="col-md-12 other-person-info">
                            <h2 className="box-form-title fz-16 fw-700 text-main text-up">
                                <span>Thông tin người đồng vay</span>
                            </h2>
                        </div>
                        <div className="col-md-6  other-person-info">
                            <div className="form-group">
                                <InputField name="otherPersonFullName" type="text" label="Họ và tên" form={form} />
                            </div>
                        </div>
                        <div className="col-md-6 other-person-info">
                            <div className="form-group">
                                {/* <InputField 
									name="otherPersonBirthDay" 
									type="date" 
									label="Ngày sinh" 
									form={form} 
								/> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name="otherPersonBirthDay"
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        // value="01/01/2020"
                                        margin="normal"
                                        // id="date-picker-inline"
                                        label="Ngày sinh"
                                        value={selectedOtherBirthDay}
                                        onChange={handleOtherBirthDayChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        form={form}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className="col-md-6 other-person-info">
                            <label htmlFor="" className="loan-label">
                                Giới tính
                            </label>
                            <div className="radio-wrap">
                                <div className="form-group form-radio">
                                    <input
                                        type="radio"
                                        name="otherPersonSex"
                                        defaultChecked="checked"
                                        id="otherPersonsex-1"
                                        value="M"
                                    />
                                    <label htmlFor="otherPersonsex-1">Nam</label>
                                </div>
                                <div className="form-group form-radio">
                                    <input type="radio" name="otherPersonSex" id="otherPersonsex-2" value="F" />
                                    <label htmlFor="otherPersonsex-2">Nữ</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 other-person-info">
                            <div className="form-group">
                                <InputField name="otherPersonIdCard" type="text" label="Số CMND/CCCD" form={form} />
                            </div>
                        </div>
                        <div className="col-md-6 other-person-info">
                            <div className="form-group">
                                <Select
                                    name="otherPersonIdentityCardAddress"
                                    label="Nơi cấp"
                                    options={placeIssued}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 other-person-info">
                            <div className="form-group">
                                {/* <InputField 
									name="otherPersonIdentityCardDate" 
									type="date" 
									label="Ngày cấp" 
									defaultValue=""
									form={form} 
								/> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name="otherPersonIdentityCardDate"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        label="Ngày cấp"
                                        value={selectedOtherPersonIdentityCardDate}
                                        onChange={handleOtherPersonIdentityCardDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        form={form}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <h2 className="box-form-title fz-16 fw-700 text-main text-up">
                                <span>Thông tin Người tham chiếu</span>
                            </h2>
                        </div>
                        <div className="col-md-6  ">
                            <div className="form-group">
                                <InputField name="ReferenceFullName" type="text" label="Họ và tên" form={form} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Select
                                    name="ReferenceCode"
                                    label="Mối quan hệ"
                                    options={[
                                        {
                                            id: 1,
                                            value: 'S',
                                            label: 'Vợ chồng',
                                        },
                                        {
                                            id: 2,
                                            value: 'C',
                                            label: 'Đồng nghiệp',
                                        },
                                        {
                                            id: 3,
                                            value: 'FR',
                                            label: 'Bạn bè',
                                        },
                                        {
                                            id: 4,
                                            value: 'P',
                                            label: 'Bố mẹ',
                                        },
                                        {
                                            id: 5,
                                            value: 'SB',
                                            label: 'Chị gái/Anh trai',
                                        },
                                        {
                                            id: 6,
                                            value: 'SD',
                                            label: 'Con trai/Con gái',
                                        },
                                        {
                                            id: 7,
                                            value: 'R',
                                            label: 'Mối quan hệ khác',
                                        },
                                    ]}
                                    form={form}
                                />
                            </div>
                        </div>
                        <div className="col-md-6  ">
                            <div className="form-group">
                                <InputField name="ReferencePhoneNumber" type="text" label="Số điện thoại" form={form} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group frm-btnwrap">
                                <div className="button">
                                    <Link
                                        className="flex align-center btn btn-back btn-67 text-white"
                                        to="/step-3-thong-tin-vay"
                                    >
                                        Quay lại
                                    </Link>
                                </div>
                                <div className="frm-btn">
                                    <input className="btn btn-default frm-btn-submit" type="submit" value="Tiếp theo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default FormStep3FormPersonalInfo;
