
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux';
import store from './store';


// css global
import "./assets/sass/fonts.sass";
import "./index.sass";
import "./assets/sass/alert.sass";
import "./assets/sass/button.sass";
import "./assets/sass/form-group.sass";
import "./assets/sass/icons.sass";
import "./assets/sass/index.sass";
import "./assets/sass/item.sass";
import "./assets/sass/section.sass";
import "./assets/sass/swiper.sass";
import "./assets/sass/tabslet.sass";
import "./assets/sass/title.sass";
import "./assets/sass/util.sass";

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
registerServiceWorker();
