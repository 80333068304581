import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actFetchStoresRequest, actFetchProvincesRequest, actFetchDistrictsRequest } from '../../actions/index'; 


import FormFilter from './FormFilter';
import FilterList from './FilterList';
import Maps from './Maps';

class DealersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: 16.6,
            lng: 106.6,
            customerLat: '', 
            customerLng: '', 
            zoom: 6
        };
    } 
    componentDidMount() {
        this.props.fetchProvinces();
        let lat= 0;
        let lng= 0;
        navigator.geolocation.getCurrentPosition(function(position) {
            lat = position.coords.latitude;
            lng = position.coords.longitude;
        });  

        setTimeout(function() {
                this.setState({ customerLat: lat });
                this.setState({ customerLng: lng });  
                this.props.fetchStores('', lat , lng);  
        }  
        .bind(this), 1000);  
    }

    onMapZoom = (storelat, storeLng) => { 
        this.setState({ 
            lat: storelat,
            lng : storeLng,
            zoom : 12
        }); 
    }

    onProvinceChange = (event) => { 
        let provinceId = event.target.value;
        this.props.fetchStores(provinceId);
        if(provinceId === '')
            provinceId = '-1';
        this.props.fetchDistricts(provinceId); 
    }
 
    render() {     
        const key = process.env.REACT_APP_MAPS_KEY; 
        var { stores, provinces, districts} = this.props;
        const dealersList =[];
        const provincesList=[];
        const disttrictsList=[];
        stores.forEach(element => {
            dealersList.push({
                id : element.id,
                title : element.name,
                description : element.description,
                latitude : element.latitude,
                longitude : element.longitude
            });   
        });


        disttrictsList.push({
            id : '1', 
            value : '',
            label : 'Chọn quận huyện'
        });  
        provincesList.push({
            id : '1', 
            value : '',
            label : 'Tất cả'
        });  
        provinces.forEach(element => {
            provincesList.push({
                id : element.id, 
                value : element.id,
                label : element.name
            });  
        });
        if(districts !== undefined) 
            districts.forEach(element => { 
                disttrictsList.push({
                    id : element.id, 
                    value : element.id,
                    label : element.name
                });  
            });
        return (
            <section className="dealers-section section"> 
                <div className="container"> 
                    <h1 className="main-title text-center">Hệ thống đại lý</h1>
                    <div className="dealers-wrap"> 
                        <div className="row no-gutters">
                            <div className="col-lg-4"> 
                                <FormFilter provinces={provincesList} districts={disttrictsList} onProvinceChange={this.onProvinceChange}/>
                                <FilterList dealersList={dealersList} mapZoom={this.onMapZoom}/>
                            </div>
                            <div className="col-lg-8">  
                                <Maps 
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}`}
                                    loadingElement={<div  style={{ height: `100%` }} />}
                                    containerElement={<div className="div-iframe" style={{ height: `665px`, margin: `auto`, border: 'none' }} />}
                                    mapElement={<div style={{ height: `100%` }}/>}   
                                    lat={this.state.lat} lng={this.state.lng} zoom={this.state.zoom} 
                                    mapZoom={this.onMapZoom}    
                                    stores={stores}/> 
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
const mapStateToProps = state => {
    return {
        provinces: state.provinces,
        districts: state.districts,
        stores: state.stores
    }
  }
  
  const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchProvinces: () => {
            dispatch(actFetchProvincesRequest());
        },
        fetchDistricts: (provinceId) => {
            dispatch(actFetchDistrictsRequest(provinceId));
        },
        fetchStores: (province,customerLat,customerLng) => { 
            dispatch(actFetchStoresRequest(province, false, customerLat, customerLng));
        }
    }
  } 
  
  export default connect(mapStateToProps, mapDispatchToProps)(DealersComponent);