import React, { Component } from 'react';
import NavAdmin from './NavAdmin';
import userInfo from '../../../actions/userInfo';

class AdminNavLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
        };
    }
    async componentDidMount() {
        const result = await userInfo();
        if (result.success) {
            let name = result.data.fullName;
            if (name === '') name = result.data.email;
            this.setState({ fullName: name });
        } else {
            sessionStorage.removeItem('token');
            window.location.reload();
        }
    }

    render() {
        const navbarList = [
            {
                id: 1,
                title: 'Danh sách đơn hàng',
                zonePath: '/admin/danh-sach-don-hang',
                url: '',
                isActive: false,
                isTarget: false,
            },
            {
                id: 2,
                title: 'Yêu cầu tư vấn',
                zonePath: '/admin/yeu-cau-tu-van',
                url: '',
                isActive: false,
                isTarget: false,
            },
            // {
            //     id: 3,
            //     title: 'Danh sách Thẩm định vay nhanh',
            //     zonePath: '/admin/danh-sach-tham-dinh-vay-nhanh',
            //     url: '',
            //     isActive: false,
            //     isTarget: false,
            // },
            // {
            //     id: 4,
            //     title: 'Danh sách Đăng ký vay',
            //     zonePath: '/admin/danh-sach-dang-ky-vay',
            //     url: '',
            //     isActive: false,
            //     isTarget: false,
            // },
        ];
        return <NavAdmin navbarList={navbarList} fullName={this.state.fullName} />;
    }
}

export default AdminNavLink;
